import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

import api from '../../service/api';

// import { Container } from './styles';

function JornadaResume({ jornada }) {
    const navigate = useNavigate();
    const [tenhoJornada, setTenhoJornada] = useState(false);

    async function getMinhasJornadas() {
        try {
            const { data: jornadas } = await api.get(`Aluno/me/jornadas`);
            return jornadas;
        } catch (error) {
            console.error(error);
        }
    }

    async function init(idJornada) {
        const jornadas = await getMinhasJornadas();
        if (!!jornadas?.find((jornada) => jornada.id == idJornada)) setTenhoJornada(true);
    }

    useEffect(() => {
        if (jornada?.id) init(jornada.id);
    }, [jornada]);

    if (jornada === null) return null;
    return (
        <div style={{ display: 'flex', flexDirection: 'column', fontSize: 14 }}>
            <div>
                <span>{jornada.descricao}</span>
            </div>
            <div>
                <h3>Aulas da Jornada:</h3>
                <ol style={{ paddingLeft: 15, fontWeight: 600 }}>
                    {jornada.aulas
                        .filter((aula) => aula.tipo === 1)
                        .map((aula) => (
                            <li key={`aulaListJornadaResume-${aula.id}`}>
                                <span>{aula.titulo}</span>
                            </li>
                        ))}
                </ol>
                {tenhoJornada && (
                    <Button
                        variant="contained"
                        sx={{ background: '#1a851f' }}
                        color="success"
                        onClick={() => navigate(`/app/minhajornada/${jornada.id}`)}
                    >
                        Abrir Jornada
                    </Button>
                )}
            </div>
        </div>
    );
}

export default JornadaResume;
