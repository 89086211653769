import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;

    >div{
        width: 100%;
        height: 100%;
        padding: 0px!important;
    }
`;
