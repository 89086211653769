import React, { useEffect, useMemo, useState } from 'react';

import { Container } from './popUpStyles';
import imgClose from '../../assets/close.png';
import api from '../../service/api';

const PopUpBibliografia = ({ closePopUp, biblioteca }) => {
    const [links, setLinks] = useState(null);

    const idBiblioteca = useMemo(() => {
        return biblioteca?.id;
    }, [biblioteca]);

    const urlS = useMemo(() => {
        if (!links) return [];
        return links.map(({ urlConteudo }) => (urlConteudo ? JSON.parse(urlConteudo) : null));
    }, [links]);
    console.log(urlS);

    useEffect(() => {
        if (idBiblioteca) getLinksBiblioteca(idBiblioteca);
    }, [idBiblioteca]);

    async function getLinksBiblioteca(idBiblioteca) {
        const { data } = await api.get(`Biblioteca/${idBiblioteca}`);
        setLinks(data.linksBiblioteca);
    }

    return (
        <Container className="popup" id="popUpBibliografia" style={{ display: 'flex' }}>
            <div className="contentPopUp">
                <div className="TitlePopUp">
                    <span id="TitlePopUp">{`Bibliografia da Jornada`}</span>
                </div>
                <div className="close" onClick={() => closePopUp()}>
                    <img src={imgClose} />
                </div>
                <div className="conteudoPopUp">
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div
                            style={{
                                color: '#fff',
                                display: 'flex',
                                alignItems: 'flex-start',
                                margin: '10px 0px',
                                flexDirection: 'column',
                                padding: '0px 10px',
                                flex: 1,
                            }}
                        >
                            <h2 style={{ textAlign: 'center', width: '100%', marginBottom: '0px' }}>
                                Para baixar o item clique no link a abixo:
                            </h2>
                            {urlS.map(({ links }) =>
                                links.map((link, index) => (
                                    <div
                                        key={link.titulo}
                                        style={{
                                            cursor: 'pointer',
                                            marginTop: '20px',
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            border: '2px solid #0d4860',
                                            width: '100%',
                                            overflow: 'hidden',
                                            borderRadius: '10px',
                                            backgroundColor: '#1a89b6',
                                        }}
                                    >
                                        <span style={{ width: '50px', background: '#0d4860', padding: '5px 0px' }}>
                                            {index + 1}
                                        </span>
                                        <span
                                            onClick={() =>
                                                link.url ? window.open(link.url, '_black') : alert('Link não cadastrado')
                                            }
                                            style={{
                                                // textDecoration: 'underline',
                                                textAlign: 'start',
                                                flex: 1,
                                                fontWeight: 600,
                                                padding: '5px 0px',
                                                letterSpacing: '1px',
                                                textAlign: 'center',
                                                fontSize: '18px',
                                            }}
                                        >
                                            {link.titulo}
                                        </span>
                                    </div>
                                )),
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default PopUpBibliografia;
