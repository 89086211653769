import { ThemeProvider } from '@mui/material/styles';
import {
    Navigate,
    Outlet,
    Route,
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements,
    defer,
    useLocation,
} from 'react-router-dom';
import { darkThemeMui } from './App';
import { getAdmAula } from './admServices/aula.service';
import { getAdmCupom } from './admServices/cupom.service';
import { getCurso } from './admServices/cursos.service';
import { getJornada } from './admServices/jornada.service';
import { ContainerPaginasAdm, DivAdmAuthMenu } from './components/commonComponents/commonComponents';
import AlunosAdm from './pages/adm/alunos/Alunos.adm';
import { AdmAuthProvider, useAdmAuth } from './pages/adm/auth/admAuthProvider/AdmAuthProvider';
import AdmLogin from './pages/adm/auth/admLogin/AdmLogin';
import CursoAlunos from './pages/adm/cursos/cursoAlunos.adm';
import CursoConfig from './pages/adm/cursos/cursoConfig.adm';
import CursosCupom from './pages/adm/cursos/cursoCupom.adm';
import CursosCupomEdit, { salvarCupom } from './pages/adm/cursos/cursoCupomEdit.adm';
import CursosEditAdm from './pages/adm/cursos/cursoEdit.adm';
import CursosJornadas from './pages/adm/cursos/cursoJornadas.adm';
import CursosAdm from './pages/adm/cursos/cursos.adm';
import ElementoGame from './pages/adm/elementoGame/ElementoGame.adm';
import ErroGenericoAdm from './pages/adm/error/erroGenerico.adm';
import HomeAdm from './pages/adm/home/home.adm';
import Jornada from './pages/adm/jornadas/Jornada.adm';
import JornadaAlunos from './pages/adm/jornadas/JornadaAlunos.adm';
import JornadaBibliotecas from './pages/adm/jornadas/JornadaBibliotecas.adm';
import JornadaConfiguracoes, { salvaDadosJornada } from './pages/adm/jornadas/JornadaConfiguracoes.adm';
import JornadaDuvidas from './pages/adm/jornadas/JornadaDuvidas.adm';
import JornadaElementos from './pages/adm/jornadas/JornadaElementos';
import AdmMenu from './pages/adm/menu/Menu.adm';

function RequireAdmAuth() {
    let auth = useAdmAuth();
    const location = useLocation();

    if (auth == null) {
        return <Navigate to="/" state={{ from: location }} replace />;
    } else
        return (
            <DivAdmAuthMenu>
                <AdmMenu />
                <ContainerPaginasAdm>
                    <Outlet />
                </ContainerPaginasAdm>
            </DivAdmAuthMenu>
        );
}

function RoutersAdm() {
    return (
        <ThemeProvider theme={darkThemeMui}>
            <AdmAuthProvider>
                <RouterProvider
                    router={createBrowserRouter(
                        createRoutesFromElements([
                            <Route path="/" element={<AdmLogin perfil={'administrador'} />} />,
                            <Route path="/app" element={<RequireAdmAuth />}>
                                <Route index element={<HomeAdm />} />
                                <Route path="cursos" element={<CursosAdm />} />
                                <Route
                                    path="cursos/:idCurso"
                                    element={<CursosEditAdm />}
                                    loader={async ({ params }) => defer(await getCurso(params.idCurso))}
                                    errorElement={<ErroGenericoAdm />}
                                >
                                    <Route path="" element={<Navigate to="dashboard" replace={true} />} />
                                    <Route path="dashboard" element={<h1>vendas</h1>} />
                                    <Route path="cupons" element={<CursosCupom />}>
                                        <Route
                                            path=":idCupom"
                                            element={<CursosCupomEdit />}
                                            loader={async ({ params }) =>
                                                params.idCupom == 0 ? {} : defer(await getAdmCupom(params.idCupom))
                                            }
                                            action={salvarCupom}
                                        />
                                    </Route>
                                    <Route path="alunos" element={<CursoAlunos />} />
                                    <Route path="jornadas" element={<CursosJornadas />} />
                                    <Route path="configuracoes" element={<CursoConfig />} />
                                    <Route path="*" element={<Navigate to="dashboard" />} />
                                </Route>
                                <Route
                                    path="cursos/:idCurso/jornadas/:idJornada"
                                    element={<Jornada />}
                                    loader={async ({ params }) => defer(await getJornada(params.idJornada))}
                                    errorElement={<ErroGenericoAdm />}
                                >
                                    <Route path="configuracoes" element={<JornadaConfiguracoes />} action={salvaDadosJornada} />
                                    <Route path="elementos" element={<JornadaElementos />} />
                                    <Route path="alunos" element={<JornadaAlunos />} />
                                    <Route path="duvidas" element={<JornadaDuvidas />} />
                                    <Route path="bibliotecas" element={<JornadaBibliotecas />} />
                                    <Route path="*" element={<Navigate to="Elementos" />} />
                                </Route>
                                <Route
                                    path="cursos/:idCurso/jornadas/:idJornada/elementos/:idElemento"
                                    element={<ElementoGame />}
                                    loader={async ({ params }) => defer(await getAdmAula(params.idElemento))}
                                    errorElement={<ErroGenericoAdm />}
                                >
                                    <Route path="configuracoes" element={<h1>Configurações Elemento</h1>} />
                                    <Route path="questoes" element={<h1>Questões Elemento</h1>} />
                                    <Route path="alunos" element={<h1>Aluno Elemento</h1>} />
                                </Route>
                                <Route index path="alunos" element={<AlunosAdm />} />
                            </Route>,
                        ]),
                        { basename: '/adm' },
                    )}
                ></RouterProvider>
            </AdmAuthProvider>
        </ThemeProvider>
    );
    // <BrowserRouter basename="/adm">

    // </BrowserRouter>
    // );
}

export default RoutersAdm;
