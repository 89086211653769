import React, { useEffect } from 'react';
import constantes from '../../../../utils/constantes';

import { testTokenService } from '../../../../admServices/auth.service';
import { setRefreshToken, setToken } from '../../../../service/auth';


export interface AuthContextType {
    user: any;
    signin: (token: string, refreshToken: string, callback: VoidFunction) => void;
    signout: (callback: VoidFunction) => void;
}
export const AdmAuthContext = React.createContext<AuthContextType>(null!);

export function useAdmAuth() {
    return React.useContext(AdmAuthContext);
}

export function AdmAuthProvider({ children }: { children: React.ReactNode }) {
    const userLocalStorage = sessionStorage.getItem(constantes.storageAdm);
    const initUser = userLocalStorage ? JSON.parse(userLocalStorage) : null;

    const [user, setUser] = React.useState(initUser);
    useEffect(() => {
        sessionStorage.setItem(constantes.storageAdm, JSON.stringify(user));
    }, [user]);

    const signin = async (token: string, refreshToken: string, callback: VoidFunction) => {
        try {
            const meUser = await testTokenService(token);
            setToken(token);
            setRefreshToken(refreshToken);
            setUser(meUser);
            callback();
        } catch (error) {
            console.error(error);
            throw { message: 'Erro ao tentar validar token!' };
        }
    };

    const signout = (callback: VoidFunction) => {
        const keyRefreshToken = constantes.storageAdmRefreshToken;
        const keyToken = constantes.storageAdmToken;
        const refreshTokenJson = localStorage.getItem(keyRefreshToken);

        if (refreshTokenJson) {
            try {
                const refreshToken = JSON.parse(refreshTokenJson);
            } catch (error) {
                console.error(error);
            }
        }
        localStorage.removeItem(keyRefreshToken);
        localStorage.removeItem(keyToken);

        setUser(null);
        callback();
    };

    return <AdmAuthContext.Provider value={{ user, signin, signout }}>{children}</AdmAuthContext.Provider>;
}
