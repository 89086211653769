import styled from 'styled-components';

export const Container = styled.div`
    width: 50vw;
    min-width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;
