import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '../../components/commonComponents/commonComponents';
import ToastGame from '../../components/toastGame/ToastGame';
import gameInitStore from '../../store/gameInitStore';
import { useAuth } from '../auth/authProvider/AuthProvider';
import { Container } from './stylesPagamento';

function Agradecimento() {
    const auth = useAuth();
    const navigate = useNavigate();

    async function acessarSistema() {
        const aluno = await gameInitStore.getAluno();
        if (!aluno)
            return toast.info(
                <ToastGame title={'Erro ao tentar buscar dados do aluno, por favor entre em contato com suporte.'} text={''} />,
            );

        auth.signin(aluno, async () => {
            navigate('/app', { replace: true, state: { trocarSenha: true } });
        });
    }

    return (
        <Container
            style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: 0,
            }}
        >
            <h1 style={{ marginBottom: '10px' }}>🎉 Parabéns, compra efetuada com sucesso! 🎉</h1>
            <h2 style={{ color: '#07bc0c', fontSize: '20px', marginBottom: '60px', marginTop: '0px' }}>
                Seus dados de acesso foram enviados para o email cadastrado.📨
            </h2>
            <Button onClick={acessarSistema}>Ok, acessar sistema</Button>
        </Container>
    );
}

export default Agradecimento;
