import styled from 'styled-components';
import imgBackground from '../../assets/background.jpg';
import theme from '../../styles/themes/light';

export const Container = styled.div`
    display: flex;
    height: 100vh;
    flex: 1;
    flex-direction: column;
    padding-top: 50px;
    /* background: ${(p) => p.theme.colors.background}; */

    > .ContainerBusca {
        align-items: center;
        justify-content: center;
        width: 100%;

        > div {
            position: relative;
            width: calc(100% - 50px);
            max-width: 500px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            margin: auto;
        }

        > div > svg {
            position: absolute;
            left: 15px;
            color: #898989;
        }

        > div > input {
            width: 100%;
            padding: 15px;
            padding-left: 20px;
            border-radius: 8px;
            border: 1px solid #cecece;
            background-color: #e7e7e7;

            &:focus {
                border-color: rgba(176, 206, 255, 1);
                box-shadow: 0px 0px 2px 1px rgba(176, 206, 255, 1);
            }
        }
    }
`;

export const ContainerListGames = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    > .HeaderCarrocel {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 20px;
        color: ${(p) => p.theme.colors.text};
        > h2 {
            display: inline;
            margin: 0px;
        }
        > div {
            display: flex;
            justify-content: space-between;
            width: 40px;
            > span {
                font-size: 22px;
                font-weight: 800;
            }
        }
    }
    > .ContainerCarrocel {
        margin: 12px 0;
    }
    @media screen and (max-width: 767px) {
        margin-bottom: 90px;
        margin-bottom: 60px;
    }
`;

export const CardJornada = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    justify-content: center;
    overflow: hidden;
    height: 250px;
    background-image: url(${(p) => p.img});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: 10px;
    border: 2px solid #1463a6;
    border-radius: 8px;
    box-shadow: 0px 0px 11px -4px #1463a6;
    background-color: ${(p) => p.theme.colors.background};
    :hover {
        > img {
            filter: saturate(0.3) brightness(0.2);
            transform: scale(1.2);
        }

        > .title-jornada-card {
            opacity: 1;
            background-color: #000000e6;
            backdrop-filter: blur(5px);
        }
    }

    > img {
        transition-duration: 0.5s;
    }

    > .title-jornada-card {
        transition-duration: 0.5s;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        color: #fff;
        padding: 0px 15px;
        opacity: 0;
        flex: 1;
        height: 100%;
        width: calc(100% - 30px);

        > h3 {
            margin: 10px 0px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        > span {
            font-size: 12px;
            margin-bottom: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        > button {
            background-color: #059709;
            border: none;
            border-radius: 4px;
            color: #fff;
            padding: 8px;
            margin-bottom: 10px;

            cursor: pointer;
        }
    }
`;
