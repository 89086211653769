import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import ListAlunos from '../components/listAlunos/ListAlunos.adm';

function JornadaAlunos() {
    const { jornada } = useOutletContext();
    const alunos = useMemo(() => {
        return jornada?.jornadasAlunos ? jornada.jornadasAlunos.map((i) => i.aluno) : [];
    }, [jornada]);

    return (
        <Box display={'flex'} flexDirection={'column'} padding={'10px'}>
            <Box display={'flex'} justifyContent={'space-between'} padding={'10px 15px'}>
                <Typography variant="h5" textAlign={'center'}>
                    Alunos da Jornada
                </Typography>
                <Button variant="contained" color="success" startIcon={<AddIcon />}>
                    Adicionar Aluno
                </Button>
            </Box>
            <ListAlunos alunos={alunos} />
        </Box>
    );
}

export default JornadaAlunos;
