import React, { useCallback } from 'react';
function ToastGame({ title, text, foto, click }) {
    const handleClick = useCallback(
        (func) => {
            if (click && typeof click == 'function') {
                click();
            }
            if (func && typeof func == 'function') {
                func();
            }
        },
        [click],
    );

    return (
        <div
            onClick={() => handleClick(console.log('Clicou no toast'))}
            style={{ display: 'flex', flexDirection: 'column', gap: '40px' }}
        >
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                {foto && <img src={foto} style={{ width: '70px' }} />}
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                    <span style={{ fontWeight: '600', textAlign: 'start', color: '#fff' }}>{title}</span>

                    <span style={{ color: '#fff' }}>{text}</span>
                </div>
            </div>
        </div>
    );
}

export default ToastGame;
