import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #0c0c0c;
    padding: 15px 10px;
    gap: 15px;
    box-shadow: rgba(0, 0, 0, 0.75) 0px 0px 5px 0px;
    > a {
    }
    > a > h6 {
        font-size: 18px;
        color: #fefefe;
        font-weight: 500;
        cursor: pointer;

        :hover {
            color: #a0a0a0;
        }
    }

    @media (max-width: 600px) {
        flex-direction: row;
        justify-content: space-between;
        gap: 5px;

        > a > h6 {
            font-size: 14px;
        }
    }
`;
