import React from 'react';

import { Container } from './Menu.adm.styles';
import { NavLink } from 'react-router-dom';
import { Typography, useTheme } from '@mui/material';

function AdmMenu() {
    const theme = useTheme();
    return (
        <Container>
            {itensMenu.map((item) => (
                <NavLink
                    key={item.caminho}
                    to={item.caminho}
                    className={({ isActive, isPending }) => (isActive ? 'active' : isPending ? 'pending' : '')}
                    style={({ isActive, isPending }) => ({
                        textDecoration: 'none',
                        color: isActive
                            ? theme.palette.primary.dark
                            : isPending
                            ? theme.palette.warning.main
                            : theme.palette.text.primary,
                    })}
                >
                    <Typography variant="h6">{item.texto}</Typography>
                </NavLink>
            ))}
        </Container>
    );
}

export default AdmMenu;

export const itensMenu = [
    { texto: 'Home', caminho: '/app' },
    { texto: 'Cursos', caminho: '/app/cursos' },
    // { texto: 'Jornadas', caminho: '/app/jornadas' },
    { texto: 'Alunos', caminho: '/app/alunos' },
    { texto: 'Configurações', caminho: '/app/configuracoes' },
];
