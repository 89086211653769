import styled from 'styled-components';

export const Container = styled.div`
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const Tabela = styled.div`
    display: flex;
    flex: 1;
    margin-bottom: 10px;
    padding: 0px 10px;
    gap: 15px;
    flex-direction: column;
    overflow-y: auto;
`;

export const ItemTabela = styled.div`
    display: flex;
    flex-direction: row;
    /* border: 1px solid #1e1e1e; */
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    transition-duration: 0.5s;

    .imagemTabela {
        border-radius: 8px;
        width: 180px;
        height: 100px;
        object-fit: cover;
        margin-right: 15px;
    }

    :hover {
        transform: scale(0.98);
    }
`;
