import axios from 'axios';
import constantes from '../utils/constantes';
import { getRefreshToken, getToken, setToken } from './auth';

const api = axios.create({
    baseURL: constantes.APIUrl,
});

api.interceptors.request.use(async (config) => {
    const token = getToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

api.interceptors.response.use(
    async (res) => {
        return res;
    },
    async (err) => {
        try {
            const { response, config, request } = err;
            if (response.status == 401) {
                const refreshToken = getRefreshToken();
                const { data } = await axios.get(`${config.baseURL}RefreshToken?sessaoId=${JSON.parse(refreshToken).id}&ip=1`);
                setToken(data.token);
                config.headers.Authorization = `Bearer ${data.token}`;
                const response = await axios(config);
                return response;
            }
            return err;
        } catch (error) {
            console.error(error);
            return error;
        }
    },
);
export default api;
