import styled from 'styled-components';

export const DivMainMenu = styled.div`
    display: flex;
    position: fixed;
    z-index: 10;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    width: 100%;
    background-color: ${(p) => p.theme.colors.background}29;
    top: 0px;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.3113620448179272) 50%,
        rgba(0, 0, 0, 0.30575980392156865) 100%
    );
    > div {
        padding: 5px 10px;

        display: flex;
        align-items: center;

        > div {
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 5px;
            color: #fefefe;
            transition-duration: 0.3s;

            :hover {
                transform: scale(1.1);
            }
        }

        > div > span {
            font-weight: 400;
            letter-spacing: 1px;
            font-size: 14px;
        }
    }
    > .containerItensMenuJogo {
        gap: 20px;
    }

    > .containerItensMenuMe {
        gap: 5px;
        background-color: #fff;
        margin: 5px;
        border-radius: 12px;
        cursor: pointer;

        transition-duration: 0.3s;

        :hover {
            transform: scale(1.05);
        }

        > span {
            font-weight: 600;
            font-size: 14px;
        }
    }

    /* @media screen and (max-width: 767px) {
        max-width: unset;
        position: fixed;
        bottom: 0;
        z-index: 10;
        width: 100%;
        padding: 0px;
    } */

    ul {
        list-style: none;
        padding: 0;
        margin: 0px;
        width: 100%;
        height: fit-content;

        display: flex;
        background-color: ${(p) => p.theme.colors.background}b0;

        @media screen and (max-width: 767px) {
            width: calc(100% - 24px);
            flex-direction: row;
            margin: 9px 10px;
            flex: 1;
            justify-content: space-between;
        }

        li {
            /* margin: 10px auto; */
            padding: 10px 4px;
            display: flex;
            cursor: pointer;
            transition: 0.5s;

            :nth-last-child(1) {
                border-bottom: 0px;
                margin-bottom: 0px;
            }
            :hover {
                transform: scale(1.08);
            }

            @media screen and (max-width: 767px) {
                padding: 4px 10px;
                border-bottom: 0px;
                border-right: 1px solid ${(p) => p.theme.colors.azul};
                flex: 1;
                justify-content: center;
                :nth-last-child(1) {
                    border-right: 0px;
                }
            }

            p {
                display: flex;
                @media screen and (max-width: 767px) {
                    border-bottom: 0;
                }
            }
        }
    }
`;
export const ImgMenu = styled.img`
    width: 20px;

    @media screen and (max-width: 767px) {
        width: 20px;
    }
`;
