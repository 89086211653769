import React from 'react';
import * as HoverCard from '@radix-ui/react-hover-card';

import { Container, CardJornada, CardHover } from './stylesCardJorndaResumo';

function CardJornadaResumo({ jornada, indexJornada, setJornadaSelecionada }) {
    return (
        <Container>
            <HoverCard.Root key={jornada.id}>
                <HoverCard.Trigger asChild>
                    <CardJornada onClick={() => setJornadaSelecionada(jornada)}>
                        <span>{indexJornada + 1}</span>
                        <img src={jornada?.urlImagemApresentacao} />
                    </CardJornada>
                </HoverCard.Trigger>
                <HoverCard.Portal>
                    <CardHover className="HoverCardContent" sideOffset={5}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
                            <img style={{ display: 'block', width: '100%' }} src={jornada.urlImagemApresentacao} alt="Radix UI" />
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 20,
                                    padding: '20px',
                                    paddingTop: 0,
                                }}
                            >
                                <h3 style={{ fontWeight: 600, fontSize: '16px', margin: 0 }}>{jornada.nome}</h3>
                                <div style={{ maxHeight: '100px', overflowY: 'auto' }}>
                                    <span style={{ fontSize: '12px', color: '#cecece' }}>{jornada.descricao}</span>
                                </div>
                                <div style={{ display: 'flex', gap: 15, justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', gap: 15 }}>
                                        <span style={{ fontWeight: 600 }}>Aulas:</span>
                                        <span>{jornada.aulas.length}</span>
                                    </div>
                                    <div style={{ display: 'flex', gap: 15 }}>
                                        <span style={{ fontWeight: 600 }}>Duração:</span>
                                        <span>
                                            {`${(jornada.aulas.reduce((acc, cur) => acc + cur.duracaoVideo, 1) / 60).toFixed(
                                                0,
                                            )} Min`}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CardHover>
                </HoverCard.Portal>
            </HoverCard.Root>
        </Container>
    );
}

export default CardJornadaResumo;
