import { Player } from '@lottiefiles/react-lottie-player';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import imgClose from '../../assets/close.png';
import loadingJSON from '../../assets/loading-animation.json';
import api from '../../service/api';
import storeJornada from '../../store/StoreJornada';
import constantes from '../../utils/constantes';
import VideoPlayer from '../videoPlayer/VideoPlayer';
import PopUpPerguntasFase from './PopUpPerguntasFase';
import { Container } from './popUpStyles';

function PopUpVideo({ objAulaSelecionada, pauseAmbiente, objConfigJornada }) {
    const [popUpAulaPerguntasIsOpen, setPopUpAulaPerguntasIsOpen] = useState(false);
    const [aulaAluno, setAulaAluno] = useState(null);
    const aula = useMemo(() => (aulaAluno ? aulaAluno.aula : null), [aulaAluno]);
    const aondeParouVideo = useMemo(() => (aulaAluno ? aulaAluno.aondeParouAula : 0), [aulaAluno]);

    function closePopUpVideo(params) {
        if (aula.questoes.length) {
            setPopUpAulaPerguntasIsOpen(true);
        } else {
            toast.info(
                <ToastMudaVideo
                    nomeVideo={`Aula ${aula.ordem + 1} liberada!`}
                    foto={`${constantes.urlStorage}${objConfigJornada?.professor}`}
                />,
                {
                    autoClose: 100000,
                    icon: false,
                    backgroundColor: 'crimson',
                },
            );
            storeJornada.toggglePopUpVideoAulaAberto();
        }
    }

    function closePopUpAulaFase() {
        toast.info(
            <ToastMudaVideo
                nomeVideo={`Aula ${aula.ordem + 1} liberada!`}
                foto={`${constantes.urlStorage}${objConfigJornada?.professor}`}
            />,
            {
                autoClose: 100000,
                icon: false,
                backgroundColor: 'crimson',
            },
        );
        setPopUpAulaPerguntasIsOpen(false);
        storeJornada.toggglePopUpVideoAulaAberto();

        // if (objAulaSelecionada.diamantes && !objAulaSelecionada.diamantes.pego) {
        //     setPopUpUsuario({ isOpen: true, text: ' 👀 Olha, um diamente!' });
        //     if (objAulaSelecionada.casoClinico && !objAulaSelecionada.casoClinico.desbloqueado)
        //         return setAulas(
        //             atualizaAulas({
        //                 ...objAulaSelecionada,
        //                 casoClinico: { ...objAulaSelecionada.casoClinico, desbloqueado: true },
        //                 diamantes: { ...objAulaSelecionada.diamantes, visivel: true },
        //             }),
        //         );
        //     return setAulas(
        //         atualizaAulas({ ...objAulaSelecionada, diamantes: { ...objAulaSelecionada.diamantes, visivel: true } }),
        //     );
        // }
    }

    useEffect(() => {
        carregaDadosAulaAluno(objAulaSelecionada.id);
    }, [objAulaSelecionada]);

    async function carregaDadosAulaAluno(idAula) {
        const { data } = await api.get(`/AulaAluno/${idAula}/perguntaRespostas`);
        setAulaAluno(data);
    }

    if (!aula)
        return (
            <Container className="popup" id="popUpFase" style={{ display: 'flex' }}>
                <div className="contentPopUp">
                    <div className="conteudoPopUp">
                        <div
                            className="video"
                            style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}
                        >
                            <Player autoplay loop src={loadingJSON} style={{ width: '100%', height: '200px' }}></Player>
                        </div>
                    </div>
                    <div className="TitlePopUp">
                        <span id="TitlePopUp">{`${objAulaSelecionada.ordem}ª FASE`}</span>
                    </div>
                </div>
            </Container>
        );

    return popUpAulaPerguntasIsOpen ? (
        <PopUpPerguntasFase
            popUpAulaPerguntasIsOpen={popUpAulaPerguntasIsOpen}
            closePopUpAulaFase={closePopUpAulaFase}
            objAulaSelecionada={aula}
        />
    ) : (
        <Container className="popup" id="popUpFase" style={{ display: 'flex' }}>
            <div className="contentPopUp">
                <div className="TitlePopUp">
                    <span id="TitlePopUp">{`${aula.ordem}ª FASE`}</span>
                </div>
                <div className="close" onClick={closePopUpVideo}>
                    <img src={imgClose} />
                </div>
                <div className="conteudoPopUp">
                    <div className="video">
                        <VideoPlayer
                            aula={aula}
                            idVideo={aula.urlConteudo}
                            pauseAmbiente={pauseAmbiente}
                            aondeParouVideo={aondeParouVideo}
                        />
                    </div>
                    <h3 id="titleVideoPopUp">{aula.titulo}</h3>
                    {/* <h4 id="autorVideoPopUp">{aula.Author}</h4> */}
                    {/* <span id="descricaoVideoPopUp">{aula.Description}</span> */}
                </div>
            </div>
        </Container>
    );
}

export default PopUpVideo;

function ToastMudaVideo({ nomeVideo, closeToast, foto }) {
    function handle() {
        closeToast();
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                {foto && <img src={foto} style={{ width: '70px', objectFit: 'cover', borderRadius: '50%' }} />}
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                    <span style={{ fontWeight: '600', textAlign: 'center', color: '#fff' }}>{nomeVideo}</span>
                    <span style={{ color: '#fff' }}>🔥 Parabéns!!!</span>
                </div>
            </div>
        </div>
    );
}
