import Phaser from 'phaser';
import { autorun, toJS } from 'mobx';
import gameInitStore from '../../store/gameInitStore';
// import camposJson from '../assets/tilemaps/json/campus.json';
// import bibliotecaJson from '../assets/tilemaps/json/campus_indoor.json';
// import predio_biblioteca from '../assets/predio_biblioteca.png';
// import tileset_ground_campus from '../assets/tilemaps/tiles/tileset_ground_campus.png';
// import tileset_elements_campus from '../assets/tilemaps/tiles/tileset_elements_campus.png';
// import tileset_elements_biblioteca from '../assets/tilemaps/tiles/tileset_elements_indoor.png';
// import tileset_ground_biblioteca from '../assets/tilemaps/tiles/tileset_ground_indoor.png';
// import janelaElementes from '../assets/tilemaps/tiles/janelas.png';
// import predio_campus1 from '../assets/predio_campus1.png';
// import predio_campus2 from '../assets/predio_campus2.png';
// import predio_auditorio from '../assets/predio_auditorio.png';
// import sacodemoedas from '../assets/Sacodemoedas.png';
// import diamante from '../assets/diamante.png';
// import professor_carloslopes from '../assets/professor_carloslopes.png';
// import placa_duvidas from '../assets/placa_duvidas.png';
// import diploma from '../assets/Diploma.png';
// import cadeadoNew from '../assets/Cadeado.png';
import bandeiraD1 from '../assets/aula1.png';
import bandeiraD2 from '../assets/aula2.png';
import bandeiraD3 from '../assets/aula3.png';
import bandeiraD4 from '../assets/aula4.png';
import bandeiraD5 from '../assets/aula5.png';
import bandeiraD6 from '../assets/aula6.png';
import bandeiraD7 from '../assets/aula7.png';
import bandeiraD8 from '../assets/aula8.png';
import bandeiraD9 from '../assets/aula9.png';
import bandeiraD10 from '../assets/aula10.png';
import bandeiraD11 from '../assets/aula11.png';
import bandeiraD12 from '../assets/aula12.png';
import bandeiraD13 from '../assets/aula13.png';
import bandeiraD14 from '../assets/aula14.png';
import bandeiraD15 from '../assets/aula15.png';
import avatar_sprite from '../assets/avatar_sprite_criado.png';
import button_start from '../assets/button_start.png';
import button_start_click from '../assets/button_start_click.png';
import white from '../assets/white.png';
import red from '../assets/red.png';

const newSprit = sessionStorage.getItem('newSprit');
export default class PreloaderScene extends Phaser.Scene {
    jornada = null;

    constructor() {
        super('Preloader');
    }

    async init() {
        this.readyCount = 0;
        autorun(() => {
            this.jornada = toJS(gameInitStore.jornadaCompleta.jornada);
        });
    }

    async preload() {
        const dominioBlob = 'https://medxdata.blob.core.windows.net/gammyx/';
        const objJornada = JSON.parse(this.jornada.jsonConfigurationMap);
        const elementosGenericos = objJornada?.elementosGenericos || [];

        this.jornada.jsonConfigurationMap = objJornada;
        // this.load.image('logoMEDX', logo);
        this.add.image(400, 200, 'logoMEDX');
        const progressBar = this.add.graphics();
        const progressBox = this.add.graphics();
        progressBox.fillStyle(0x222222, 0.8);
        progressBox.fillRect(240, 270, 320, 50);

        const { width } = this.cameras.main;
        const { height } = this.cameras.main;

        const loadingText = this.make.text({
            x: width / 2,
            y: height / 2 - 50,
            text: 'Loading...',
            style: {
                font: '20px monospace',
                fill: '#FF0000',
            },
        });
        loadingText.setOrigin(0.5, 0.5);

        const percentText = this.make.text({
            x: width / 2,
            y: height / 2 - 5,
            text: '0%',
            style: {
                font: '18px monospace',
                fill: '#000000',
            },
        });
        percentText.setOrigin(0.5, 0.5);

        const assetText = this.make.text({
            x: width / 2,
            y: height / 2 + 50,
            text: '',
            style: {
                font: '18px monospace',
                fill: '#ffffff',
            },
        });
        assetText.setOrigin(0.5, 0.5);

        this.load.on('progress', (value) => {
            percentText.setText(`${parseInt(value * 100, 10)}%`);
            progressBar.clear();
            progressBar.fillStyle(0xffffff, 1);
            progressBar.fillRect(250, 280, 300 * value, 30);
        });

        this.load.on('fileprogress', (file) => {
            assetText.setText(`Loading asset: ${file.key}`);
        });

        this.load.on('complete', () => {
            progressBar.destroy();
            progressBox.destroy();
            loadingText.destroy();
            percentText.destroy();
            assetText.destroy();
            this.ready();
        });

        // this.timedEvent = this.time.delayedCall(3000, this.ready, [], this);

        //PRE=LOAD CARREGA TODOS OS ASSETS E COLOCA REFERENCIADOS EM ALIAS
        this.jornada.bibliotecas.forEach((biblioteca) => {
            this.load.image('tilesGroundBiblioteca', dominioBlob + biblioteca.tilesGroundBiblioteca);
            this.load.image('tilesElementsBiblioteca', dominioBlob + biblioteca.tilesElementsBiblioteca);
            this.load.image('tilesJanelaBiblioteca', dominioBlob + biblioteca.tilesObjetosBiblioteca);
            //CARREGA JSON DO TILEMAP EXPORTADO NO TILED
            this.load.tilemapTiledJSON('map_biblioteca', dominioBlob + biblioteca.map);
            this.load.image(biblioteca.urlImagemNoJogo, dominioBlob + biblioteca.urlImagemNoJogo);
        });

        if (elementosGenericos.length) {
            elementosGenericos.forEach((elemento, index) => {
                this.load.image(`elementoGenerico-${index}`, dominioBlob + elemento.urlImagem);
            });
        }

        this.load.image('tiles', dominioBlob + objJornada.campus.tiles);
        this.load.image('tilesElements', dominioBlob + objJornada.campus.tilesElements);
        //CARREGA JSON DO TILEMAP EXPORTADO NO TILED
        this.load.tilemapTiledJSON('map', dominioBlob + objJornada.campus.map);

        // this.load.image('predio_auditorio', dominioBlob + objJornada.predio_auditorio);
        this.load.image('sacodemoedas', dominioBlob + objJornada.sacodemoedas);
        // this.load.image('diamante', dominioBlob + objJornada.diamante);
        this.load.image('duvidas', dominioBlob + objJornada.placa_duvidas);
        this.load.image('diploma', dominioBlob + objJornada.diploma);
        this.load.image('cadeadoNew', dominioBlob + objJornada.cadeado);

        //CARREGA UM SPRITE (DUDE.PNG) QUE TEM A SEQ PARA ANIMAÇÃO

        this.load.image('white', white);
        this.load.image('red', red);

        this.load.spritesheet('avatarSprit', newSprit || avatar_sprite, {
            frameWidth: 64,
            frameHeight: 128,
        });

        //carrega imagem de todas as aulas/casos clinicos/auditorio
        this.jornada.aulas.forEach((aula) => {
            this.load.image(aula.urlImagemNoJogo, dominioBlob + aula.urlImagemNoJogo);
            aula?.premioAula.forEach((premio) => {
                this.load.image(premio.urlImagemNoJogo, dominioBlob + premio.urlImagemNoJogo);
            });
        });

        this.load.image('start_button', button_start);
        this.load.image('start_button_click', button_start_click);
    }

    ready() {
        this.scene.start('ObsidadeScene');

        this.readyCount += 1;
        if (this.readyCount === 2) {
            this.scene.start('ObsidadeScene');
        }
    }
}
