import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { MdArrowBack } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';

import PopUpVideo from '../../../components/popUps/PopUpVideo';
import Sticker from '../../../game/assets/Sticker.png';
import storeAmbienceSound from '../../../store/StoreAmbienceSound';
import storeCasoClinico from '../../../store/StoreCasoClinico';
import storeCongratulations from '../../../store/StoreCongratulations';
import storeJornada from '../../../store/StoreJornada';
import storePopUps from '../../../store/StorePopUps';
import storeToast from '../../../store/StoreToast';
import storeTooltip from '../../../store/StoreTooltip';
import gameInitStore from '../../../store/gameInitStore';
import ScorePlayer from '../../../store/scorePlayer';
import { useAuth } from '../../auth/authProvider/AuthProvider';

import ToastGame from '../../../components/toastGame/ToastGame';
import Diploma from '../../../game/assets/Diploma.png';

import PopUpPerguntasFase from '../../../components/popUps/PopUpPerguntasFase';
import PopUpSatisfacao from '../../../components/popUps/PopUpSatisfacao';
import PopUpAuditorio from '../../../components/popUps/popUpAuditorio';
import PopUpBibliografia from '../../../components/popUps/popUpBibliografia';
import PopUpHelp from '../../../components/popUps/popUpHelp';
import PopUpLinks from '../../../components/popUps/popUpLinks';
import PopUpListPDFs from '../../../components/popUps/popUpListPDFs';
import PopUpLivros from '../../../components/popUps/popUpLivros';
import PopUpPerguntaEnviada from '../../../components/popUps/popUpPerguntaEnviada';

import gifBoasVindas from '../../../game/assets/Pergaminho-abrindo.gif';
import coin_156 from '../../../game/assets/coin_156.png';
import diamante_156 from '../../../game/assets/diamante_156.png';
import soundError from '../../../game/assets/soundfx/mixkit-click-error-1110.wav';
import soundClick from '../../../game/assets/soundfx/mixkit-gate-latch-click-1924.wav';
import { TIPOS_AULAS } from '../../../game/scenes/ObsidadeScene';
import themeDark from '../../../styles/themes/dark';
import constantes from '../../../utils/constantes';

import { Tooltip } from '@mui/material';
import api from '../../../service/api';
import {
    Body,
    CardAulaBody,
    Container,
    ContainerArrowBack,
    Header,
    IconeProfessorPergunta,
    MenuDuvida,
    TitleJornada,
} from './stylesGame';

import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import LoadingLottie from '../../../components/loadingLottie/loadingLottie';
import PopUpEnviarPergunta from '../../../components/popUps/PopUpEnviarPergunta';
const ColorButton = styled(Button)(({ theme }) => ({
    color: themeDark.colors.text,
    backgroundColor: themeDark.colors.primary,
    fontSize: '12px',
    '&:hover': {
        backgroundColor: themeDark.colors.primary,
    },
}));

const clickAudio = new Audio(soundClick);
const ambienceAudio = new Audio(
    'https://medxdata.blob.core.windows.net/gammyx/mixkit-forest-birds-ambience-1210.31c344ee3d4ef4068b74.wav',
);
const errorAudio = new Audio(soundError);
const VOLUME_AMBIENCE = 0.02;
ambienceAudio.volume = VOLUME_AMBIENCE;
errorAudio.volume = 0.1;
const TEMPIDCURSO = 142;

const CINCO_MIN = 180000; //300000;
const darkTheme = createTheme({ palette: { mode: 'dark' } });

const actions = [
    { icon: <SmartDisplayIcon />, name: 'Assistir Respostas' },
    { icon: <EmojiPeopleIcon />, name: 'Enviar Pergunta' },
];

export default observer(() => {
    const params = useParams();
    const { socket } = useAuth();
    const navegate = useNavigate();
    const [carregando, setCarregando] = useState(false);
    const aluno = useMemo(() => toJS(gameInitStore.aluno), [gameInitStore.aluno]);
    const [bibliotecaSelecionada, setBibliotecaSelecionada] = useState(null);
    const [boasVindas, setBoasVindas] = useState(false);
    const [satisfacao, setSatisfacao] = useState(false);
    const [isOpenSpeedDial, setIsOpenSpeedDial] = useState(false);
    const [alunoConectados, setAlunosConectados] = useState([]);

    const objJornada = useMemo(() => {
        const jornada = toJS(gameInitStore.jornadaCompleta);

        if (jornada) {
            console.log('jornada selecionada', jornada);
            return jornada.jornada;
        } else {
            return null;
        }
    }, [gameInitStore.jornadaCompleta]);

    const objConfigJornada = useMemo(() => {
        if (!objJornada) return null;

        const obj = objJornada.jsonConfigurationMap ? JSON.parse(objJornada.jsonConfigurationMap) : null;
        console.log('Obj config jornada', obj);
        return obj;
    }, [objJornada]);

    const itensJornada = useMemo(() => {
        if (!objJornada) return null;

        const casosClinicos = objJornada.aulas.filter((item) => item.tipo == TIPOS_AULAS.casoClinico);
        const auditorio = objJornada.aulas.find((item) => item.tipo == TIPOS_AULAS.auditorio);
        const aulas = objJornada.aulas.filter((item) => item.tipo == TIPOS_AULAS.aula).sort((a, b) => a.ordem - b.ordem);

        let aulasComCasoClinico = [];
        aulas.forEach((aula) => {
            aulasComCasoClinico.push(aula);
            const casoClinico = casosClinicos.find((caso) => caso.id == aula.idQualAulaLibera);
            if (casoClinico) {
                aulasComCasoClinico.push(casoClinico);
            }
        });
        return { aulas, casosClinicos, auditorio, aulasComCasoClinico };
    }, [objJornada]);

    const aulaSelecionada = useMemo(() => {
        if (!objJornada || !objJornada.aulas.length || !storeJornada.idAulaSelecionada) return null;

        const selecionada = objJornada.aulas.find((jornada) => jornada.id == storeJornada.idAulaSelecionada);
        return selecionada;
    }, [storeJornada.idAulaSelecionada, objJornada]);

    const casoClinicoSelecionado = useMemo(() => {
        if (!objJornada || !objJornada.aulas.length || !storeCasoClinico.idAulaSelecionada) return null;

        return objJornada.aulas.find((jornada) => jornada.id == storeCasoClinico.idAulaSelecionada);
    }, [storeCasoClinico.idAulaSelecionada, objJornada]);

    useEffect(() => {
        async function initGame(params) {
            const jornadaCompleta = await gameInitStore.getJornadaCompleta(params.idJornada);
            // playAmbienceAudio();
            const boasVindas = localStorage.getItem(`boasVindas-${TEMPIDCURSO}`);
            const satisfacao = localStorage.getItem(`satisfacao-${TEMPIDCURSO}`);
            if (!boasVindas) {
                setBoasVindas(true);
                setTimeout(() => {
                    localStorage.setItem(`boasVindas-${TEMPIDCURSO}`, 'true');
                    setBoasVindas(false);
                }, 1000);
            }

            if (!satisfacao) {
                setSatisfacao(false);
                setTimeout(() => {
                    localStorage.setItem(`satisfacao-${TEMPIDCURSO}`, 'true');
                    setSatisfacao(false);
                }, CINCO_MIN);
            }

            if (socket) {
                socket.on('join', (content) => {
                    if (content.status) {
                        // setEstaConectado(true);
                    }
                    console.log('Você se juntou a sala: ', content);
                });
                socket.on('list_clients_in_room', (usuariosNaSala) => {
                    console.log('Usuarios na sala: ', usuariosNaSala);
                    setAlunosConectados(usuariosNaSala);
                });
            }
        }
        initGame(params);
        ScorePlayer.atualizaSaldo();
        return () => {
            ambienceAudio.pause();
            socket.off('join');
        };
    }, [params, socket, aluno]);

    useEffect(() => {
        const elementoAjudaBlip = document.getElementById('blip-chat-open-iframe');
        elementoAjudaBlip?.classList?.add('sobeAjudaBlip');
        if (socket && aluno) {
            const objEntrarSala = {
                name: aluno.nome,
                room_id: params.idJornada,
                gammyxId: aluno.id,
            };

            socket.on('connect', () => {
                socket.emit('join', objEntrarSala);
            });

            socket.emit('join', objEntrarSala);
        }

        return () => {
            console.log('Saindo da sala');

            if (socket && aluno) {
                socket.off('connect');
                socket.emit('leave', {
                    room_id: params.idJornada,
                    gammyxId: aluno.id,
                });
            }
            const elementoAjudaBlip = document.getElementById('blip-chat-open-iframe');
            elementoAjudaBlip?.classList?.remove('sobeAjudaBlip');
        };
    }, [socket, aluno, params]);

    useEffect(() => {
        if (storeToast.aberto) {
            errorAudio.play();
            toast.info(
                <ToastGame
                    title={storeToast.texto}
                    text={storeToast.title}
                    foto={`${constantes.urlStorage}${objConfigJornada.professor}`}
                />,
                {
                    // autoClose: 100000,
                    icon: false,
                },
            );
        }
    }, [storeToast.aberto, objConfigJornada]);

    // useEffect(() => {
    //     console.log('Moedas Incrementada', ScorePlayer.moedas);
    // }, [ScorePlayer.moedas]);

    // useEffect(() => {
    //     console.log('Diamante Incrementado', ScorePlayer.diamantes.length);
    // }, [ScorePlayer.diamantes]);

    function toogleAmbienceAudioActive() {
        let newValue = !storeAmbienceSound.isPlaying;
        if (newValue) {
            storeAmbienceSound.play();
            ambienceAudio.volume = VOLUME_AMBIENCE;
            errorAudio.volume = VOLUME_AMBIENCE;
        } else {
            storeAmbienceSound.stop();
            ambienceAudio.volume = 0;
            errorAudio.volume = 0;
        }
        // setAmbienceAudioActive(newValue);
    }

    function playAmbienceAudio(params) {
        ambienceAudio.loop = true;
        ambienceAudio.play();
    }

    function closeGame() {
        navegate(-1);
    }

    function openHelp() {
        storePopUps.openOnePopUp('help');
    }

    async function abrirAula(aula, index) {
        try {
            setCarregando(true);
            const proximaAula = itensJornada.aulas[index + 1];
            if (proximaAula) await api.get(`aulaAluno/${proximaAula.id}/criaOuPega`);
            if (aula.idQualAulaLibera) await api.get(`aulaAluno/${aula.idQualAulaLibera}/criaOuPega`);
            await api.put(`aulaAluno/${aula.id}/abriuAula`);
            await gameInitStore.getJornadaCompleta(objJornada.id);
            await ScorePlayer.atualizaSaldo();
            storeJornada.abrePopupVideo(aula.id);
        } catch (error) {
            console.error(error);
        } finally {
            setCarregando(false);
        }
    }

    async function abrirAuditorio(auditorio) {
        storePopUps.openOnePopUp('auditorio');
    }

    async function abrirBiblioteca(lib) {
        setBibliotecaSelecionada(lib);
        storePopUps.openOnePopUp('bibliografia');
    }

    function openHelp() {
        storePopUps.openOnePopUp('help');
    }

    if (!objJornada) return <div>Carregando...</div>;

    return (
        <>
            <Container id="containerGameUI">
                <ContainerArrowBack>
                    <div title="Voltar" onClick={closeGame}>
                        <MdArrowBack />
                    </div>
                    {/* <div title="Help" onClick={openHelp}>
                        <IoMdHelp />
                    </div> */}
                </ContainerArrowBack>
                <Header>
                    <TitleJornada>{objJornada.nome}</TitleJornada>
                    {/* <ContainerPontos>
                        <div
                            onClick={() => {
                                storePopUps.openOnePopUp('listPDFs');
                                clickAudio.play();
                            }}
                        >
                            <img src={diamante_156}></img>
                            <span>{ScorePlayer.diamantes.length}</span>
                        </div>
                        <div>
                            <img src={coin_156}></img>
                            <span>{ScorePlayer.moedas.reduce((qtdMoedas, saco) => qtdMoedas + saco.quantidadeMoedas, 0)}</span>
                        </div>
                    </ContainerPontos> */}
                    <div style={{ flex: 1, display: 'flex', width: '100%', alignItems: 'center', flexDirection: 'column' }}>
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',

                                width: '100%',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <span
                                style={{
                                    marginLeft: '2px',
                                    marginRight: '10px',
                                    fontSize: '12px',
                                    color: '#b7b7b7',
                                    fontWeight: 600,
                                }}
                            >
                                Progresso{' '}
                            </span>
                            <span style={{ marginLeft: '10px', fontSize: '12px', color: '#b7b7b7', fontWeight: 600 }}>
                                {(
                                    (itensJornada.aulasComCasoClinico.filter((x) => x.aulaAluno.length).length * 100) /
                                    itensJornada.aulas.length
                                ).toFixed(0)}
                                %
                            </span>
                        </div>
                        <div style={{ flex: 1, display: 'flex', width: '100%', alignItems: 'center' }}>
                            {itensJornada.aulasComCasoClinico.map((aula, index) => (
                                <Tooltip key={`atalho-${index}-${aula.id}`} title={`${index + 1}ª ${aula.titulo}`}>
                                    <div
                                        onClick={() => {
                                            document.getElementById(`aula-${aula.id}`)?.scrollIntoView();
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            margin: '0px 2px',
                                            flex: 1,
                                            height: ' 3px',
                                            boxShadow: aula.aulaAluno.length > 0 ? `0px 0px 5px 0px #1463a6` : '',
                                            background: `${aula.aulaAluno.length > 0 ? '#1463a6' : '#37393e'}`,
                                            borderRadius: '10px',
                                        }}
                                    ></div>
                                </Tooltip>
                            ))}
                        </div>
                    </div>
                </Header>
                <Body>
                    <div>
                        <Timeline
                            sx={{
                                padding: '0px 10px',
                                marginTop: '0px',
                                [`& .${timelineItemClasses.root}:before`]: {
                                    flex: 0,
                                    padding: 0,
                                },
                            }}
                        >
                            {objJornada?.bibliotecas.length > 0 &&
                                objJornada.bibliotecas.map((lib) => (
                                    <TimelineItem
                                        key={`aula-lib-${lib.id}`}
                                        id={`aula-lib-${lib.id}`}
                                        sx={{ padding: '0px' }}
                                        position={'right'}
                                    >
                                        <TimelineSeparator>
                                            <TimelineConnector
                                                sx={{
                                                    bgcolor: '#1463a6',
                                                    boxShadow: `0px 0px 11px 0px #1463a6`,
                                                    width: '3px',
                                                }}
                                            />
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    position: 'relative',
                                                }}
                                            >
                                                <div
                                                    onClick={() => abrirBiblioteca(lib)}
                                                    style={{
                                                        width: '60px',
                                                        height: '60px',
                                                        padding: '8px',
                                                        boxShadow: `0px 0px 8px 0px #1463a6`,
                                                        borderRadius: '8px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        border: `3px solid #1463a6`,
                                                    }}
                                                >
                                                    <img
                                                        style={{
                                                            filter: 'drop-shadow(0px 0px 4px #ffffff)',
                                                            width: '60px',
                                                            animation: `float 2s ease-in-out infinite`,
                                                        }}
                                                        src={`${constantes.urlStorage}${lib.urlImagemNoJogo}`}
                                                    />
                                                </div>
                                            </div>
                                            <TimelineConnector
                                                sx={{
                                                    bgcolor: '#1463a6',
                                                    boxShadow: `0px 0px 11px 0px #1463a6`,
                                                    width: '3px',
                                                }}
                                            />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                                            <ThemeProvider theme={darkTheme}>
                                                <Paper elevation={10} sx={{ borderRadius: '8px' }}>
                                                    <CardAulaBody ativo={true}>
                                                        <h2>{'Biblioteca'}</h2>

                                                        <span style={{ marginTop: '10px' }}>
                                                            {'Aqui você encontrara conteúdos adicionais para sua jornada!'}
                                                        </span>
                                                        <div
                                                            style={{
                                                                marginTop: '10px',
                                                                display: 'flex',
                                                                alignItems: 'flex-end',
                                                                justifyContent: 'space-between',
                                                            }}
                                                        >
                                                            <div
                                                                id="ContainerTimeAula"
                                                                style={{
                                                                    flex: 1,
                                                                    marginRight: '20px',
                                                                    height: ' 3px',
                                                                    background: `#37393e`,
                                                                    position: 'relative',
                                                                }}
                                                            ></div>
                                                            <ColorButton onClick={() => abrirBiblioteca(lib)} variant="contained">
                                                                Abrir
                                                            </ColorButton>
                                                        </div>
                                                    </CardAulaBody>
                                                </Paper>
                                            </ThemeProvider>
                                        </TimelineContent>
                                    </TimelineItem>
                                ))}
                            {itensJornada.auditorio && (
                                <TimelineItem
                                    key={`aula-auditorio-${itensJornada.auditorio.id}`}
                                    id={`aula-auditorio-${itensJornada.auditorio.id}`}
                                    sx={{ padding: '0px' }}
                                    position={'right'}
                                >
                                    <TimelineSeparator>
                                        <TimelineConnector
                                            sx={{
                                                bgcolor: '#1463a6',
                                                boxShadow: `0px 0px 11px 0px #1463a6`,
                                                width: '3px',
                                            }}
                                        />
                                        <div
                                            style={{
                                                display: 'flex',
                                                position: 'relative',
                                            }}
                                        >
                                            <div
                                                onClick={() => abrirAuditorio(itensJornada.auditorio)}
                                                style={{
                                                    width: '60px',
                                                    height: '60px',
                                                    padding: '8px',
                                                    // backgroundColor: '#cecece',
                                                    boxShadow: `0px 0px 8px 0px #1463a6`,
                                                    borderRadius: '8px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    border: `3px solid #1463a6`,
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        filter: 'drop-shadow(0px 0px 4px #ffffff)',
                                                        width: '60px',
                                                        animation: `float 2s ease-in-out infinite`,
                                                    }}
                                                    src={`${constantes.urlStorage}${itensJornada.auditorio.urlImagemNoJogo}`}
                                                />
                                            </div>
                                        </div>
                                        <TimelineConnector
                                            sx={{
                                                bgcolor: '#1463a6',
                                                boxShadow: `0px 0px 11px 0px #1463a6`,
                                                width: '3px',
                                            }}
                                        />
                                    </TimelineSeparator>
                                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                                        <ThemeProvider theme={darkTheme}>
                                            <Paper elevation={10} sx={{ borderRadius: '8px' }}>
                                                <CardAulaBody ativo={true}>
                                                    <h2>{itensJornada.auditorio.titulo}</h2>

                                                    <span style={{ marginTop: '10px' }}>{itensJornada.auditorio.descricao}</span>
                                                    <div
                                                        style={{
                                                            marginTop: '10px',
                                                            display: 'flex',
                                                            alignItems: 'flex-end',
                                                            justifyContent: 'space-between',
                                                        }}
                                                    >
                                                        <div
                                                            id="ContainerTimeAula"
                                                            style={{
                                                                flex: 1,
                                                                marginRight: '20px',
                                                                height: ' 3px',
                                                                background: `#37393e`,
                                                                position: 'relative',
                                                            }}
                                                        ></div>
                                                        <ColorButton
                                                            onClick={() => abrirAuditorio(itensJornada.auditorio)}
                                                            variant="contained"
                                                        >
                                                            Abrir
                                                        </ColorButton>
                                                    </div>
                                                </CardAulaBody>
                                            </Paper>
                                        </ThemeProvider>
                                    </TimelineContent>
                                </TimelineItem>
                            )}
                            {itensJornada.aulasComCasoClinico.map((aula, index, dataOriginal) => (
                                <TimelineItem
                                    key={`aula-${index}-${aula.id}`}
                                    id={`aula-${index}-${aula.id}`}
                                    sx={{ padding: '0px' }}
                                    position={'right'}
                                >
                                    <TimelineSeparator>
                                        <TimelineConnector
                                            sx={{
                                                bgcolor: aula.aulaAluno.length > 0 ? '#1463a6' : '#8d8d8d',
                                                boxShadow: `0px 0px 11px 0px ${
                                                    aula.aulaAluno.length > 0 ? '#1463a6' : '#8d8d8d'
                                                }`,
                                                width: '3px',
                                            }}
                                        />
                                        <div
                                            style={{
                                                display: 'flex',
                                                position: 'relative',
                                            }}
                                        >
                                            <div
                                                onClick={() => abrirAula(aula, index)}
                                                style={{
                                                    cursor: 'pointer',
                                                    width: '60px',
                                                    height: '60px',
                                                    padding: '8px',
                                                    // backgroundColor: '#cecece',
                                                    boxShadow: `0px 0px 8px 0px ${
                                                        aula.aulaAluno.length > 0 ? '#1463a6' : '#8d8d8d'
                                                    }`,
                                                    borderRadius: '8px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    border: `3px solid ${aula.aulaAluno.length > 0 ? '#1463a6' : '#8d8d8d'}`,
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        filter: 'drop-shadow(0px 0px 4px #ffffff)',
                                                        width: '60px',
                                                        animation: `float 2s ease-in-out infinite`,
                                                        filter:
                                                            aula.aulaAluno.length == 0
                                                                ? 'saturate(0)'
                                                                : 'saturate(1) drop-shadow(0px 0px 4px #ffffff87)',
                                                    }}
                                                    src={`${constantes.urlStorage}${aula.urlImagemNoJogo}`}
                                                />
                                            </div>
                                            {aula.aulaAluno.length == 0 && (
                                                <div style={{ position: 'absolute', bottom: '-10px', right: '-4px' }}>
                                                    <img
                                                        style={{ width: '25px' }}
                                                        src={`${constantes.urlStorage}${objConfigJornada.cadeado}`}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <TimelineConnector
                                            sx={{
                                                bgcolor: aula.aulaAluno.length > 0 ? '#1463a6' : '#8d8d8d',
                                                boxShadow: `0px 0px 11px 0px ${
                                                    aula.aulaAluno.length > 0 ? '#1463a6' : '#8d8d8d'
                                                }`,
                                                width: '3px',
                                            }}
                                        />
                                    </TimelineSeparator>
                                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                                        <ThemeProvider theme={darkTheme}>
                                            <Paper elevation={10} sx={{ borderRadius: '8px' }}>
                                                <CardAulaBody ativo={aula.aulaAluno.length > 0}>
                                                    <h2>{aula.titulo}</h2>
                                                    <div
                                                        style={{
                                                            borderRadius: '8px',
                                                            display: 'flex',
                                                            flexWrap: 'wrap',
                                                            justifyContent: 'flex-start',
                                                        }}
                                                    >
                                                        {aula.questoes.length > 0 && (
                                                            <Chip
                                                                sx={{
                                                                    marginRight: '3px',
                                                                    fontSize: '12px',
                                                                    marginTop: '3px',
                                                                    fontWeight: 500,
                                                                }}
                                                                size="small"
                                                                label={`${
                                                                    aula.questoes.length > 1
                                                                        ? `${aula.questoes.length} Questões`
                                                                        : '1 Questão'
                                                                }`}
                                                                color="success"
                                                            />
                                                        )}

                                                        {aula.idQualAulaLibera && (
                                                            <Chip
                                                                sx={{
                                                                    marginRight: '3px',
                                                                    fontSize: '12px',
                                                                    marginTop: '3px',
                                                                    fontWeight: 500,
                                                                }}
                                                                size="small"
                                                                label={`Caso Clinico`}
                                                                color="success"
                                                            />
                                                        )}

                                                        {aula.premioAula.length > 0 && (
                                                            <Chip
                                                                sx={{
                                                                    marginRight: '3px',
                                                                    fontSize: '12px',
                                                                    marginTop: '3px',
                                                                    fontWeight: 500,
                                                                }}
                                                                size="small"
                                                                avatar={<img style={{ height: '20px' }} src={diamante_156}></img>}
                                                                label="Diamante"
                                                                color="warning"
                                                            />
                                                        )}

                                                        {aula.sacoMoedas.length > 0 && (
                                                            <Chip
                                                                sx={{
                                                                    marginRight: '3px',
                                                                    fontSize: '12px',
                                                                    marginTop: '3px',
                                                                    fontWeight: 500,
                                                                }}
                                                                size="small"
                                                                avatar={<img style={{ height: '20px' }} src={coin_156}></img>}
                                                                label="Moedas"
                                                                color="error"
                                                            />
                                                        )}
                                                    </div>
                                                    <span style={{ marginTop: '10px' }}>{aula.descricao}</span>
                                                    <div
                                                        style={{
                                                            marginTop: '10px',
                                                            display: 'flex',
                                                            alignItems: 'flex-end',
                                                            justifyContent: 'space-between',
                                                        }}
                                                    >
                                                        <div
                                                            id="ContainerTimeAula"
                                                            style={{
                                                                flex: 1,
                                                                marginRight: '20px',
                                                                height: ' 3px',
                                                                background: `#37393e`,
                                                                position: 'relative',
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    position: 'absolute',
                                                                    bottom: '10px',
                                                                    fontWeight: 600,
                                                                    fontSize: '10px',
                                                                    color: '#b7b7b7',
                                                                }}
                                                            >
                                                                {aula.aulaAluno.length
                                                                    ? fmtMSS(
                                                                          aula.aulaAluno[0].aondeParouAula
                                                                              ? aula.aulaAluno[0].aondeParouAula
                                                                              : 0,
                                                                      )
                                                                    : 'Não assistiu'}
                                                            </span>
                                                            <div
                                                                style={{
                                                                    flex: 1,
                                                                    width:
                                                                        aula.aulaAluno.length > 0
                                                                            ? `${
                                                                                  (aula.aulaAluno[0].aondeParouAula * 100) /
                                                                                  aula.duracaoVideo
                                                                              }%`
                                                                            : '0%',
                                                                    height: ' 3px',
                                                                    boxShadow: `0px 0px 5px 0px ${themeDark.colors.verde}`,
                                                                    background: themeDark.colors.verde,
                                                                }}
                                                            ></div>
                                                            <span
                                                                style={{
                                                                    position: 'absolute',
                                                                    bottom: '10px',
                                                                    right: '0px',
                                                                    fontWeight: 600,
                                                                    fontSize: '10px',
                                                                    color: '#b7b7b7',
                                                                }}
                                                            >
                                                                {fmtMSS(aula.duracaoVideo)}
                                                            </span>
                                                        </div>
                                                        <ColorButton
                                                            disabled={aula.aulaAluno.length == 0}
                                                            onClick={() => abrirAula(aula, index)}
                                                            variant="contained"
                                                        >
                                                            Abrir
                                                        </ColorButton>
                                                    </div>
                                                </CardAulaBody>
                                            </Paper>
                                        </ThemeProvider>
                                    </TimelineContent>
                                </TimelineItem>
                            ))}
                        </Timeline>
                    </div>
                </Body>
            </Container>

            <IconeProfessorPergunta id="iconeProfessor">
                {isOpenSpeedDial && (
                    <MenuDuvida>
                        <div
                            onClick={() => {
                                setIsOpenSpeedDial(false);
                                storePopUps.openOnePopUp('perguntaEnviada');
                            }}
                        >
                            <SmartDisplayIcon />
                            <span>Assistir Respostas</span>
                        </div>
                        <div
                            onClick={() => {
                                setIsOpenSpeedDial(false);
                                storePopUps.openOnePopUp('enviarPergunta');
                            }}
                        >
                            <EmojiPeopleIcon />
                            <span>Enviar Pergunta</span>
                        </div>
                    </MenuDuvida>
                )}
                <Tooltip title={`Tire duvidas com professor.`}>
                    <img
                        src={`${constantes.urlStorage}${objConfigJornada.professor}`}
                        onClick={() => setIsOpenSpeedDial(isOpenSpeedDial ? false : true)}
                    />
                </Tooltip>
            </IconeProfessorPergunta>

            {storePopUps.popups.perguntaEnviada && (
                <PopUpPerguntaEnviada
                    closePopUp={() => storePopUps.closeAllPopUps()}
                    pauseAmbiente={ambienceAudio}
                    objConfigJornada={objConfigJornada}
                />
            )}

            {storePopUps.popups.listPDFs && (
                <PopUpListPDFs closePopUp={() => storePopUps.closeAllPopUps()} diamantesPegos={ScorePlayer.diamantes} />
            )}
            {storePopUps.popups.enviarPergunta && <PopUpEnviarPergunta closePopUp={() => storePopUps.closeAllPopUps()} />}

            {storePopUps.popups.auditorio && (
                <PopUpAuditorio
                    closePopUp={() => storePopUps.closeAllPopUps()}
                    pauseAmbiente={ambienceAudio}
                    aluno={aluno}
                    jornada={objJornada}
                />
            )}

            {storePopUps.popups.bibliografia && (
                <PopUpBibliografia
                    closePopUp={() => storePopUps.closeAllPopUps()}
                    pauseAmbiente={ambienceAudio}
                    biblioteca={bibliotecaSelecionada}
                />
            )}

            {storePopUps.popups.livros && (
                <PopUpLivros closePopUp={() => storePopUps.closeAllPopUps()} pauseAmbiente={ambienceAudio} />
            )}

            {storePopUps.popups.links && (
                <PopUpLinks closePopUp={() => storePopUps.closeAllPopUps()} pauseAmbiente={ambienceAudio} />
            )}

            {storePopUps.popups.help && (
                <PopUpHelp closePopUp={() => storePopUps.closeAllPopUps()} pauseAmbiente={ambienceAudio} />
            )}

            {aulaSelecionada && storeJornada.popUpVideoAulaAberto && (
                <PopUpVideo
                    objAulaSelecionada={aulaSelecionada}
                    pauseAmbiente={ambienceAudio}
                    objConfigJornada={objConfigJornada}
                />
            )}

            {satisfacao && <PopUpSatisfacao closePopUp={() => setSatisfacao(false)} pauseAmbiente={ambienceAudio} />}

            {casoClinicoSelecionado && storeCasoClinico.popUpVideoAulaAberto && (
                <PopUpPerguntasFase
                    objAulaSelecionada={casoClinicoSelecionado}
                    popUpAulaPerguntasIsOpen={storeCasoClinico.popUpVideoAulaAberto}
                    closePopUpAulaFase={() => storeCasoClinico.fechaPopupVideo()}
                    casoClinico={true}
                    pauseAmbiente={ambienceAudio}
                />
            )}

            {storeCongratulations.popups.isOpen && (
                <div
                    style={{
                        position: 'absolute',
                        top: '0px',
                        left: '0px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <GetCongratulations popUp={{ ...storeCongratulations.popups }} />
                </div>
            )}

            {storeTooltip.aberto && (
                <div
                    style={{
                        position: 'absolute',
                        top: '0px',
                        left: '0px',
                        width: '230px',
                        transform: calculatePosistionTolltip(storeTooltip.x, storeTooltip.y),
                        background: '#007bff ',
                        padding: '10px',
                        borderRadius: '3px',
                        color: '#fff',
                        fontSize: '14px',
                        display: 'flex',
                        animation: 'fadein 2s',
                        boxShadow: '2px 2px 10px #00000085',
                    }}
                >
                    <img src={Sticker} style={{ width: '60px' }} />
                    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'start' }}>
                        <span style={{ fontWeight: 600 }}>{storeTooltip.title}</span>
                        <span>{storeTooltip.texto}</span>
                    </div>
                </div>
            )}

            {boasVindas && (
                <div
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <img src={gifBoasVindas} style={{ width: '50%' }} />
                </div>
            )}

            {carregando && (
                <LoadingLottie />
                // <div style={{ position: 'absolute', background: 'red' }}>
                //     <h1>Carregando</h1>
                // </div>
            )}
        </>
    );
});

function calculatePosistionTolltip(x, y) {
    const paddingX = 105;
    const paddingY = 130;
    let translateX = x - paddingX;
    let translateY = y - paddingY;

    if (translateX < 130) {
        translateX = 140;
    } else if (translateX > window.innerWidth - 300) {
        translateX = window.innerWidth - 320;
    }

    if (translateY < 80) {
        translateY = 80;
    } else if (translateY > window.innerHeight - 130) {
        translateY = window.innerHeight - 140;
    }

    return `translateX(${translateX}px) translateY(${translateY}px)`;
}

function GetCongratulations({ popUp }) {
    if (popUp.diamante)
        return (
            <div>
                <img src={diamante_156} />
                <h1>Parabéns, você ganhou diamante!</h1>
            </div>
        );

    if (popUp.moeda)
        return (
            <div>
                <img src={coin_156} />
                <h1>Parabéns, você ganhou uma moeda!</h1>
            </div>
        );

    // if (popUp.diploma) return <h1>Parabenss!</h1>;

    if (popUp.diploma)
        return (
            <div>
                <img src={Diploma} />
                <h1>Parabéns, você finalizou o curso!</h1>
            </div>
        );

    return <h1>Deu rruim</h1>;
}

function fmtMSS(s) {
    return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s;
}
