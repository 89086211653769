import styled from 'styled-components';
import imgBackground from '../../../assets/background.jpg';

export const Container = styled.div`
    display: flex;
    height: 100vh;
    flex: 1%;
    flex-direction: column;
    overflow: hidden auto;
    /* background:${(p) => p.theme.colors.background} ; */
    /* :before {
        overflow: hidden;
        background: url(${imgBackground}) center center / cover no-repeat fixed;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        z-index: -1;
        filter: brightness(0.9) blur(49px) opacity(0.4);
    } */

    > .ContainerPhoto {
        flex: 1;
        overflow: hidden;
        position: fixed;
        z-index: -1;

        > img {
            width: 100%;
        }
    }
`;

export const ContainerBody = styled.div`
    display: flex;
    flex-direction: column;
`;
export const DivSkeleton = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
