import { useCallback, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import { useParams } from 'react-router-dom';
import api from '../../service/api';
import gameInitStore from '../../store/gameInitStore';
import { Container } from './stylesVideoPlayer';

function VideoPlayer({ idVideo, pauseAmbiente, aula, aondeParouVideo }) {
    const tempo = useRef();
    const referenciaVideo = useRef();
    const params = useParams();

    const setLogWatchedVideo = useCallback(
        (time) => {
            if (aula?.id && time) {
                api.put(`aulaAluno/${aula.id}/salvaAondeParou?tempo=${Number(time.toFixed())}`).then((resolve) => {
                    console.log(`Tempo de video salvo - ${time}`, resolve);
                    if (params?.idJornada) {
                        gameInitStore.getJornadaCompleta(params.idJornada);
                    }
                });
            }
        },
        [idVideo, params],
    );

    useEffect(() => {
        if (referenciaVideo?.current) {
            referenciaVideo.current.seekTo(aondeParouVideo, 'seconds');
        }
    }, [referenciaVideo]);

    useEffect(() => {
        return () => {
            setLogWatchedVideo(tempo.current);
        };
    }, []);

    return (
        <Container>
            <ReactPlayer
                ref={referenciaVideo}
                url={idVideo}
                progressInterval={3000}
                onProgress={(a) => {
                    console.log('progresso', a.playedSeconds);
                    tempo.current = a.playedSeconds;
                }}
                // onPause={(a) => console.log(a.target.current)}
                // onEnded={(a) => console.log(a)}
                // onError={(a) => console.log(a)}
                playing={true}
                width="100%"
                height="100%"
                controls={true}
            />
        </Container>
    );
}

export default VideoPlayer;
