import React from 'react';
import { useTheme } from '@emotion/react';
import { getAlunos } from '../../../admServices/aluno.service';
import { Typography, Box, Button } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { Container, ItemTabela, Tabela } from './Alunos.adm.styles';

function AlunosAdm() {
    const theme = useTheme();
    const [alunos, setAlunos] = React.useState([]);

    React.useEffect(() => {
        init();
    }, []);

    async function init() {
        try {
            const alunos = await getAlunos();
            setAlunos(alunos);
        } catch (error) {
            console.error(error);
        }
    }

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name) {
        try {
            return {
                sx: {
                    width: '50px',
                    height: '50px',
                    marginRight: '15px',
                    bgcolor: stringToColor(name),
                },
                children: `${name.split(' ')[0][0]}`,
            };
        } catch (error) {
            console.error(error);
        }
    }
    return (
        <Container>
            <Box display={'flex'} justifyContent={'space-between'} padding={'10px 15px'}>
                <Typography color={theme.palette.text.primary} variant="h4">
                    Alunos
                </Typography>
                <Button variant="contained">Novo Aluno</Button>
            </Box>
            <Tabela>
                {alunos.map((aluno) => (
                    <ItemTabela key={aluno.id} style={{ backgroundColor: theme.palette.background.default }} title="Abrir Curso">
                        <Avatar {...stringAvatar(aluno.nome)} />

                        <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} flex={1}>
                            <Box>
                                <Typography color={theme.palette.primary.dark} variant="h6" textAlign={'start'}>
                                    {aluno.nome}
                                </Typography>
                            </Box>
                            <Box display={'flex'} justifyContent={'space-between'}>
                                <div>
                                    <Typography
                                        color={theme.palette.text.primary}
                                        variant="body2"
                                        textAlign={'start'}
                                        fontWeight={600}
                                    >
                                        Moedas
                                    </Typography>
                                    <Typography color={theme.palette.text.secondary} textAlign={'start'} variant="body1">
                                        {aluno.moedas}
                                    </Typography>
                                </div>
                            </Box>
                            <Box display={'flex'} justifyContent={'space-between'}>
                                <div>
                                    <Typography
                                        color={theme.palette.text.primary}
                                        variant="body2"
                                        textAlign={'start'}
                                        fontWeight={600}
                                    >
                                        Celular
                                    </Typography>
                                    <Typography color={theme.palette.text.secondary} textAlign={'start'} variant="body1">
                                        <a style={{ color: theme.palette.text.secondary }} href={`tel:${aluno.celular}`}>
                                            {aluno.celular}
                                        </a>
                                    </Typography>
                                </div>
                                <div>
                                    <Typography
                                        color={theme.palette.text.primary}
                                        variant="body2"
                                        textAlign={'start'}
                                        fontWeight={600}
                                    >
                                        Email
                                    </Typography>
                                    <Typography color={theme.palette.text.secondary} textAlign={'start'} variant="body1">
                                        <a style={{ color: theme.palette.text.secondary }} href={`mailto:${aluno.email}`}>
                                            {aluno.email}
                                        </a>
                                    </Typography>
                                </div>

                                <div>
                                    <Typography
                                        color={theme.palette.text.primary}
                                        variant="body2"
                                        textAlign={'start'}
                                        fontWeight={600}
                                    >
                                        CPF
                                    </Typography>
                                    <Typography color={theme.palette.text.secondary} textAlign={'start'} variant="body1">
                                        {aluno.cpf}
                                    </Typography>
                                </div>
                            </Box>
                        </Box>
                    </ItemTabela>
                ))}
            </Tabela>
        </Container>
    );
}

export default AlunosAdm;
