import styled from 'styled-components';
import * as HoverCard from '@radix-ui/react-hover-card';

export const Container = styled.div`
    display: flex;
`;

export const CardJornada = styled.div`
    display: flex;
    position: relative;
    padding-left: 75px;

    > span {
        font-size: 200px;
        line-height: 200px;
        position: absolute;
        right: 85px;
        font-family: PT Serif;
        color: #681010;
    }

    > img {
        z-index: 1;
        height: 200px;
        width: 112px;
        object-fit: cover;
        transition-duration: 0.3s;
        cursor: pointer;
        border-radius: 4px;
        :hover {
            transform: scale(1.1);
        }
    }
`;

export const CardHover = styled(HoverCard.Content)`
    border-radius: 6px;
    overflow: hidden;
    width: 300px;
    background-color: #181818;
    color: #fefefe;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;

    /* &[data-side='top'] {
        animation-name: slideDownAndFade;
    }
    &[data-side='right'] {
        animation-name: slideLeftAndFade;
    }
    &[data-side='bottom'] {
        animation-name: slideUpAndFade;
    }
    &[data-side='left'] {
        animation-name: slideRightAndFade;
    } */
`;
