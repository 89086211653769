import React, { useMemo, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { DivMainMenu, ImgMenu } from './menuStyled';
import { observer } from 'mobx-react-lite';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import storePopUps from '../../store/StorePopUps';
import ScorePlayer from '../../store/scorePlayer';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Perfil from '../../assets/menuresponsivo/icone_perfil.png';
import Logout from '../../assets/menuresponsivo/Logout.png';
import TrocarSenha from '../../assets/menuresponsivo/TrocarSenha.png';
import Coin from '../../assets/compra/coin.png';
import Diamante from '../../assets/compra/diamante.png';
import Livros from '../../assets/menuresponsivo/icone_livros.png';
import Jornada from '../../assets/menuresponsivo/icone_jornada.png';
import MinhaJornada from '../../assets/menuresponsivo/icone_minha_jornada.png';
import Config from '../../assets/menuresponsivo/icone_conf.png';
import { toast } from 'react-toastify';
import ToastGame from '../toastGame/ToastGame';
import { useAuth } from '../../pages/auth/authProvider/AuthProvider';

import PopUpListPDFs from '../popUps/popUpListPDFs';
import { Dialog, ListItemIcon, ListItemText } from '@mui/material';
import PopupAtualizaSenha from '../popupAtualizaSenha/PopupAtualizaSenha';

function MenuComponent() {
    const auth = useAuth();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [hoverSelecionado, setHoverSelecionado] = useState({
        id: '',
    });
    const refElemento = useRef([]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // const arrayIcones = [
    //     { icone: Perfil, acao: () => navigate('/app'), title: 'Home', id: 'Home' },
    //     { icone: Coin, acao: () => alert('Sem opção'), title: 'Moedas', id: 'Moedas' },
    //     { icone: Diamante, acao: () => storePopUps.openOnePopUp('listPDFs'), title: 'Diamantes', id: 'Diamantes' },
    //     { icone: Config, acao: () => navigate('/app/configuracoes'), title: 'Configurações', id: 'Configuracoes' },
    //     { icone: Logout, acao: , title: 'Logout', id: 'Logout' },
    // ];

    return (
        <>
            <DivMainMenu id="menuPrincipal">
                <div className="containerItensMenuJogo">
                    <div onClick={() => navigate('/app')}>
                        <span>Home</span>
                    </div>
                    <div onClick={() => alert('Sem opção')}>
                        <span>Moedas</span>
                        <ImgMenu src={Coin} />
                    </div>
                    <div onClick={() => storePopUps.openOnePopUp('listPDFs')}>
                        <span>Diamantes</span>
                        <ImgMenu src={Diamante} />
                    </div>
                </div>
                <div className="containerItensMenuMe" onClick={handleClick}>
                    <ImgMenu src={Perfil} />
                    <span>Menu</span>
                </div>

                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            navigate('/app/configuracoes');
                            handleClose();
                        }}
                    >
                        <ListItemIcon>
                            <ImgMenu src={Config} />
                        </ListItemIcon>
                        <ListItemText>Configurações</ListItemText>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            auth.setOpenPopupSenha(true);
                            handleClose();
                        }}
                    >
                        <ListItemIcon>
                            <ImgMenu src={TrocarSenha} />
                        </ListItemIcon>
                        <ListItemText>Trocar Senha</ListItemText>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            auth.signout();
                            handleClose();
                        }}
                    >
                        <ListItemIcon>
                            <ImgMenu src={Logout} />
                        </ListItemIcon>
                        <ListItemText>Logout</ListItemText>
                    </MenuItem>
                </Menu>
            </DivMainMenu>
            <Dialog open={auth.openPopupSenha} onClose={() => auth.setOpenPopupSenha(false)}>
                <PopupAtualizaSenha />
            </Dialog>
            {storePopUps.popups.listPDFs && (
                <PopUpListPDFs closePopUp={() => storePopUps.closeAllPopUps()} diamantesPegos={ScorePlayer.diamantes} />
            )}
        </>
    );
}

export default observer(MenuComponent);
