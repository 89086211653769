import { Box, Button, Typography, useTheme } from '@mui/material';
import FlipCountdown from '@rumess/react-flip-countdown';
import { ContadorPromocao, ContainerPromocao, ContainerTextosPromocao } from './Promocao.styles';

function Promocao({ dadosCurso, acaoBtn }) {
    const theme = useTheme();

    return (
        <ContainerPromocao bgcolor={theme.palette.primary.dark}>
            <ContadorPromocao
                display={'flex'}
                flexDirection={'column'}
                position={'absolute'}
                justifySelf={'flex-end'}
                bottom={'55px'}
                borderRadius={'8px'}
                padding={'10px 15px'}
                bgcolor={theme.palette.primary.dark}
            >
                <Typography fontWeight={'600'} fontSize={'20px'}>
                    {dadosCurso.promocao_titulo}
                </Typography>
                <FlipCountdown
                    monthTitle="Meses"
                    dayTitle="Dias"
                    hourTitle="Horas"
                    minuteTitle="Minutos"
                    secondTitle="Segundos"
                    hideYear
                    size="small"
                    endAt={dadosCurso.promocao_dtFim} // Date/Time
                />
            </ContadorPromocao>
            <ContainerTextosPromocao>
                <Box>
                    <Box>
                        <Typography fontWeight={'600'} fontSize={'20px'}>
                            {dadosCurso.promocao_texto}
                        </Typography>
                    </Box>
                </Box>
                {acaoBtn && (
                    <Box justifySelf={'flex-end'}>
                        <Button onClick={acaoBtn} variant="contained" color="success" sx={{ fontWeight: '600' }}>
                            Aproveitar Desconto de {dadosCurso.promocao_percentualDesconto}%
                        </Button>
                    </Box>
                )}
            </ContainerTextosPromocao>
        </ContainerPromocao>
    );
}

export default Promocao;
