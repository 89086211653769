import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import ListElementosJornada from '../components/listElementosJornada/ListElementosJornada.adm';
function JornadaElementos() {
    const { jornada } = useOutletContext();
    const navegate = useNavigate();
    const elementosOrdenados = useMemo(() => jornada.aulas.sort((a, b) => a.ordem - b.ordem), [jornada]);
    return (
        <Box display={'flex'} flexDirection={'column'} padding={'10px'}>
            <Box display={'flex'} justifyContent={'space-between'} padding={'10px 15px'}>
                <Typography variant="h5" textAlign={'center'}>
                    Elementos da Jornada
                </Typography>
                <Button variant="contained" color="success" startIcon={<AddIcon />}>
                    Adicionar Elemento
                </Button>
            </Box>
            <ListElementosJornada
                elemento={elementosOrdenados}
                click={(elemento) => {
                    navegate(`${elemento.id}/alunos`);
                }}
            />
        </Box>
    );
}

export default JornadaElementos;
