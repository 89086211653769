import axios from 'axios';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { isCPF } from 'validation-br';
import backgroundControlinhos from '../../assets/background_cadastro.jpg';
import logo from '../../assets/login/logo.png';
import avatar from '../../assets/menuresponsivo/icone_perfil.png';
import { Button, Input, Select } from '../../components/commonComponents/commonComponents';
import LoadingLottie from '../../components/loadingLottie/loadingLottie';
import PopupMontaPersonagem from '../../components/popUps/popupMontaPersonagem';
import ToastGame from '../../components/toastGame/ToastGame';
import api from '../../service/api';
import gameInitStore from '../../store/gameInitStore';
import constantes from '../../utils/constantes';
import {
    Avatar,
    Body,
    BodyBorda,
    Container,
    Content,
    DivContent,
    DivImgAvatar,
    DivInfoAluno,
    DivLogo,
    Img,
    SubErrorForm,
} from './styledConfiguracoes';
export default function Configuracoes() {
    const [dadosAluno, setDadosAluno] = useState({
        id: 0,
        nome: '',
        cpf: '',
        nascimento: undefined,
        sexo: '',
        celular: '',
        cep: '',
        endereco: '',
        numero: '',
        complemento: '',
        estado: '',
        cidade: '',
        bairro: '',
        senha: '',
        novaSenha: '',
    });
    const [openpopup, setOpenpopup] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const { register, handleSubmit, watch, errors, control, getValues, setValue, setError, clearErrors } = useForm();
    async function carregaDadosAluno() {
        setOpenLoading(true);
        try {
            const dadosAluno = await gameInitStore.getAluno();
            const { id, nome, cpf, celular, nascimento, sexo, cep, endereco, numero, complemento, estado, cidade, bairro } =
                dadosAluno;
            setDadosAluno({
                ...dadosAluno,
                id: id ?? '',
                nome: nome ?? '',
                cpf: cpf ?? '',
                nascimento: nascimento ? dayjs(nascimento).format('YYYY-MM-DD') : undefined,
                sexo: sexo ?? '',
                celular: celular ?? '',
                cep: cep ?? '',
                endereco: endereco ?? '',
                numero: numero ?? '',
                complemento: complemento ?? '',
                estado: estado ?? '',
                cidade: cidade ?? '',
                bairro: bairro ?? '',
            });
        } catch (error) {
            console.error(error);
            toast.error(<ToastGame title={'Error'} text={'Não foi possível carregar dados do aluno'} />);
        }
        setOpenLoading(false);
    }
    async function salvaDadosAluno() {
        setOpenLoading(true);
        try {
            if (isCPF(dadosAluno.cpf)) {
                const responseSalvaDados = await api.put(`/Aluno/${dadosAluno.id}`, dadosAluno);
                if (responseSalvaDados.status == 200) toast.success(<ToastGame title={'Dados atualizados com sucesso.'} />);
                else throw `Erro ao salvar informações.`;
            } else {
                toast.warning(<ToastGame title={'CPF inválido'} />);
            }
            setOpenLoading(false);
        } catch (error) {
            console.error(error);
            toast.error(<ToastGame title={'Erro ao salvar informações.'} />);
            setOpenLoading(false);
        }
    }
    async function carregaDadosCep(cep) {
        try {
            if (cep.length == 8) {
                const responseCarregaDadosCep = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
                if (responseCarregaDadosCep.status == 200) {
                    const { data } = responseCarregaDadosCep;
                    const { logradouro, complemento, bairro, localidade, uf, erro } = data;
                    if (erro) {
                        toast.warning(<ToastGame title="CEP inválido ou não encontrado." text="Aviso" />);
                    } else {
                        setDadosAluno({
                            ...dadosAluno,
                            endereco: logradouro,
                            complemento: complemento,
                            bairro,
                            cidade: localidade,
                            estado: uf,
                        });
                    }
                }
            }
        } catch (error) {
            toast.error(<ToastGame title={error || 'Não foi possível carregar informações do cep.'} text="Erro" />);
        }
    }
    function verificaCpf(cpf) {
        if (cpf == '') return;
        const verificaCpf = isCPF(cpf);
        if (!verificaCpf) toast.warning(<ToastGame title={'CPF inválido'} />);
    }
    useEffect(() => {
        carregaDadosAluno();
    }, []);

    return (
        <Container image={backgroundControlinhos}>
            <DivLogo>
                <Img src={constantes.logo} onError={(e) => (e.target.src = logo)} />
            </DivLogo>
            <BodyBorda>
                <Body
                    onSubmit={(event) => {
                        event.preventDefault();
                        salvaDadosAluno();
                    }}
                >
                    <Content>
                        <DivInfoAluno>
                            <DivContent>
                                <span>Nome:*</span>
                                <Input
                                    required
                                    name="Nome"
                                    {...register('Nome', { required: 'Nome é obrigatório' })}
                                    type="text"
                                    value={dadosAluno.nome}
                                    onChange={({ target }) => setDadosAluno({ ...dadosAluno, nome: target.value })}
                                    placeholder="Informe seu nome aqui."
                                />
                                {errors?.Nome && errors?.Nome?.type == 'required' && (
                                    <SubErrorForm>{errors?.Nome?.message}</SubErrorForm>
                                )}
                            </DivContent>
                            <DivContent style={{ flex: '0.2' }}>
                                <span>Nascimento:</span>
                                <Input
                                    type="date"
                                    value={dadosAluno.nascimento}
                                    onChange={({ target }) => setDadosAluno({ ...dadosAluno, nascimento: target.value })}
                                />
                            </DivContent>
                        </DivInfoAluno>
                        <DivInfoAluno>
                            <DivContent>
                                <span>CPF:*</span>
                                <Input
                                    required
                                    type="text"
                                    value={dadosAluno.cpf}
                                    placeholder="Informe seu cpf."
                                    maxLength={11}
                                    onChange={({ target }) =>
                                        setDadosAluno({
                                            ...dadosAluno,
                                            cpf: target.value
                                                .replace(/[a-z]/g, '')
                                                .replace(/[A-Z]/g, '')
                                                .replace(/[^a-zA-Z 0-9]+/g, ''),
                                        })
                                    }
                                    onBlur={({ target }) => verificaCpf(target.value)}
                                />
                            </DivContent>
                            <DivContent>
                                <span>Sexo :</span>
                                <Select
                                    style={{ margin: '5px 0' }}
                                    value={dadosAluno.sexo}
                                    onChange={({ target }) => setDadosAluno({ ...dadosAluno, sexo: target.value })}
                                >
                                    <option value=""></option>
                                    <option value="M">Masculino</option>
                                    <option value="F">Feminino</option>
                                </Select>
                            </DivContent>
                        </DivInfoAluno>
                        <DivInfoAluno>
                            <DivContent>
                                <span>Celular:*</span>
                                <Input
                                    type={'text'}
                                    required
                                    placeholder="Informe seu celular."
                                    value={dadosAluno.celular}
                                    onChange={({ target }) => setDadosAluno({ ...dadosAluno, celular: target.value })}
                                />
                            </DivContent>
                            <DivContent>
                                <span>Cep:*</span>
                                <Input
                                    required
                                    type={'text'}
                                    value={dadosAluno.cep}
                                    placeholder="Informe seu cep."
                                    onBlur={({ target }) => carregaDadosCep(target.value)}
                                    onChange={({ target }) =>
                                        setDadosAluno({
                                            ...dadosAluno,
                                            cep: String(target.value)
                                                .replace(/[a-z]/g, '')
                                                .replace(/[A-Z]/g, '')
                                                .replace(/[^a-zA-Z 0-9]+/g, ''),
                                        })
                                    }
                                />
                            </DivContent>
                        </DivInfoAluno>
                        <DivInfoAluno>
                            <DivContent>
                                <span>Endereco:*</span>
                                <Input
                                    required
                                    placeholder="Informe seu endereço."
                                    type="text"
                                    value={dadosAluno.endereco}
                                    onChange={({ target }) => setDadosAluno({ ...dadosAluno, endereco: target.value })}
                                />
                            </DivContent>
                            <DivContent style={{ flex: '0.2' }}>
                                <span>Número:*</span>
                                <Input
                                    required
                                    placeholder="Informe o número do seu endereço."
                                    type="text"
                                    value={dadosAluno.numero}
                                    onChange={({ target }) => setDadosAluno({ ...dadosAluno, numero: target.value })}
                                />
                            </DivContent>
                            <DivContent style={{ flex: '0.4' }}>
                                <span>Complemento:</span>
                                <Input
                                    placeholder="Informe o complemento do seu endereço."
                                    type="text"
                                    value={dadosAluno.complemento}
                                    onChange={({ target }) => setDadosAluno({ ...dadosAluno, complemento: target.value })}
                                />
                            </DivContent>
                        </DivInfoAluno>
                        <DivInfoAluno>
                            <DivContent>
                                <span>Bairro:*</span>
                                <Input
                                    required
                                    placeholder="Informe seu bairro."
                                    type="text"
                                    value={dadosAluno.bairro}
                                    onChange={({ target }) => setDadosAluno({ ...dadosAluno, bairro: target.value })}
                                />
                            </DivContent>
                            <DivContent>
                                <span>Cidade:*</span>
                                <Input
                                    required
                                    placeholder="Informe a sua cidade."
                                    type="text"
                                    value={dadosAluno.cidade}
                                    onChange={({ target }) => setDadosAluno({ ...dadosAluno, cidade: target.value })}
                                />
                            </DivContent>
                        </DivInfoAluno>
                        <DivInfoAluno>
                            <DivContent>
                                <span>Estado:*</span>
                                <Select
                                    style={{ margin: '5px 0' }}
                                    required
                                    id="estado"
                                    name="estado"
                                    value={dadosAluno.estado}
                                    onChange={({ target }) => setDadosAluno({ ...dadosAluno, estado: target.value })}
                                >
                                    <option value=""> </option>
                                    <option value="AC">Acre</option>
                                    <option value="AL">Alagoas</option>
                                    <option value="AP">Amapá</option>
                                    <option value="AM">Amazonas</option>
                                    <option value="BA">Bahia</option>
                                    <option value="CE">Ceará</option>
                                    <option value="DF">Distrito Federal</option>
                                    <option value="ES">Espírito Santo</option>
                                    <option value="GO">Goiás</option>
                                    <option value="MA">Maranhão</option>
                                    <option value="MT">Mato Grosso</option>
                                    <option value="MS">Mato Grosso do Sul</option>
                                    <option value="MG">Minas Gerais</option>
                                    <option value="PA">Pará</option>
                                    <option value="PB">Paraíba</option>
                                    <option value="PR">Paraná</option>
                                    <option value="PE">Pernambuco</option>
                                    <option value="PI">Piauí</option>
                                    <option value="RJ">Rio de Janeiro</option>
                                    <option value="RN">Rio Grande do Norte</option>
                                    <option value="RS">Rio Grande do Sul</option>
                                    <option value="RO">Rondônia</option>
                                    <option value="RR">Roraima</option>
                                    <option value="SC">Santa Catarina</option>
                                    <option value="SP">São Paulo</option>
                                    <option value="SE">Sergipe</option>
                                    <option value="TO">Tocantins</option>
                                </Select>
                            </DivContent>
                        </DivInfoAluno>
                        <DivInfoAluno>
                            <Button>Salvar</Button>
                        </DivInfoAluno>
                    </Content>
                    <Avatar>
                        <DivImgAvatar>
                            <img style={{ width: '100%' }} src={avatar} />
                        </DivImgAvatar>
                        <Button onClick={() => setOpenpopup(true)}>Configurar avatar</Button>
                    </Avatar>
                </Body>
            </BodyBorda>
            {openpopup && <PopupMontaPersonagem closePopUp={() => setOpenpopup(false)} />}
            {openLoading && <LoadingLottie />}
        </Container>
    );
}
