import React, { useEffect, useState } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { Container } from './popUpStyles';
import imgClose from '../../assets/close.png';
import imgDiamante from '../../game/assets/diamante.png';
import { toJS } from 'mobx';
import constantes from '../../utils/constantes';
import ScorePlayer from '../../store/scorePlayer';
import { observer } from 'mobx-react-lite';

const PopUpListPDFs = ({ closePopUp, diamantesPegos }) => {
    const [mostraPopUpListaPDFs, setMostraPopUpListaPDFs] = useState({ isOpen: true, indexPergunta: 0 });
    const [pdfSelecionado, setPdfSelecionado] = useState('');

    useEffect(() => {
        ScorePlayer.atualizaSaldo();
    }, []);

    return (
        <Container className="popup" id="popUpListPDFs" style={{ display: mostraPopUpListaPDFs ? 'flex' : 'none', left: '0px' }}>
            <div className="contentPopUp">
                <div className="TitlePopUp">
                    <span id="TitlePopUp">{`Seus diamantes`}</span>
                </div>
                <div className="close" onClick={() => (pdfSelecionado ? setPdfSelecionado('') : closePopUp())}>
                    <img src={imgClose} />
                </div>
                <div className="conteudoPopUp">
                    {pdfSelecionado ? (
                        <div style={{ flex: 1, borderRadius: '8px', overflow: 'hidden' }}>
                            <iframe
                                style={{ border: 'none' }}
                                id="pdf"
                                data=""
                                type="application/pdf"
                                width="100%"
                                height="100%"
                                src={pdfSelecionado}
                            />
                        </div>
                    ) : (
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            {ScorePlayer.diamantes.length ? (
                                ScorePlayer.diamantes.map((diamante) => {
                                    return (
                                        <div
                                            style={{
                                                display: 'flex',
                                                cursor: 'pointer',
                                                alignItems: 'center',
                                                justifyContent: 'space-around',
                                                padding: '0px 10px',
                                            }}
                                            onClick={() => {
                                                setPdfSelecionado(toJS(diamante.url));
                                            }}
                                        >
                                            <div style={{ width: '50px' }}>
                                                <img
                                                    style={{ width: '100%' }}
                                                    src={`${constantes.urlStorage}${diamante.urlImagemNoJogo}`}
                                                />
                                            </div>
                                            <span
                                                style={{
                                                    fontSize: '20px',
                                                    flex: 1,
                                                    color: '#fff',
                                                    margin: '10px 10px',
                                                    textAlign: 'start',
                                                }}
                                            >
                                                {diamante.descricao}
                                            </span>
                                            <button
                                                style={{
                                                    background: '#2dae32',
                                                    color: '#fff',

                                                    border: 'none',
                                                    borderRadius: '8px',
                                                    padding: '0px 10px',
                                                    height: '40px',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                Abrir Item
                                            </button>
                                        </div>
                                    );
                                })
                            ) : (
                                <div style={{ color: '#fff' }}>
                                    <h1>😔 Poxa, você ainda não possui diamantes.</h1>
                                    <h3 style={{ fontWeight: 400 }}>
                                        Que tal assistir algumas aulas? Basta clicar nas bandeiras.
                                    </h3>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </Container>
    );
};

export default observer(PopUpListPDFs);
