import React, { useEffect, useMemo, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Container, ContainerListGames, CardJornada } from './stylesHome';
import { MdSearch } from 'react-icons/md';
import api from '../../service/api';
import gameInitStore from '../../store/gameInitStore';
import { toast } from 'react-toastify';
import ToastGame from '../../components/toastGame/ToastGame';
import { useAuth } from '../auth/authProvider/AuthProvider';

const isMedx = localStorage.getItem('@jornada-userEmail')?.includes('medx');
function Home() {
    const auth = useAuth();
    const [minhasJornadas, setMinhasJornadas] = useState([]);
    const [ultimasAulasJornadas, setUltimasAulasJornadas] = useState([]);
    const [todosCursos, setTodosCursos] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const ultimasJornadas = useMemo(() => ultimasAulasJornadas.map((item) => item.jornada), [ultimasAulasJornadas]);

    async function carregaTodosCursos() {
        try {
            const responseGetAllCursos = await api.get(`/curso`);
            if (responseGetAllCursos.status == 200) setTodosCursos(responseGetAllCursos.data);
        } catch (error) {
            console.error(error);
            toast.error(<ToastGame title={'Erro ao carregar lista de cursos'} text={'Erro.'} />);
        }
    }

    useEffect(() => {
        api.get('Aluno/me/jornadas')
            .then(({ data }) => {
                setMinhasJornadas(data);
                gameInitStore.jornadasDoAluno = data;
            })
            .catch((error) => {
                console.error(error);
            });
        api.get('Aluno/me/lastJornadas')
            .then(({ data }) => {
                let jornadas = {};
                data.forEach((aula) => {
                    if (jornadas[aula.aula.idJornada]) {
                        if (jornadas[aula.aula.idJornada].ultimaVisita) {
                            const dataAulaOld = new Date(jornadas[aula.aula.idJornada].ultimaVisita);
                            const dataAulaNew = new Date(aula.updatedAt);
                            if (dataAulaNew > dataAulaOld) {
                                jornadas[aula.aula.idJornada] = {
                                    jornada: aula.aula.jornada,
                                    ultimaVisita: aula.updatedAt,
                                    aondeParouAula: aula.aondeParouAula,
                                    idAula: aula.idAula,
                                };
                            }
                        }
                    } else {
                        jornadas[aula.aula.idJornada] = {
                            jornada: aula.aula.jornada,
                            ultimaVisita: aula.updatedAt,
                            aondeParouAula: aula.aondeParouAula,
                            idAula: aula.idAula,
                        };
                    }
                });
                setUltimasAulasJornadas(Object.keys(jornadas).map((key) => jornadas[key]));
            })
            .catch((error) => {
                console.error(error);
            });

        carregaTodosCursos();
    }, []);

    useEffect(() => {
        if (location?.state?.trocarSenha) {
            auth.setOpenPopupSenha(true);
        }
    }, [location]);

    return (
        <Container style={{ overflow: 'hidden' }}>
            <ContainerListGames>
                <div className="HeaderCarrocel">
                    <h2>Ultimas Jornadas Que Fiz</h2>
                </div>
                <div className="ContainerCarrocel">
                    <Carousel
                        additionalTransfrom={0}
                        arrows
                        partialVisible={true}
                        autoPlaySpeed={9000000}
                        autoPlay={false}
                        centerMode={false}
                        className=""
                        containerClass="container-with-dots"
                        dotListClass=""
                        draggable
                        focusOnSelect={false}
                        infinite
                        itemClass=""
                        keyBoardControl
                        minimumTouchDrag={80}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        responsive={responsive}
                        showDots={false}
                        sliderClass=""
                        slidesToSlide={1}
                        swipeable
                    >
                        {ultimasJornadas.map((jornada, index) => {
                            return (
                                <CardJornada key={`visto-${jornada.id}`} img={jornada.urlImagemApresentacao}>
                                    <div className="title-jornada-card" onClick={() => navigate(`./minhajornada/${jornada.id}`)}>
                                        <h3>{jornada.nome}</h3>
                                        <span>{jornada.descricao}</span>
                                        <button>Retornar Jornada</button>
                                    </div>
                                </CardJornada>
                            );
                        })}
                    </Carousel>
                </div>
                <div className="HeaderCarrocel">
                    <h2>Minhas Jornadas</h2>
                </div>
                <div className="ContainerCarrocel">
                    <Carousel
                        additionalTransfrom={0}
                        arrows
                        partialVisible={true}
                        autoPlaySpeed={9000000}
                        autoPlay={false}
                        centerMode={false}
                        className=""
                        containerClass="container-with-dots"
                        dotListClass=""
                        draggable
                        focusOnSelect={false}
                        infinite
                        itemClass=""
                        keyBoardControl
                        minimumTouchDrag={80}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        responsive={responsive}
                        showDots={false}
                        sliderClass=""
                        slidesToSlide={1}
                        swipeable
                    >
                        {minhasJornadas.map((jornada, index) => {
                            return (
                                <CardJornada key={`visto-${jornada.id}`} img={jornada.urlImagemApresentacao}>
                                    <div className="title-jornada-card" onClick={() => navigate(`./minhajornada/${jornada.id}`)}>
                                        <h3>{jornada.nome}</h3>
                                        <span>{jornada.descricao}</span>
                                        <button>Retornar Jornada</button>
                                    </div>
                                </CardJornada>
                            );
                        })}
                    </Carousel>
                </div>

                <div className="HeaderCarrocel">
                    <h2>Todos os Cursos</h2>
                </div>
                <div className="ContainerCarrocel">
                    <Carousel
                        partialVisible={true}
                        additionalTransfrom={0}
                        arrows
                        autoPlaySpeed={9000000}
                        autoPlay={false}
                        centerMode={false}
                        className=""
                        containerClass="container-with-dots"
                        dotListClass=""
                        draggable
                        focusOnSelect={false}
                        infinite
                        itemClass=""
                        keyBoardControl
                        minimumTouchDrag={80}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        responsive={responsive}
                        showDots={false}
                        sliderClass=""
                        slidesToSlide={1}
                        swipeable
                    >
                        {todosCursos
                            .filter((curso) => (isMedx ? true : curso.id != '1eee8aed-723e-40fa-bca6-2fb4e123e3c5'))
                            .map((curso, index) => {
                                return (
                                    <CardJornada key={`Nvisto-${curso.id}`} img={curso.urlImagem}>
                                        <div
                                            className="title-jornada-card"
                                            onClick={() =>
                                                navigate(
                                                    `curso/${curso.alias}?alias=${curso.alias}&lead=7c790ede-02c3-48f7-bcaf-8d135a471617`,
                                                )
                                            }
                                        >
                                            <h3>{curso.nome}</h3>
                                            <span>{curso.descricao}</span>
                                            <button>Comprar curso</button>
                                        </div>
                                    </CardJornada>
                                );
                            })}
                    </Carousel>
                </div>
            </ContainerListGames>
        </Container>
    );
}

export default Home;

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 1400 },
        items: 3,
        partialVisibilityGutter: 40,
    },
    desktop: {
        breakpoint: { max: 1400, min: 1024 },
        items: 2,
        partialVisibilityGutter: 40,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        partialVisibilityGutter: 30,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        partialVisibilityGutter: 30,
    },
};
