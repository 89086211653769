import React, { useState, useEffect } from 'react';
import produce from 'immer';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Box from '@mui/material/Box';
import gameInitStore from '../../store/gameInitStore';
import { observer } from 'mobx-react-lite';
import { Container } from './popUpStyles';
import imgClose from '../../assets/close.png';
import { Alert, Button } from '@mui/material';

const OPCOES = ['A', 'B', 'C', 'D', 'E'];
const OPCOES_ESTENDIDA = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];

const loadImage = (src) =>
    new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = src;
    });

const PopupMontaPersonagem = observer(({ closePopUp }) => {
    const [generoMasculino, setGeneroMasculino] = useState(true);
    const [urls, setUrls] = useState({ pele: '', cabelo: '', calca: '', oculos: '', camisa: '', sapato: '', vestido: '' });
    const [opcoes, setOpoes] = useState({ pele: 0, cabelo: 0, calca: 0, oculos: 0, camisa: 0, sapato: 0, vestido: null });
    const [parteEditando, setParteEditando] = useState('pele');

    useEffect(() => {
        if (Object.keys(urls).every((item) => urls[item])) {
            const canvas = document.getElementById('canvasMostraAvatar');
            const ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            const linksFiltados = Object.keys(urls)
                .map((key) => ({ url: urls[key], selecionado: opcoes[key], parte: key }))
                .filter((i) => i.selecionado != null);

            console.log(linksFiltados);

            const canvas2 = document.getElementById('canvas2');
            const ctx2 = canvas2.getContext('2d');
            ctx2.clearRect(0, 0, canvas2.width, canvas2.height);

            Promise.all(linksFiltados.map((item) => loadImage(item.url))).then((images) => {
                images.forEach((image) => {
                    ctx.drawImage(image, 64 * 8, 0, image.width, image.height, 0, 0, image.width, image.height);
                    ctx2.drawImage(image, 0, 0, image.width, image.height, 0, 0, image.width, image.height);
                });
            });
        }
    }, [urls]);

    async function getBase64() {
        const fileType = 'image/png';
        const canvas = document.getElementById('canvas2');
        const base64Canvas = canvas.toDataURL(fileType);

        const objctSaveAvatar = {
            file_base64: base64Canvas,
            filename: 'avatar',
            filesize: 0,
            filetype: '',
        };

        try {
            const image = await fetch(base64Canvas);
            const imageBlob = await image.blob();
            objctSaveAvatar.filetype = imageBlob.type;
            objctSaveAvatar.filesize = imageBlob.size;
        } catch (error) {
            return Alert('Erro ao tentar manipular imagem.');
        }

        const aluno = await gameInitStore.salvaAvatar(objctSaveAvatar);
        if (aluno) {
            console.log(aluno.urlAzureAvatar);
            closePopUp();
        } else {
            alert('Erro ao tentar salvar avatar.');
        }
    }

    return (
        <Container className="popup" id="popupMontaPersonagem" style={{ display: 'flex' }}>
            <div className="contentPopUp">
                <div className="TitlePopUp">
                    <span id="TitlePopUp">{`Montando Personagem`}</span>
                </div>
                <div className="close" onClick={() => closePopUp()}>
                    <img src={imgClose} />
                </div>
                <div className="conteudoPopUp">
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center' }}>
                                <h2 style={{ margin: '0px 0px 10px 0px', fontWeight: 500, fontSize: '20px' }}>Qual gênero?</h2>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <ToggleButtonGroup
                                        color="error"
                                        size="small"
                                        value={generoMasculino}
                                        exclusive
                                        onChange={(e, newAlignment) => setGeneroMasculino(newAlignment)}
                                        aria-label="text alignment"
                                    >
                                        <ToggleButton value={true}>Masculino</ToggleButton>
                                        <ToggleButton value={false}>Feminino</ToggleButton>
                                    </ToggleButtonGroup>
                                </Box>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center' }}>
                                <h2 style={{ margin: '0px 0px 10px 0px', fontWeight: 500, fontSize: '20px' }}>
                                    Qual parte deseja mudar?
                                </h2>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <ToggleButtonGroup
                                        color="error"
                                        size="small"
                                        value={parteEditando}
                                        exclusive
                                        onChange={(e, newAlignment) => setParteEditando(newAlignment)}
                                        aria-label="text alignment"
                                    >
                                        {Object.keys(urls).map((parte, index) => (
                                            <ToggleButton key={`escolhaPart-${parte}`} value={parte}>
                                                {parte}
                                            </ToggleButton>
                                        ))}
                                    </ToggleButtonGroup>
                                </Box>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center' }}>
                                <h2 style={{ margin: '10px 0px 10px 0px', fontWeight: 500, fontSize: '20px' }}>Opção?</h2>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <ToggleButtonGroup
                                        color="error"
                                        size="small"
                                        value={opcoes[parteEditando]}
                                        exclusive
                                        onChange={(e, newAlignment) =>
                                            setOpoes(
                                                produce((draft) => {
                                                    if (parteEditando == 'vestido') {
                                                        // draft.camisa = null;
                                                        draft.calca = null;
                                                    } else if (parteEditando == 'camisa' || parteEditando == 'calca') {
                                                        draft.vestido = null;
                                                        if (parteEditando == 'camisa' && draft.calca == null) {
                                                            draft.calca = 0;
                                                        }
                                                    }
                                                    draft[parteEditando] = newAlignment;
                                                }),
                                            )
                                        }
                                        aria-label="text alignment"
                                    >
                                        {(parteEditando == 'cabelo' ? OPCOES_ESTENDIDA : OPCOES).map((opcao, index) => (
                                            <ToggleButton key={`escolhaOpcao-${opcao}`} value={index}>
                                                {`${index + 1}ª`}
                                            </ToggleButton>
                                        ))}
                                    </ToggleButtonGroup>
                                </Box>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <canvas
                                    width={64}
                                    height={128}
                                    style={{ width: 64, height: 128 }}
                                    id="canvasMostraAvatar"
                                ></canvas>
                            </div>

                            <canvas style={{ display: 'none' }} width={1864} height={128} id="canvas2"></canvas>
                            <div className="ContainerImages">
                                <img
                                    style={{ display: 'none' }}
                                    id="imgPele"
                                    onLoad={(e) =>
                                        setUrls(
                                            produce((draft) => {
                                                draft.pele = e.target.src;
                                            }),
                                        )
                                    }
                                    src={require(`../../assets/personagem_opcoes/Masculino/Pele/Avatar_Sprite_${
                                        OPCOES[opcoes.pele] || 'A'
                                    }.png`)}
                                />
                                <img
                                    id="imgCabelo"
                                    style={{ display: 'none' }}
                                    onLoad={(e) =>
                                        setUrls(
                                            produce((draft) => {
                                                draft.cabelo = e.target.src;
                                            }),
                                        )
                                    }
                                    src={require(`../../assets/personagem_opcoes/Masculino/Cabelo/Avatar_Sprite_${
                                        OPCOES_ESTENDIDA[opcoes.cabelo] || 'A'
                                    }.png`)}
                                />
                                <img
                                    id="imgCalca"
                                    style={{ display: 'none' }}
                                    onLoad={(e) =>
                                        setUrls(
                                            produce((draft) => {
                                                draft.calca = e.target.src;
                                            }),
                                        )
                                    }
                                    src={require(`../../assets/personagem_opcoes/Masculino/Calca/Avatar_Sprite_${
                                        OPCOES[opcoes.calca] || 'A'
                                    }.png`)}
                                />
                                <img
                                    id="imgOculos"
                                    style={{ display: 'none' }}
                                    onLoad={(e) =>
                                        setUrls(
                                            produce((draft) => {
                                                draft.oculos = e.target.src;
                                            }),
                                        )
                                    }
                                    src={require(`../../assets/personagem_opcoes/Masculino/Oculos/Avatar_Sprite_${
                                        OPCOES[opcoes.oculos] || 'A'
                                    }.png`)}
                                />
                                <img
                                    id="imgSapato"
                                    style={{ display: 'none' }}
                                    onLoad={(e) =>
                                        setUrls(
                                            produce((draft) => {
                                                draft.sapato = e.target.src;
                                            }),
                                        )
                                    }
                                    src={require(`../../assets/personagem_opcoes/Masculino/Sapato/Avatar_Sprite_${
                                        OPCOES[opcoes.sapato] || 'A'
                                    }.png`)}
                                />
                                <img
                                    id="imgCamisa"
                                    style={{ display: 'none' }}
                                    onLoad={(e) =>
                                        setUrls(
                                            produce((draft) => {
                                                draft.camisa = e.target.src;
                                            }),
                                        )
                                    }
                                    src={require(`../../assets/personagem_opcoes/Masculino/Camisa/Avatar_Sprite_${
                                        OPCOES[opcoes.camisa] || 'A'
                                    }.png`)}
                                />
                                <img
                                    id="imgVestido"
                                    style={{ display: 'none' }}
                                    onLoad={(e) =>
                                        setUrls(
                                            produce((draft) => {
                                                draft.vestido = e.target.src;
                                            }),
                                        )
                                    }
                                    src={require(`../../assets/personagem_opcoes/Feminino/Vestido/Avatar_Sprite_${
                                        OPCOES_ESTENDIDA[opcoes.vestido] || 'A'
                                    }.png`)}
                                />
                            </div>
                            <div>
                                <Button variant="contained" onClick={getBase64}>
                                    Salvar
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
});

export default PopupMontaPersonagem;
