import styled from 'styled-components';

export const Container = styled.div`
    min-height: 100vh;
    padding: 10px;
    align-items: center;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    /* background-color: ${(p) => p.theme.colors.background}; */
    color: ${(p) => p.theme.colors.text};
`;
export const DivBodyCompra = styled.div`
    display: flex;
    max-width: 1200px;
    flex: 1;
    overflow: hidden;
    height: fit-content;

    @media screen and (max-width: 767px) {
        flex-direction: column;
    }
`;
export const Section = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const DivApresentacao = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ImgApresentacao = styled.img`
    max-height: 50vh;
    width: fit-content;

    @media screen and (max-width: 767px) {
        width: 100%;
    }
`;

export const PApresentacao = styled.p`
    margin: 12px;
    text-align: start;
    font-size: 22px;
    font-family: 'Nunito Sans', sans-serif;

    @media screen and (max-width: 767px) {
        text-align: center;
    }
`;

export const Aside = styled.aside`
    /* width: 30%; */
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media screen and (max-width: 767px) {
        padding: 8px;
        width: 100%;
        height: auto;
        margin-top: 25px;
    }
    @media screen and (max-width: 1500px) {
        width: 100%;
        padding: 0;
        align-items: center;
    }
`;

export const ImgSticker = styled.img`
    width: 100px;
    border-radius: 100px;
`;
export const DivInfoValor = styled.div`
    background: linear-gradient(90deg, rgba(208, 208, 208, 1) 0%, rgba(0, 212, 255, 1) 54%, rgba(33, 171, 227, 1) 100%);
    padding: 2px;
    border-radius: 16px;
    margin-bottom: 36px;
    width: 500px;
    @media screen and (max-width: 767px) {
        width: calc(100% - 32px);
    }
    @media screen and (max-width: 1500px) {
        width: calc(100% - 40px);
    }
`;
export const DivInternaValor = styled.div`
    background-color: ${(p) => p.theme.colors.background};
    display: flex;
    flex-direction: column;
    padding: 12px 24px;
    border-radius: 16px;
    @media screen and (max-width: 767px) {
        padding: 10px;
    }
`;
export const DivImgLogo = styled.div`
    margin-top: 24px;
`;

export const H1TituloJornada = styled.h1`
    margin: 36px 0 0 0;
    font-size: 70px;
    @media screen and (max-width: 767px) {
        display: none;
    }
`;
export const ContainerProgramacao = styled.div`
    display: flex;
    flex-direction: column;
    text-align: start;
    flex: 1;
    width: 100%;

    > h3 {
        text-align: center;
        font-size: 30px;
        border-bottom: 1px solid ${(p) => p.theme.colors.primary};
        padding-bottom: 10px;
    }
`;
export const DivProgramacao = styled.div`
    display: flex;
    flex-direction: column;
    text-align: start;

    > div {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid #242424;
    }
`;
export const TitulAula = styled.div`
    font-size: 20px;
    margin-bottom: 12px;
    font-weight: 600;
    letter-spacing: 1px;
`;
export const DescricaoAula = styled.div`
    margin-bottom: 12px;
    font-size: 14px;
`;
