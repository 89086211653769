import Phaser from 'phaser';

import SacoMoedas from './sacoMoedas';
import Diamantes from './diamantes';
import StoreCasoClinico from '../../store/StoreCasoClinico';
import storeTooltip from '../../store/StoreTooltip';
import storeToast from '../../store/StoreToast';
import storeJornada from '../../store/StoreJornada';
import storeCasoClinico from '../../store/StoreCasoClinico';

import api from '../../service/api';

export default class CasoClinico extends Phaser.GameObjects.Image {
    constructor(scene, x, y, casoClinico, player, debloqueadoInit) {
        super(scene, x, y, 'imgPredio_campus2');
        this.casoClinico = casoClinico;
        this.sacoMoedas = casoClinico.sacoMoedas;
        this.diamantes = casoClinico.premioAula;
        this.meuCadeado = null;
        this.scene.add.existing(this);
        this.player = player;
        this.infosAula = null;
        this.interactveAula = this.setInteractive();
        this.desbloqueado = debloqueadoInit;
        this._initClickOn();
        this.init();
        this.scene.physics.world.enableBody(this, 0);
    }

    init() {
        this.scale = 1.3;
        if (!this.desbloqueado) {
            //inicia a bandeira bandeiras bloqueadas com cadeado
            this.meuCadeado = this.scene.add.image(this.x - 50, this.y + 145, 'cadeadoNew');
            this.meuCadeado.scale = 0.4;
            this.meuCadeado.setDepth(10);
        }
    }

    desbloqueiaCasoClinico() {
        (async () => {
            if (this.meuCadeado) this.meuCadeado.visible = false;
            storeToast.togggleToast(true, '🏥 Casos clínicos', '🎉 Parabéns, você liberou casos clínicos.');
            this.desbloqueado = true;
            await this.getAulaAluno();
        })();
    }

    openPopUp() {
        (async () => {
            await this.putAbriAula();
            StoreCasoClinico.abrePopupVideo(this.casoClinico.id);

            if (this.sacoMoedas.length && !this.infosAula.pegouSacoMoedas) {
                this.sacoMoedas.forEach((sacoMoedas) => {
                    new SacoMoedas(this.scene, sacoMoedas.posicaoX, sacoMoedas.posicaoY, null, this.player, this, sacoMoedas);
                });
            }
            if (this.diamantes.length && !this.infosAula.pegouPremio) {
                this.diamantes.forEach((diamante) => {
                    new Diamantes(this.scene, diamante.posicaoX, diamante.posicaoY, this.player, this, diamante.url, diamante);
                });

                // this.diamante.setColoder();
            }
        })();
    }

    _initClickOn() {
        this.interactveAula.on('pointerup', () => {
            if (storeJornada.popUpVideoAulaAberto || storeCasoClinico.popUpVideoAulaAberto)
                return console.log('tentou abrir outro popup por cima.');

            if (!this.desbloqueado) {
                storeToast.togggleToast(true, '🏥 Poxa 😔.', 'Estes casos clinicos ainda não foram desbloqueados.');

                setTimeout(() => {
                    // só para limpar o obj
                    storeToast.togggleToast(false, '', '');
                }, 2000);

                return; // Finaliza o click caso a aula ainda estaja bloqueada.
            }

            const { x: PlayerX, y: PlayerY } = this.player.getPositionInTiles();
            this.scene.findPathAndMove(
                PlayerX,
                PlayerY,
                this.casoClinico.posicaoJogadorX,
                this.casoClinico.posicaoJogadorY,
                this.openPopUp.bind(this),
            );
        });

        // this.scene.physics.add.overlap(this.player, this, this._collectCoins.bind(this));
    }

    async getAulaAluno() {
        try {
            const { data } = await api.get(`aulaAluno/${this.casoClinico.id}/criaOuPega`);
            this.atualizaInfosAula(data);
            return data;
        } catch (error) {
            console.error(error);
        }
    }

    async putAbriAula() {
        try {
            const { data } = await api.put(`aulaAluno/${this.casoClinico.id}/abriuAula`);
            this.atualizaInfosAula(data);
            return data;
        } catch (error) {
            console.error(error);
        }
    }

    atualizaInfosAula(data) {
        if (data) {
            this.infosAula = {
                abriuAula: data.abriuAula,
                idAula: data.idAula,
                aondeParouAula: data.aondeParouAula,
                pegouPremio: data.pegouPremio,
                pegouSacoMoedas: data.pegouSacoMoedas,
            };
        }
    }
}
