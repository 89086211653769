import React, { useMemo } from 'react';
import { Container } from './erroGenerico.adm.styles';
import { useNavigate, useRouteError } from 'react-router-dom';
import { Button, Typography, useTheme } from '@mui/material';

function ErroGenericoAdm() {
    const error = useRouteError();
    const theme = useTheme();
    const navegate = useNavigate();
    const mensagemErroAPI = useMemo(() => {
        const menesagensErro = error?.response?.data?.title;
        return menesagensErro ? menesagensErro : '';
    }, [error]);

    function voltar() {
        navegate(-1);
    }

    return (
        <Container style={{ color: theme.palette.text.primary }}>
            <Typography variant="h1" fontWeight={700}>
                Oops!
            </Typography>
            <Typography variant="h3" color={'error'}>
                {error?.response?.status}
            </Typography>
            <Typography variant="h4">{error?.message}</Typography>
            <Typography variant="h4">{mensagemErroAPI}</Typography>
            <Button variant="contained" onClick={voltar}>
                Voltar
            </Button>
        </Container>
    );
}

export default ErroGenericoAdm;
