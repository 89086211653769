const constantes = {
    keyCaptcha: '6LehhcoaAAAAAM88Uc2_-GeZ9N-p_KKGe3PGrsvh',
    urlSITE: 'https://gammyx-gammyxteste.azurewebsites.net',
    urlSocket: 'https://gammyxchat.azurewebsites.net',
    storageEmail: '@jornada-userEmail',
    storageToken: '@jornada-Token',
    storageRefreshToken: '@jornada-RefreshToken',
    storageAluno: '@gammyx-aluno',

    storageAdmEmail: '@Adm-userEmail',
    storageAdmToken: '@Adm-Token',
    storageAdmRefreshToken: '@Adm-RefreshToken',
    storageAdm: '@gammyx-Adm',
    storageHotjar: '@hotjar',
    urlStorage: 'https://medxdata.blob.core.windows.net/gammyx/',
    logo: `https://medxdata.blob.core.windows.net/gammyx/${window.location.host.replaceAll('.', '')}.png`,
    APIUrl: window.location.origin.includes('localhost')
        ? `${window.location.origin}/api/`.replace('3000', '5025')
        : `${window.location.origin}/api/`,
};

export default constantes;
