import constantes from '../utils/constantes';

export const TOKEN_KEY = constantes.storageToken;
export const REFRESHTOKEN_KEY = constantes.storageRefreshToken;
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const setToken = (token) => localStorage.setItem(TOKEN_KEY, token);
export const removeToken = () => localStorage.removeItem(TOKEN_KEY);
export const setRefreshToken = (refreshToken)=> localStorage.setItem(REFRESHTOKEN_KEY, JSON.stringify(refreshToken));
export const getRefreshToken = ()=> localStorage.getItem(REFRESHTOKEN_KEY);

const AuthProviderFunc = {
    isAuthenticated: false,
    async signin(callback) {
        AuthProviderFunc.isAuthenticated = true;
        callback();
    },
    signout(callback) {
        removeToken();
        callback();
    },
};

export { AuthProviderFunc };
