import axios from 'axios';
import api from './api';
import constantes from '../utils/constantes';

export const apiUrl = 'login/administrador';

export const loginService = async (credenciais) => {
    const { data } = await api.post(apiUrl, credenciais);
    return data;
};

export const esqueciSenhaService = async (email) => {
    const { data } = await api.post(`${apiUrl}?email=${email}`);
    return data;
};

export const testTokenService = async (token) => {
    const { data: user } = await axios.get(`${constantes.APIUrl}administrador/me`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return user;
};
