import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Breadcrumbs, Tab, Tabs, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import { Suspense, useState } from 'react';
import { Await, Outlet, Link as RouterLink, useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';

import { Container } from './cursoEdit.adm.styles';

const tabs = [
    { label: 'Dashboard', path: 'dashboard' },
    { label: 'Alunos', path: 'alunos' },
    { label: 'Jornadas', path: 'jornadas' },
    { label: 'Cupons', path: 'cupons' },
    { label: 'Configurações', path: 'configuracoes' },
];

function CursosEditAdm() {
    const cursoLoaded = useLoaderData();
    const navegate = useNavigate();
    const location = useLocation();
    const param = useParams();
    const [curso, setCurso] = useState(cursoLoaded || {});

    const [valueTab, setValueTab] = useState(() => {
        const i = tabs.findIndex((i) => location.pathname.includes(`/${i.path}`));
        return i == -1 ? 0 : i;
    });

    return (
        <Suspense
            fallback={
                <Container>
                    <h1>Carregando dados do curso...</h1>
                </Container>
            }
        >
            <Await resolve={cursoLoaded}>
                {(curso) => (
                    <Container>
                        <Box>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                <Link
                                    title="Todos os cursos"
                                    to="/app/cursos"
                                    key="1"
                                    color="text.primary"
                                    variant="h6"
                                    underline="hover"
                                    component={RouterLink}
                                >
                                    Cursos
                                </Link>
                                <Typography key="2" color="primary" variant="h6" title="Nome do curso">
                                    {curso.nome}
                                </Typography>
                            </Breadcrumbs>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <Tabs
                                value={valueTab}
                                onChange={(event, newValue) => setValueTab(newValue)}
                                aria-label="nav tabs example"
                                variant="fullWidth"
                            >
                                {tabs.map((i) => (
                                    <Tab key={i.path} label={i.label} onClick={() => navegate(i.path)} />
                                ))}
                            </Tabs>
                        </Box>
                        <Box display={'flex'} flex={'1'} flexDirection={'column'} overflow={'auto'}>
                            <Outlet context={{ curso, setCurso }} />
                        </Box>
                    </Container>
                )}
            </Await>
        </Suspense>
    );
}

export default CursosEditAdm;
