import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Drawer, Typography, useTheme } from '@mui/material';
import { useNavigate, useOutlet, useOutletContext, useParams } from 'react-router-dom';
import ContainerContentDrawer from '../components/containerContentDrawer/ContainerContentDrawer';
import { ItemTabela, Tabela } from './cursos.adm.styles';

function CursosCupom({ cursoProp }) {
    const { curso, setCurso } = useOutletContext();
    const navegate = useNavigate();
    const param = useParams();
    const theme = useTheme();
    const outlet = useOutlet();

    return (
        <Box display={'flex'} flexDirection={'column'} padding={'10px'}>
            <Box display={'flex'} justifyContent={'space-between'} padding={'10px 15px'}>
                <Typography variant="h5" textAlign={'center'}>
                    Cupons do Curso
                </Typography>
                <Button variant="contained" color="success" startIcon={<AddIcon />} onClick={() => navegate('0')}>
                    Criar Cupom
                </Button>
            </Box>
            <Tabela>
                {curso.cupons.map((cupom) => (
                    <ItemTabela
                        key={cupom.id}
                        style={{ backgroundColor: theme.palette.background.default }}
                        title="Abrir Curso"
                        onClick={() => navegate(cupom.id)}
                    >
                        <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} flex={1}>
                            <Box>
                                <Typography color={theme.palette.primary.dark} variant="h6" textAlign={'start'}>
                                    Cupom: {cupom.cupom}
                                </Typography>
                            </Box>
                            <Box display={'flex'} gap={'20px'} justifyContent={'space-around'}>
                                <Box>
                                    <Typography
                                        color={theme.palette.text.primary}
                                        variant="body2"
                                        textAlign={'start'}
                                        fontWeight={600}
                                    >
                                        Percentual
                                    </Typography>
                                    <Typography
                                        className="textoDescricao"
                                        variant="body1"
                                        color={theme.palette.text.secondary}
                                        textAlign={'start'}
                                    >
                                        {cupom.percentual}%
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography
                                        color={theme.palette.text.primary}
                                        variant="body2"
                                        textAlign={'start'}
                                        fontWeight={600}
                                    >
                                        Validade
                                    </Typography>
                                    <Typography
                                        className="textoDescricao"
                                        variant="body1"
                                        color={theme.palette.text.secondary}
                                        textAlign={'start'}
                                    >
                                        {new Date(cupom.validade).toLocaleDateString()}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography
                                        color={theme.palette.text.primary}
                                        variant="body2"
                                        textAlign={'start'}
                                        fontWeight={600}
                                    >
                                        Data de Criacao
                                    </Typography>
                                    <Typography
                                        className="textoDescricao"
                                        variant="body1"
                                        color={theme.palette.text.secondary}
                                        textAlign={'start'}
                                    >
                                        {new Date(cupom.dataCriacao).toLocaleDateString()}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </ItemTabela>
                ))}
            </Tabela>
            <Drawer anchor={'right'} open={!!outlet} onClose={() => navegate(`/app/cursos/${param.idCurso}/cupons`)}>
                <ContainerContentDrawer>{outlet}</ContainerContentDrawer>
            </Drawer>
        </Box>
    );
}

export default CursosCupom;
