import { Box, Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useMemo } from 'react';
import { Form, useOutletContext } from 'react-router-dom';

export async function salvaDadosJornada({ request, params }) {
    const formData = await request.formData();
    const updates = Object.fromEntries(formData);
    console.log('Salvou os dados da jornada');
    console.log('updates', updates);
    console.log('params', params);
    return null; //redirect(`/contacts/${params.contactId}`);
}

function JornadaConfiguracoes() {
    const { jornada } = useOutletContext();

    const objConfigurationMap = useMemo(() => {
        try {
            return jornada?.jsonConfigurationMap ? JSON.parse(jornada.jsonConfigurationMap) : {};
        } catch (error) {
            console.error(error);
            console.error('Erro ao tentar converter para json objeto => jsonConfigurationMap da jornada');
            return {};
        }
    }, [jornada]);

    return (
        <Box display={'flex'} flexDirection={'column'} padding={'20px 10px'} gap={'25px'} textAlign={'start'}>
            <Typography variant="h5" textAlign={'start'}>
                Configurações da jornada
            </Typography>
            <Box
                display={'flex'}
                title="Este campo é um campo de referencia para o curso, não sem preocupe com este valor, ele é gerado automaticamente."
            >
                <Typography textAlign={'start'} fontWeight={600} variant="body1" marginRight={'10px'}>
                    Identificador da Jornada:
                </Typography>
                <Typography textAlign={'start'} variant="body1">
                    {jornada.id}
                </Typography>
            </Box>
            <Form method="post" id="jornada-config-form" style={{ display: 'flex', flexDirection: 'column', gap: '25px' }}>
                <Box>
                    <TextField name="nome" label={'Nome do Curso'} defaultValue={jornada.nome} fullWidth />
                </Box>
                <Box>
                    <TextField
                        name="alias"
                        label={'Alias do Curso'}
                        helperText={`O alias do curso, é um nome reduzido sem espaço ou caracteres especiais que sera usado para crir uma URL "amigavel" para o aluno`}
                        value={jornada.alias}
                        fullWidth
                    />
                </Box>
                <Box>
                    <TextField
                        name="descricao"
                        label={'Descricao do Curso'}
                        multiline
                        defaultValue={jornada.descricao}
                        fullWidth
                    />
                </Box>
                <Box>
                    <TextField
                        name="descricaoResumo"
                        label={'Descricao Resumida do Curso'}
                        helperText={`A descrição resumida deve ser pequena contendo apenas algumas palavas descrevendo o curso`}
                        multiline
                        maxRows={1}
                        fullWidth
                    />
                </Box>
                <Box>
                    <TextField
                        name="descricaoResumo"
                        label={'Descricao Resumida do Curso'}
                        helperText={`A descrição resumida deve ser pequena contendo apenas algumas palavas descrevendo o curso`}
                        multiline
                        maxRows={1}
                        defaultValue={jornada.descricaoResumo}
                        fullWidth
                    />
                </Box>
                <Box marginTop={'15px'} borderBottom={'1px solid'}>
                    <Typography variant="subtitle1" textAlign={'start'}>
                        Configurações do mapa do Game
                    </Typography>
                </Box>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel>Tiles do campus</InputLabel>
                        <Select
                            label="Tiles do campus"
                            defaultValue={objConfigurationMap.campus.tiles}
                            inputProps={{
                                name: 'campus-tiles',
                                id: 'campus-tiles',
                            }}
                        >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={objConfigurationMap.campus.tiles}>item selecionado</MenuItem>
                        </Select>
                        <FormHelperText>Tiles é um arquivo png, com as informaçõos para montar o jogo</FormHelperText>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel>Tiles de Elements do campus</InputLabel>
                        <Select
                            label="Tiles de Elements do campus"
                            defaultValue={objConfigurationMap.campus.tilesElements}
                            inputProps={{
                                name: 'campus-tilesElements',
                                id: 'campus-tilesElements',
                            }}
                        >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={objConfigurationMap.campus.tilesElements}>
                                {objConfigurationMap.campus.tilesElements}
                            </MenuItem>
                        </Select>
                        <FormHelperText>
                            Tiles dos elementos é um arquivo png com elementos que compõem o game, com as informaçõos para montar
                            o jogo
                        </FormHelperText>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel>Json do campus</InputLabel>
                        <Select
                            label="Json do campus"
                            defaultValue={objConfigurationMap.campus.map}
                            inputProps={{
                                name: 'campus-map',
                                id: 'campus-map',
                            }}
                        >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={objConfigurationMap.campus.map}>{objConfigurationMap.campus.map}</MenuItem>
                        </Select>
                        <FormHelperText>Json Map é um arquivo json com a configuração geral de como monar o jogo</FormHelperText>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel>Imagem de predio "generico" 1</InputLabel>
                        <Select
                            label="Imagem de predio ''generico'' 1"
                            defaultValue={objConfigurationMap.imgPredio_campus1}
                            inputProps={{
                                name: 'campus-imgPredio_campus1',
                                id: 'campus-imgPredio_campus1',
                            }}
                        >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={objConfigurationMap.imgPredio_campus1}>
                                {objConfigurationMap.imgPredio_campus1}
                            </MenuItem>
                        </Select>
                        <FormHelperText>
                            Imagem png que representara um predio generico(com funcionalidade somente estética)
                        </FormHelperText>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel>Imagem de predio "generico" 2</InputLabel>
                        <Select
                            label="Imagem de predio ''generico'' 2"
                            defaultValue={objConfigurationMap.imgPredio_campus2}
                            inputProps={{
                                name: 'campus-imgPredio_campus2',
                                id: 'campus-imgPredio_campus2',
                            }}
                        >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={objConfigurationMap.imgPredio_campus2}>
                                {objConfigurationMap.imgPredio_campus2}
                            </MenuItem>
                        </Select>
                        <FormHelperText>
                            Imagem png que representara um predio generico(com funcionalidade somente estética)
                        </FormHelperText>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel>Imagem do premio pela aula</InputLabel>
                        <Select
                            label="Imagem do premio pela aula"
                            defaultValue={objConfigurationMap.diamante}
                            inputProps={{
                                name: 'campus-diamante',
                                id: 'campus-diamante',
                            }}
                        >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={objConfigurationMap.diamante}>{objConfigurationMap.diamante}</MenuItem>
                        </Select>
                        <FormHelperText>
                            Imagem png que representara o prêmio a ser pego quando se conclui algumas aulas especificas
                        </FormHelperText>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel>Imagem da moedo do game</InputLabel>
                        <Select
                            label="Imagem da moedo do game"
                            defaultValue={objConfigurationMap.sacodemoedas}
                            inputProps={{
                                name: 'campus-sacodemoedas',
                                id: 'campus-sacodemoedas',
                            }}
                        >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={objConfigurationMap.sacodemoedas}>{objConfigurationMap.sacodemoedas}</MenuItem>
                        </Select>
                        <FormHelperText>Imagem png que representara a moeda do game</FormHelperText>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel>Imagem do predio de auditório</InputLabel>
                        <Select
                            label="Imagem do predio de auditório"
                            defaultValue={objConfigurationMap.predio_auditorio}
                            inputProps={{
                                name: 'campus-predio_auditorio',
                                id: 'campus-predio_auditorio',
                            }}
                        >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={objConfigurationMap.predio_auditorio}>
                                {objConfigurationMap.predio_auditorio}
                            </MenuItem>
                        </Select>
                        <FormHelperText>Imagem png que representara o predio de auditório</FormHelperText>
                    </FormControl>
                </Box>

                <Box>
                    <FormControl fullWidth>
                        <InputLabel>Imagem do/da professor/professora</InputLabel>
                        <Select
                            label="Imagem do professor"
                            defaultValue={objConfigurationMap.professor}
                            inputProps={{
                                name: 'campus-professor',
                                id: 'campus-professor',
                            }}
                        >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={objConfigurationMap.professor}>{objConfigurationMap.professor}</MenuItem>
                        </Select>
                        <FormHelperText>Imagem png que representara o/a professor/professora</FormHelperText>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel>Imagem da placa de duvidas</InputLabel>
                        <Select
                            label="Imagem da placa de duvidas"
                            defaultValue={objConfigurationMap.placa_duvidas}
                            inputProps={{
                                name: 'campus-placa_duvidas',
                                id: 'campus-placa_duvidas',
                            }}
                        >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={objConfigurationMap.placa_duvidas}>{objConfigurationMap.placa_duvidas}</MenuItem>
                        </Select>
                        <FormHelperText>Imagem png da placa duvidas</FormHelperText>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel>Imagem do cadeado</InputLabel>
                        <Select
                            label="Imagem do cadeado"
                            defaultValue={objConfigurationMap.cadeado}
                            inputProps={{
                                name: 'campus-cadeado',
                                id: 'campus-cadeado',
                            }}
                        >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={objConfigurationMap.cadeado}>{objConfigurationMap.cadeado}</MenuItem>
                        </Select>
                        <FormHelperText>
                            Imagem png do cadeado que representa que as aulas ainda não estão liberadas
                        </FormHelperText>
                    </FormControl>
                </Box>
                <Box>
                    <Button variant="contained" fullWidth type="submit">
                        Salvar Alterações
                    </Button>
                </Box>
            </Form>
        </Box>
    );
}
export default JornadaConfiguracoes;
