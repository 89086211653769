import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { Container } from './popUpStyles';
import imgClose from '../../assets/close.png';
import VideoPlayer from '../videoPlayer/VideoPlayer';
import styled from 'styled-components';
import { BsArrowBarRight } from 'react-icons/bs';
import randomcolor from 'randomcolor';
import ScrollToBottom, { useScrollToBottom, useSticky } from 'react-scroll-to-bottom';
import api from '../../service/api';
import { useAuth } from '../../pages/auth/authProvider/AuthProvider';

export const TextArea = styled.textarea`
    height: calc(100% - 10px);
    width: calc(100% - 10px);
    padding: 5px 5px;
    border-radius: 6px;
    background-color: #41414b;
    color: #fff;
    font-size: 16px;

    :focus {
        background-color: #070708;
        ::placeholder {
            color: #070708;
        }
    }

    ::placeholder {
        color: #fff;
        text-align: center;
        padding-top: 10px;
    }
`;

export const Mensagem = styled.div`
    padding: 1px 0px;
`;
export const ContainerScroll = styled.div`
    padding: 5px 0px;
`;

const listOfColors = randomcolor({
    count: 200,
    luminosity: 'bright',
    format: 'rgb', // e.g. 'rgb(225,200,20)'
});

const PopUpAuditorio = ({ closePopUp, pauseAmbiente, aluno, jornada }) => {
    const { socket } = useAuth();
    const scrollToBottom = useScrollToBottom();
    const meId = useMemo(() => aluno.id, [aluno]);
    const [chatAberto, setChatAberto] = useState(true);
    const [pessoas, setPessoas] = useState({});
    const [estaConectado, setEstaConectado] = useState(false);
    const [mensagens, setMensagens] = useState([]);
    const refTextArea = useRef();

    useEffect(() => {
        if (socket) {
            socket.on('join', (content) => {
                if (content.status) {
                    setEstaConectado(true);
                }
                console.log('Você se juntou a sala: ', content);
            });

            socket.on('receive-message-chatJornada', (content) => {
                console.log('Mensagem recebida na sala ', content);
                if (content.room_id == jornada.id) {
                    if (content.gammyxId != meId) {
                        if (!pessoas[content.gammyxId]) {
                            setPessoas((old) => ({
                                ...old,
                                [content.gammyxId]: { color: listOfColors[Math.floor(Math.random() * 199)] },
                            }));
                        }
                    }
                    setMensagens((old) => [...old, content]);
                }
            });
        }

        return () => {
            socket.off('join');
            socket.off('receive-message-chatJornada');
        };
    }, [socket, jornada, aluno, pessoas]);

    const sendMessage = useCallback(() => {
        const message = refTextArea.current.value;
        if (!message || message == '\n') return (refTextArea.current.value = '');
        socket.emit('send-message-chatJornada', { message });
        const messageOBJ = {
            gammyxId: meId,
            name: aluno.nome,
            room_id: jornada.id,
            message: message,
        };
        setMensagens((old) => [...old, messageOBJ]);
        refTextArea.current.value = '';
    }, [socket, aluno, jornada]);

    useEffect(() => {
        if (socket) {
            const objEntrarSala = {
                name: aluno.nome,
                room_id: jornada.id,
                gammyxId: aluno.id,
            };

            socket.on('connect', () => {
                socket.emit('join', objEntrarSala);
            });

            socket.emit('join', objEntrarSala);
        }

        return () => {
            console.log('Saindo da sala');
            if (socket) {
                socket.off('connect');
                socket.emit('leave', {
                    room_id: jornada.id,
                    gammyxId: aluno.id,
                });
            }
        };
    }, []);

    return (
        <Container className="popup" id="popAuditorio" style={{ display: 'flex' }}>
            <div className="contentPopUp">
                <div className="TitlePopUp">
                    <span id="TitlePopUp">{`Auditório - Curso de obesidade`}</span>
                </div>
                <div className="close" onClick={() => closePopUp()}>
                    <img src={imgClose} />
                </div>
                <div className="conteudoPopUp">
                    <div className="containerVideoChatAuditorio">
                        <div className="containerVideoAuditorio">
                            <VideoPlayer idVideo={'ec5e5f468b3b3f82ae75147374f56c0e'} pauseAmbiente={pauseAmbiente} />
                            {!chatAberto && (
                                <div
                                    onClick={() => setChatAberto(true)}
                                    style={{
                                        cursor: 'pointer',
                                        padding: ' 5px',
                                        borderRadius: ' 5px',
                                        backgroundColor: ' rgb(53 53 60)',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        borderBottom: '1px solid #494952',
                                        position: 'absolute',
                                        right: '30px',
                                        top: '30px',
                                        color: '#fff',
                                        transform: 'rotate(180deg)',
                                    }}
                                    title={`Abrir chat`}
                                >
                                    <BsArrowBarRight style={{ fontSize: 20 }} />
                                </div>
                            )}
                        </div>

                        {estaConectado && chatAberto && (
                            <div className="containerChatAuditorio">
                                <div
                                    style={{
                                        padding: 10,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div
                                        onClick={() => setChatAberto(false)}
                                        className="fecharChatAuditorio"
                                        style={{}}
                                        title="Fechar chat"
                                    >
                                        <BsArrowBarRight style={{ fontSize: 20 }} />
                                    </div>
                                    <span style={{ flex: 1 }}>Chat da Transmissão</span>
                                </div>
                                <ContainerScroll style={{ flex: 1, display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
                                    <ScrollToBottom className="customScroll">
                                        {mensagens.map((mensagem, index) => (
                                            <Mensagem key={index}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        color:
                                                            mensagem.gammyxId == meId ? 'red' : pessoas[mensagem.gammyxId].color,
                                                        alignItems: 'baseline',
                                                    }}
                                                >
                                                    <span>{`${mensagem.gammyxId == meId ? 'Eu' : mensagem.name}:`}</span>
                                                    <span
                                                        style={{
                                                            display: 'flex',
                                                            fontSize: '14px',
                                                            lineHeight: '16px',
                                                            color: '#b5b5b5',
                                                            marginLeft: '5px',
                                                            textAlign: 'justify',
                                                        }}
                                                    >
                                                        {mensagem.message}
                                                    </span>
                                                </div>
                                                {/* <div
                                                    style={{
                                                        display: 'flex',
                                                        fontSize: '14px',
                                                        color: '#b5b5b5',
                                                    }}
                                                >
                                                    <span>{mensagem.message}</span>
                                                </div> */}
                                            </Mensagem>
                                        ))}
                                    </ScrollToBottom>
                                </ContainerScroll>
                                <div style={{ height: 60, padding: '10px 5px' }}>
                                    <TextArea
                                        ref={refTextArea}
                                        placeholder="Escreva sua mensagem aqui!"
                                        onKeyPress={(event) => {
                                            if (event.key === 'Enter') {
                                                sendMessage();
                                                // const mensagemEscrita = refTextArea.current.value;
                                                // setMensagens((old) => [
                                                //     ...old,
                                                //     {
                                                //         id: Math.random() * (99999 - 1) + 1,
                                                //         idUsuario: 1,
                                                //         usuario: 'Lucas',
                                                //         mensagem: mensagemEscrita,
                                                //         data: new Date(),
                                                //     },
                                                // ]);
                                                // refTextArea.current.value = '';
                                            }
                                        }}
                                    ></TextArea>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default PopUpAuditorio;

const _mensagens = [
    { id: 123, idUsuario: 0, usuario: 'Artur', mensagem: 'Teste de mensagem', data: new Date() },
    { id: 12233, idUsuario: 1, usuario: 'Lucas', mensagem: 'Teste de mensagem', data: new Date() },
    { id: 12443, idUsuario: 2, usuario: 'Matheus', mensagem: 'Teste de mensagem', data: new Date() },
    { id: 15823, idUsuario: 1, usuario: 'Lucas', mensagem: 'Teste de mensagem', data: new Date() },
    { id: 12963, idUsuario: 2, usuario: 'Matheus', mensagem: 'Teste de mensagem', data: new Date() },
    { id: 865, idUsuario: 3, usuario: 'Carlos', mensagem: 'Teste de mensagem', data: new Date() },
    { id: 234, idUsuario: 1, usuario: 'Lucas', mensagem: 'Teste de mensagem', data: new Date() },
    { id: 745234234, idUsuario: 2, usuario: 'Matheus', mensagem: 'Teste de mensagem', data: new Date() },
    { id: 68, idUsuario: 0, usuario: 'Artur', mensagem: 'Teste de mensagem', data: new Date() },
    { id: 2000087, idUsuario: 2, usuario: 'Matheus', mensagem: 'Teste de mensagem', data: new Date() },
];
