import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Breadcrumbs, Tab, Tabs, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import { Suspense, useEffect, useState } from 'react';
import { Await, Outlet, Link as RouterLink, useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Container } from './ElementoGame.adm.styles';

const tabs = [
    { label: 'Alunos', path: 'alunos' },
    { label: 'Questões', path: 'questoes' },
    { label: 'Configuracões', path: 'configuracoes' },
];

function ElementoGame() {
    const aulaLoaded = useLoaderData();
    const navegate = useNavigate();
    const location = useLocation();
    const param = useParams();
    useEffect(() => {
        console.log(aulaLoaded);
    }, []);

    const [valueTab, setValueTab] = useState(() => {
        const i = tabs.findIndex((i) => location.pathname.includes(`/${i.path}`));
        return i == -1 ? 0 : i;
    });

    return (
        <Suspense
            fallback={
                <Container>
                    <h1>Carregando dados da aula...</h1>
                </Container>
            }
        >
            <Await resolve={aulaLoaded}>
                {(aula) => (
                    <Container>
                        <Box>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                <Link
                                    to="/app/cursos"
                                    key="1"
                                    color="text.primary"
                                    variant="h6"
                                    underline="hover"
                                    component={RouterLink}
                                    title="Todos os cursos"
                                >
                                    Cursos
                                </Link>
                                <Link
                                    to={`/app/cursos/${aula.jornada.curso.id}/jornadas`}
                                    key="2"
                                    color="text.primary"
                                    variant="h6"
                                    underline="hover"
                                    component={RouterLink}
                                    title="Nome do curso"
                                >
                                    {aula.jornada.curso.nome}
                                </Link>
                                <Link
                                    to={`/app/cursos/${aula.jornada.curso.id}/jornadas/${aula.jornada.id}/Elementos`}
                                    key="3"
                                    color="text.primary"
                                    variant="h6"
                                    underline="hover"
                                    component={RouterLink}
                                    title="Nome da jornada"
                                >
                                    {aula.jornada.nome}
                                </Link>
                                <Typography key="2" color="primary" variant="h6" title="Nome da jornada">
                                    {aula.titulo}
                                </Typography>
                            </Breadcrumbs>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <Tabs
                                value={valueTab}
                                onChange={(event, newValue) => setValueTab(newValue)}
                                aria-label="nav tabs example"
                                variant="fullWidth"
                            >
                                {tabs.map((i) => (
                                    <Tab key={i.path} label={i.label} onClick={() => navegate(i.path)} />
                                ))}
                            </Tabs>
                        </Box>
                        <Box display={'flex'} flex={'1'} flexDirection={'column'} overflow={'auto'}>
                            <Outlet context={{ aula }} />
                        </Box>
                    </Container>
                )}
            </Await>
        </Suspense>
    );
}

export default ElementoGame;
