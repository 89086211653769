import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import api from '../../../service/api';
import { AuthProviderFunc } from '../../../service/auth';
import gameInitStore from '../../../store/gameInitStore';
import constantes from '../../../utils/constantes';

export interface AuthContextType {
    user: any;
    signin: (user: string, callback: VoidFunction) => void;
    signout: (callback: VoidFunction) => void;
}

export const AuthContext = React.createContext<AuthContextType>(null!);

export function useAuth() {
    return React.useContext(AuthContext);
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
    const userLocalStorage = sessionStorage.getItem(constantes.storageAluno);
    const initUser = userLocalStorage ? JSON.parse(userLocalStorage) : null;

    let [user, setUser] = React.useState<any>(initUser);
    const [openPopupSenha,setOpenPopupSenha] = React.useState<boolean>(false);

    useEffect(() => {
        gameInitStore.setAluno(user);
    }, [user]);

    const socket = React.useMemo<any>(() => {
        if (user) {
            const urlSocket = window.location.origin.includes('localhost')
                ? `http://localhost:3003/room`
                : `${constantes.urlSocket}/room`;
            return io(urlSocket, { query: { name: user.nome, gammyxId: user.id } });
        }
        return null;
    }, [user]);

    React.useEffect(() => {
        if (!socket) return;

        socket.on('connect', () => {
            console.log('Abriu a conexão com socket.');
        });
    }, [socket]);

    let signin = (newUser: string, callback: VoidFunction) => {
        return AuthProviderFunc.signin(() => {
            gameInitStore.setLogout(() => {
                setUser(null);
            });
            setUser(newUser);
            callback();
        });
    };

    let signout = (callback: VoidFunction) => {
        return AuthProviderFunc.signout(async () => {
            const keyRefreshToken = constantes.storageRefreshToken;
            const keyToken = constantes.storageToken;
            const refreshTokenJson = localStorage.getItem(keyRefreshToken);

            if (refreshTokenJson) {
                try {
                    const refreshToken = JSON.parse(refreshTokenJson);
                    api.post(`Login/aluno/logout?sessaoId=${refreshToken.id}`).then(() => {
                        console.log('Logout efetuado com sucesso!');
                    });
                } catch (error) {
                    console.error(error);
                }
            }
            localStorage.removeItem(keyRefreshToken);
            localStorage.removeItem(keyToken);

            setUser(null);
            if(callback)
                callback();
        });
    };

    let value = { user, signin, signout, socket, openPopupSenha, setOpenPopupSenha };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}


