import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100vw - 5px);
    position: relative;
    overflow: hidden;
    text-align: initial;

    @media (max-width: 600px) {
        width: 100vw;
    }
`;
export const Cover = styled.div`
    height: 400px;
    background-image: url(${(p) => p.imgBackground});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* filter: blur(0px) brightness(0.5); */
`;

export const ContainerInfosInit = styled.div`
    padding: 0px 15px;
    width: 100%;
    background: rgb(255, 255, 255);
    background: linear-gradient(
        rgba(0, 0, 0, 0) 49.02%,
        rgba(0, 0, 0, 0.008) 52.42%,
        rgba(0, 0, 0, 0.035) 55.82%,
        rgba(0, 0, 0, 0.082) 59.22%,
        rgba(0, 0, 0, 0.15) 62.62%,
        rgba(0, 0, 0, 0.23) 66.02%,
        rgba(0, 0, 0, 0.333) 69.41%,
        rgba(0, 0, 0, 0.443) 72.81%,
        rgba(0, 0, 0, 0.557) 76.21%,
        rgba(0, 0, 0, 0.667) 79.61%,
        rgba(0, 0, 0, 0.77) 83.01%,
        rgba(0, 0, 0, 0.85) 86.41%,
        rgba(0, 0, 0, 0.918) 89.8%,
        rgba(0, 0, 0, 0.965) 93.2%,
        rgba(0, 0, 0, 0.992) 96.6%,
        rgb(0, 0, 0) 100%
    );
    height: 400px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;

    > h1 {
        color: #fff;
        @media (min-width: 600px) {
            width: calc(100% - 380px);
        }
    }
`;

export const ContainerBody = styled.div`
    background: #000;
    display: flex;
    flex-direction: column;
    color: #fff;
    padding: 15px;

    @media (max-width: 600px) {
        width: 100vw;
    }
`;

export const ContainerBodyPrimeiro = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 15px;
    @media (max-width: 600px) {
        flex-direction: column-reverse;
    }
`;

export const ContainerRightBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    @media (max-width: 600px) {
        margin-bottom: 20px;
    }
`;

export const CardInfosJornada = styled.div`
    border-radius: 15px;
    padding: 15px;
    background-color: #181818;
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: absolute;
    top: -120px;
    @media (max-width: 600px) {
        position: initial;
        top: 0px;
        transform: translateY(0px);
    }
    /* transform: translateY(-120px); */
`;

export const CardInfosAutorJornda = styled.div`
    display: flex;
    flex-direction: column;
    width: 300px;
    border-radius: 15px;
    border: #181818 2px solid;
    padding: 15px;
    /* position: absolute;
    top: 120px; */
    transform: translateY(60px);

    @media (max-width: 600px) {
        transform: translateY(0px);
    }

    > span {
        font-size: 14px;
        color: #b7b7b7;
    }

    > div {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 15px;
        > div {
            > span {
                font-size: 18px;
                font-weight: 600;
            }
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
        }

        > img {
            margin-right: 15px;
            width: 60px;
            border-radius: 50%;
        }
    }
`;

export const Progress = styled.div`
    height: 2px;
    flex-grow: 1;
    border-radius: 4px;
    margin: 10px 5px;
    display: flex;
    background-image: -webkit-linear-gradient(
        left,
        rgba(78, 86, 118, 1) 0%,
        rgba(25, 118, 210, 1) 50%,
        rgba(88, 89, 104, 0.5) 50.001%,
        rgba(88, 89, 104, 0.5) 100%
    );
    background-repeat: no-repeat;
    background-size: 200%;
    background-color: #666;
    background-position: 100% 50%;
    animation-timing-function: linear;
    animation-delay: 0.2s;
    animation-duration: 10s;
`;
