import styled from 'styled-components';
import background from '../../../assets/login/backgroundLandPageLogin.jpg';
import controle from '../../../assets/login/controle.png';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: absolute;

    background-image: url(${background});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    flex-direction: column;
    overflow: hidden;
`;

export const DivLogo = styled.div`
    max-height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    flex: 1;

    > img {
        max-width: 300px;
    }

    @media screen and (max-width: 767px) {
        margin-top: 16px;
    }
`;

export const DivImagemControle = styled.div`
    /* position:absolute; */
    /* top:30%; */
    background-image: url(${controle});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    @media screen and (max-width: 767px) {
        background-position: center;
        background-size: 500px;
        top: 10%;
        left: 0;
        display: none;
    }
`;

export const LoginContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    > form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        > input {
            flex: 1;
            padding: 10px 10px;
            border-radius: 4px;
            border: 1px solid #d9d9d9;
        }

        > button {
            margin: 20px 0px;
        }

        > a {
            color: #fff;
            text-decoration: underline;
        }
    }
`;

export const DivPopupAviso = styled.div`
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    display: ${(p) => p.display};
    top: 0;
    z-index: 5;
    position: fixed;
    justify-content: center;
    align-items: center;
    background: ${(p) => p.theme.colors.azul};
`;
export const DivPopupContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #dddddd;
    border-radius: 8px;
    padding: 8px;
`;
