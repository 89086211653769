import Phaser from 'phaser';

import SacoMoedas from './sacoMoedas';
import Diamantes from './diamantes';
import storeJornada from '../../store/StoreJornada';
import storeTooltip from '../../store/StoreTooltip';
import storeToast from '../../store/StoreToast';
import CasoClinico from './casoClinico';
import api from '../../service/api';
import teste from '../../store/teste';
import { TIPOS_AULAS } from '../scenes/ObsidadeScene';
const COLOR_PRIMARY = 0x007bff;
export default class Bandeira extends Phaser.GameObjects.Image {
    constructor(scene, x, y, playerSetPostion, player, name, sacoMoedas, objAula, proximaBandeira, emitter) {
        super(scene, x, y, objAula.urlImagemNoJogo);
        this.X = x;
        this.Y = y;
        this.name = name;
        this.sacoMoedas = sacoMoedas;
        this.diamantes = objAula.premioAula;
        this.player = player;
        this.playerSetPostion = playerSetPostion;
        this.scene.add.existing(this);
        this.interactveAula = this.setInteractive();
        this.scene.physics.world.enableBody(this, 0);
        this.objAula = { ...objAula };
        this.moedasPega = false;
        this.proximaBandeira = proximaBandeira;
        this.meuCadeado = null;
        this.particles = null;
        this.emitter = emitter;
        this.casoClinico = null;
        this.diploma = null;
        this.emitterDiploma = null;
        this.infosAula = null;
        this._init();
    }

    createAnimationSet() {
        this.scene.anims.create({
            key: 'left',
            frames: this.scene.anims.generateFrameNumbers('avatarSprit', {
                frames: [0, 1, 2, 3],
            }),
            frameRate: 8,
            repeat: -1,
        });
    }

    liberaBandeira() {
        this.objAula.desbloqueada = true;
        this.emitter.startFollow(this);
        if (this.meuCadeado) this.meuCadeado.visible = false;
        this.getAulaAluno();
    }

    openPopUp() {
        (async () => {
            await this.getAulaAluno();
            console.log('this.infosAula antes ', this.infosAula);

            if (this.proximaBandeira) {
                this.proximaBandeira.liberaBandeira();
            }

            if (this.casoClinico) {
                this.casoClinico.desbloqueiaCasoClinico();
            }

            storeJornada.abrePopupVideo(this.objAula.id);

            if (this.sacoMoedas.length && !this.infosAula.pegouSacoMoedas) {
                this.sacoMoedas.forEach((sacoMoedas) => {
                    new SacoMoedas(this.scene, sacoMoedas.posicaoX, sacoMoedas.posicaoY, null, this.player, this, sacoMoedas);
                });
            }
            if (this.diamantes.length && !this.infosAula.pegouPremio) {
                this.diamantes.forEach((diamante) => {
                    new Diamantes(this.scene, diamante.posicaoX, diamante.posicaoY, this.player, this, diamante.url, diamante);
                });
            }
            await this.putAbriAula();
            console.log('this.infosAula depois ', this.infosAula);
        })();
    }

    _init() {
        const [data] = this.objAula.aulaAluno;
        this.atualizaInfosAula(data);
        this.setDepth(10);
        this.objAula.desbloqueada = this.infosAula || this.objAula.ordem == 1 ? true : false;

        //testee --------------------------------------
        // if (this.sacoMoedas.length) {
        //     this.sacoMoedas.forEach((sacoMoedas) => {
        //         new SacoMoedas(this.scene, sacoMoedas.posicaoX, sacoMoedas.posicaoY, null, this.player, this, sacoMoedas);
        //     });
        // }
        // if (this.diamantes.length) {
        //     this.diamantes.forEach((diamante) => {
        //         new Diamantes(this.scene, diamante.posicaoX, diamante.posicaoY, this.player, this, diamante.url, diamante);
        //     });
        // }
        //testee --------------------------------------

        if (this.objAula.idQualAulaLibera) {
            const objCasoClinico = this.scene.jornada.aulas.find(
                (item) => item.tipo == TIPOS_AULAS.casoClinico && item.id == this.objAula.idQualAulaLibera,
            );

            //cria caso clinico caso existea na bandeira
            if (objCasoClinico) {
                this.casoClinico = new CasoClinico(
                    this.scene,
                    objCasoClinico.posicaoX,
                    objCasoClinico.posicaoY,
                    objCasoClinico,
                    this.player,
                    this.objAula.desbloqueada,
                );
            }
        }

        if (this.objAula.diploma) {
            let diploma = this.scene.add.image(1700, 450, 'diploma');
            diploma.setDepth(10);
            let particles = this.scene.add.particles('red');
            particles.setDepth(5);
            let emitterDiploma = particles.createEmitter({
                speed: 220,
                scale: { start: 0.07, end: 0 },
                blendMode: 'ADD',
            });

            diploma.visible = false;
            this.diploma = {
                element: diploma,
                turnVisible: () => {
                    diploma.visible = true;
                    emitterDiploma.startFollow(diploma);
                },
            };
        }

        if (this.infosAula && this.infosAula.abriuAula == false) {
            this.emitter?.startFollow(this);
        }

        if (!this.objAula.desbloqueada) {
            //inicia a bandeira bandeiras bloqueadas com cadeado
            this.meuCadeado = this.scene.add.image(this.X - 20, this.Y + 25, 'cadeadoNew');
            this.meuCadeado.scale = 0.4;
            this.meuCadeado.setDepth(10);
        }

        this.interactveAula.on('pointerup', () => {
            if (!this.objAula.desbloqueada) {
                storeToast.togggleToast(true, 'Poxa 😔.', 'Esta aula ainda não foi desbloqueada');
                setTimeout(() => {
                    storeToast.togggleToast(false, '', '');
                }, 2000);

                return; // Finaliza o click caso a aula ainda estaja bloqueada.
            }

            //CLICANDO NO OBJETO MANDO  ELE PARA LÁ NAS COORDANADAS ESPECIFICAS
            //A BIBLIOTECA STAR CALCULA O TRAJETO EM TILES, POR ISSO AS COORDENADAS SÃO DIVIDAS PELO TILESIZE

            if (this.diploma && this.diploma.element && this.diploma.turnVisible) {
                this.diploma.turnVisible();
            }

            const { x: PlayerX, y: PlayerY } = this.player.getPositionInTiles();
            console.log('Posicao player bandeira', this.playerSetPostion.x, this.playerSetPostion.y);
            this.scene.findPathAndMove(
                PlayerX,
                PlayerY,
                this.playerSetPostion.x,
                this.playerSetPostion.y,
                this.openPopUp.bind(this),
            );
        });

        this.interactveAula.on('pointerover', function (pointer, item) {
            const posX = window.event.clientX;
            const posY = window.event.clientY;
            storeTooltip.togggleTooltip(true, posX, posY, this.name, `Aula ${this.objAula.ordem}:`);
            // console.log(this, posX, posY);
        });

        this.interactveAula.on('pointerout', function (pointer, item) {
            storeTooltip.togggleTooltip(false);
        });

        if (window.location.origin.includes('localhost')) {
            this.objAula.desbloqueada = true;
        }
    }

    async getAulaAluno() {
        const { data } = await api.get(`aulaAluno/${this.objAula.id}/criaOuPega`);
        this.atualizaInfosAula(data);
        return data;
    }

    async putAbriAula() {
        const { data } = await api.put(`aulaAluno/${this.objAula.id}/abriuAula`);
        this.atualizaInfosAula(data);
        return data;
    }

    atualizaInfosAula(data) {
        if (data) {
            this.infosAula = {
                abriuAula: data.abriuAula,
                idAula: data.idAula,
                aondeParouAula: data.aondeParouAula,
                pegouPremio: data.pegouPremio,
                pegouSacoMoedas: data.pegouSacoMoedas,
            };
        }
    }
}
