import api from './api';

export const apiUrl = 'curso/adm';

export const getCursos = async () => {
    const { data } = await api.get('curso');
    return data;
};

export const getCurso = async (idCurso) => {
    const { data } = await api.get(`${apiUrl}/${idCurso}`);
    return data;
};

export const createCurso = async (curso) => {
    const { data } = await api.post(apiUrl, curso);
    return data;
};

export const updateCurso = async (idCurso, curso) => {
    const { data } = await api.put(`${apiUrl}/${idCurso}`, curso);
    return data;
};

export const deleteCurso = async (idCurso) => {
    const { data } = await api.delete(`${apiUrl}/${idCurso}`);
    return data;
};
