import { lighten, shade } from 'polished';
import styled from 'styled-components';

const secundColor = '#0d4860';
const backgrondColor = '#21abe3';
const textBody = '#093446';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1100;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffffc2;
    top: 0px;

    > .contentPopUp {
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        width: 1000px;
        height: 630px;
        max-height: calc(100vh - 110px);
        max-width: calc(100vw - 50px);
        background-color: ${backgrondColor};
        border-radius: 20px;
        border: 4px solid ${secundColor};

        @media screen and (max-width: 600px) {
            width: 100%;
            height: 100%;
            max-width: 100vw;
            max-height: 100vh;
            border-radius: unset;
            border: 0px;
        }
    }

    > .contentPopUp > .TitlePopUp {
        padding: 5px;
        font-size: 20px;
        background-color: ${secundColor};
        display: flex;
        justify-content: center;
        width: 100%;

        @media (max-width: 600px) {
            /* width: calc(100% - 45px); */
            justify-content: start;
            text-align: start;
            font-size: 16px;
        }
    }

    > .contentPopUp > .close {
        position: absolute;
        top: 6px;
        right: 6px;
        /* width: 20px; */
        height: 30px;
        cursor: pointer;
        z-index: 10;
    }
    > .contentPopUp > .close > img {
        height: 100%;
    }

    > .contentPopUp > .TitlePopUp > span {
        line-height: 35px;
        color: #fff;
        font-weight: 600;
        font-size: 22px;
    }

    > .contentPopUp > .conteudoPopUp {
        /* padding: 10px; */
        padding-top: 0px;
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: overlay;
        width: calc(100% + 4px);

        @media (max-width: 600px) {
            padding: 0px;
        }

        .TextAreaEnviaPergunta {
            width: 100%;
            border-radius: 5px;
            padding: 10px;
            background-color: #2e2e2e;
            color: #fff;
            font-size: 16px;
            border: 0px;
            ::placeholder {
                /* color: #fff; */
                font-size: 18px;
            }
        }
    }
    > .contentPopUp > .conteudoPopUp > .containerVideoChatAuditorio {
        flex: 1;
        display: flex;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        overflow: hidden;

        @media (max-width: 600px) {
            flex-direction: column;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }
    }
    > .contentPopUp > .conteudoPopUp > div > .containerVideoAuditorio {
        flex: 1;
        position: relative;

        @media (max-width: 600px) {
        }
    }

    > .contentPopUp > .conteudoPopUp > div > .containerChatAuditorio {
        width: 300px;
        background-color: #18181b;
        color: #fff;
        font-weight: 600;
        padding: 10;
        display: flex;
        flex-direction: column;

        @media (max-width: 600px) {
            flex: 1;
            position: relative;
            width: 100%;
        }

        .fecharChatAuditorio {
            cursor: pointer;
            padding: 5px;
            border-radius: 5px;
            background-color: rgb(53 53 60);
            display: flex;
            justify-content: center;
            border-bottom: 1px solid #494952;

            @media (max-width: 600px) {
                display: none;
            }
        }
    }

    /* width */
    > .contentPopUp > .conteudoPopUp ::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    > .contentPopUp > .conteudoPopUp ::-webkit-scrollbar-track {
        background: ${lighten(0.1, backgrondColor)};
        border-radius: 5px;
    }

    /* Handle */
    > .contentPopUp > .conteudoPopUp ::-webkit-scrollbar-thumb {
        background: ${secundColor};
        border-radius: 5px;
    }

    /* Handle on hover */
    > .contentPopUp > .conteudoPopUp ::-webkit-scrollbar-thumb:hover {
        background: ${shade(0.5, secundColor)};
    }

    > .contentPopUp > .conteudoPopUp > .video {
        position: relative;
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #000;
    }

    > .contentPopUp > .conteudoPopUp > div > div > h2 {
        color: ${textBody};
        font-size: 20px;
        font-weight: 600;
    }
    > .contentPopUp > .conteudoPopUp > h3 {
        color: #fff;
        text-align: center;
        font-size: 22px;
        position: absolute;
        left: 20px;
    }

    > .contentPopUp > .conteudoPopUp > h4 {
        margin: 0px;
        font-size: 20px;
        color: #fff;
        text-align: center;
        font-weight: 400;
    }

    > .contentPopUp > .conteudoPopUp > span {
        color: #fff;
        text-align: center;
        margin-top: 10px;
    }
    > .contentPopUp > .conteudoPopUp > div > .text {
        color: #fff;
        text-align: center;
        margin: 5px;
        font-weight: 600;
    }
`;
export const ButtonPopup = styled.button`
    padding: 10px 20px;
    background: #fd2b2b;
    border: none;
    border-radius: 8px;
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 1px;
    cursor: pointer;
`;
