import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { getCursos } from '../../../admServices/cursos.service';
import { Container, ItemTabela, Tabela } from './cursos.adm.styles';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

function CursosAdm() {
    const navegate = useNavigate();
    const theme = useTheme();
    console.log(theme);
    const [cursos, setCursos] = React.useState([]);
    React.useEffect(() => {
        init();
    }, []);

    async function init() {
        try {
            const cursos = await getCursos();
            setCursos(cursos);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Container>
            <Box display={'flex'} justifyContent={'space-between'} padding={'10px 15px'}>
                <Typography color={theme.palette.text.primary} variant="h4">
                    Cursos
                </Typography>
                <Button variant="contained">Novo Curso</Button>
            </Box>
            <Tabela>
                {cursos.map((curso) => (
                    <ItemTabela
                        key={curso.id}
                        style={{ backgroundColor: theme.palette.background.default }}
                        title="Abrir Curso"
                        onClick={() => navegate(`${curso.id}/dashboard`)}
                    >
                        <img className="imagemTabela" src={curso.urlImagem} />

                        <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} flex={1}>
                            <Box>
                                <Typography color={theme.palette.text.primary} variant="h6" textAlign={'start'}>
                                    {curso.nome}
                                </Typography>
                            </Box>
                            <Box display={'flex'} justifyContent={'space-between'}>
                                <div>
                                    <Typography color={theme.palette.text.primary} variant="body2" textAlign={'start'}>
                                        Alias
                                    </Typography>
                                    <Typography color={theme.palette.text.secondary} variant="body1" fontWeight={600}>
                                        {curso.alias}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography color={theme.palette.text.primary} variant="body2" textAlign={'start'}>
                                        Valor
                                    </Typography>
                                    <Typography color={theme.palette.text.secondary} variant="body1" fontWeight={600}>
                                        {curso.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                    </Typography>
                                </div>
                            </Box>
                        </Box>
                    </ItemTabela>
                ))}
            </Tabela>
        </Container>
    );
}

export default CursosAdm;
