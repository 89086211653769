import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import ListAlunos from '../components/listAlunos/ListAlunos.adm';

function CursoAlunos() {
    const { curso, setCurso } = useOutletContext();

    const alunos = useMemo(() => {
        if (!curso.jornadas) {
            return [];
        }
        const alunosJornadas = curso.jornadas.reduce((acc, cur) => {
            return [...acc, ...cur.jornadasAlunos];
        }, []);
        return [...new Set(alunosJornadas.map((i) => i.aluno))];
    }, [curso]);
    return (
        <Box display={'flex'} flexDirection={'column'} padding={'10px'}>
            <Box display={'flex'} justifyContent={'space-between'} padding={'10px 15px'}>
                <Typography variant="h5" textAlign={'center'}>
                    Alunos do Curso
                </Typography>
                <Button color="success" variant="contained" startIcon={<AddIcon />}>
                    Adicionar Aluno
                </Button>
            </Box>
            <ListAlunos alunos={alunos} />
        </Box>
    );
}

export default CursoAlunos;
