import React from 'react';
import gifNotfound from '../../assets/notFound.json';
import { Player } from '@lottiefiles/react-lottie-player';
// import { Container } from './styles';

function NotFound() {
    return (
        <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Player autoplay loop src={gifNotfound} style={{ width: '50vw', height: '50vh' }} />
        </div>
    );
}

export default NotFound;
