import styled from 'styled-components';

export const Tabela = styled.div`
    display: flex;
    flex: 1;
    margin-bottom: 10px;
    padding: 0px 10px;
    gap: 15px;
    flex-direction: column;
    overflow-y: auto;
`;

export const ItemTabela = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* border: 1px solid #1e1e1e; */
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    transition-duration: 0.5s;
    gap: 10px;

    .textoDescricao {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    :hover {
        transform: scale(0.98);
    }
`;
