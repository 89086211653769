import React, { useState } from 'react';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { useAuth } from '../../pages/auth/authProvider/AuthProvider';
import api from '../../service/api';
import { toast } from 'react-toastify';
import ToastGame from '../toastGame/ToastGame';
// import { Container } from './styles';

function PopupAtualizaSenha() {
    const auth = useAuth();
    const [senhaNova, setSenhaNova] = useState('');
    const [senhaNovaRepete, setSenhaNovaRepete] = useState('');

    async function atualizarSenha() {
        try {
            if (senhaNova != senhaNovaRepete) {
                return;
            }
            const retorno = await api.put('aluno/me/atualizaSenha', { senhaNova });
            if (retorno?.response?.data?.errors?.senhaNova) {
                return toast.success(
                    <ToastGame title={'Erro ao trocar senha'} text={retorno?.response?.data?.errors?.senhaNova[0]} />,
                    {
                        icon: false,
                    },
                );
            }
            toast.success(<ToastGame title={'Senha atualizada'} text={'Senha atualizaa com sucesso!'} />);
            auth.setOpenPopupSenha(false);
        } catch (error) {
            toast.error(
                <ToastGame
                    title={'Erro ao trocar senha'}
                    text={'A senha deve conter pelo menos 8 caracteres, com ao menos 1 letra Maiúscula e 1 numero.'}
                />,
                {
                    icon: false,
                },
            );
            console.error(error);
        }
    }

    return (
        <>
            <DialogTitle>Atualizar Senha</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    A senha deve conter pelo menos 8 caracteres, com ao menos 1 letra Maiúscula e 1 numero.
                </DialogContentText>

                <TextField
                    value={senhaNova}
                    onChange={(e) => setSenhaNova(e.target.value)}
                    autoFocus
                    margin="dense"
                    id="senha"
                    label="Nova senha*"
                    type="password"
                    fullWidth
                    variant="standard"
                />
                <sub style={{ color: 'red' }}>{!senhaNova && 'Campo obrigatório'}</sub>
                <TextField
                    value={senhaNovaRepete}
                    onChange={(e) => setSenhaNovaRepete(e.target.value)}
                    margin="dense"
                    id="senhaRepete"
                    label="Repita a nova senha*"
                    type="password"
                    fullWidth
                    variant="standard"
                />
                <sub style={{ color: 'red' }}>{senhaNova && !senhaNovaRepete && 'Campo obrigatório'}</sub>
                <sub style={{ color: 'red' }}>
                    {senhaNova && senhaNovaRepete && senhaNova != senhaNovaRepete && 'Senhas não coincidem.'}
                </sub>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => auth.setOpenPopupSenha(false)}>Cancelar</Button>
                <Button disabled={!senhaNova || !senhaNovaRepete || senhaNova != senhaNovaRepete} onClick={atualizarSenha}>
                    Atualizar Senha
                </Button>
            </DialogActions>
        </>
    );
}

export default PopupAtualizaSenha;
