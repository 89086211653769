export default {
    title: 'dark',

    colors: {
        primary: '#1463a6',
        secondary: '#ea5f5f',

        background: '#181818',
        text: '#fff',
        textSecundary: '#cecece',
        backgroundPopup:'#000000c7',
        verde:'#53af32',
        vermelho:'#d94a4b',
        azul:'#21abe3',
    }
}