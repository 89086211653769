import { useEffect, useState } from 'react';
import ReactHlsPlayer from 'react-hls-player';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ImgClose from '../../assets/close.png';
import logo from '../../assets/login/logo.png';
import { Button } from '../../components/commonComponents/commonComponents';
import api from '../../service/api';
import constantes from '../../utils/constantes';
import {
    Container,
    ContainerVideos,
    ContainerVideosInfo,
    DivLogo,
    ImagemFecharPopup,
    PopupExperience,
    TituloPopupExperience,
} from './styledVideos';
export default function Videos() {
    const VideosHardCode = [
        {
            id: '729701ef-84a0-44fd-891e-fdb36288670f',
            titulo: 'Abertura do MEDX EXPERIENCE 2022',
            descricao: 'Abertura do EXPERIENCE 2022 com CEO da MEDX TECNOLOGIA S/A, Dr Carlos Lopes.',
            urlConteudo: 'https://b-vz-d2250563-9e5.tv.pandavideo.com.br/b9734203-50c3-4655-a280-01976bc62f1b/playlist.m3u8',
            ordem: 1,
            autor: 'MEDX Tecnologia',
        },
        {
            id: '37ba0bf6-327b-47f6-b23c-f8ab381c2921',
            titulo: 'Update no tratamento de obesidade e perspectivas para o futuro',
            descricao: '"Update no tratamento de obesidade e perspectivas para o futuro" com Dr Gabriel Almeida',
            urlConteudo: 'https://b-vz-d2250563-9e5.tv.pandavideo.com.br/75bdd47e-7988-4516-8a43-20adfda59ace/playlist.m3u8',
            ordem: 2,
            autor: 'MEDX Tecnologia',
        },
        {
            id: '4b006285-792d-4551-8a17-f2aa0ef233cc',
            titulo: 'Porque a medicina pode estar te matando e você nem sabe disso',
            descricao:
                '"Porque a medicina pode estar te matando e você nem sabe disso" com Dr Carlos Lopes e Artur Garcia, CTO da MEDX TECNOLOGIA.',
            urlConteudo: 'https://b-vz-d2250563-9e5.tv.pandavideo.com.br/dc52d389-9ba5-41e2-84b7-2498c754cc26/playlist.m3u8',
            ordem: 3,
            autor: 'MEDX Tecnologia',
        },
        {
            id: '5fb51335-fd44-4c58-b15e-ee016d6fb0f1',
            titulo: 'Terapia de reposição de testosterona e testosterona e câncer de próstata',
            descricao:
                'Workshop de Terapia de Reposição de Testosterona com professor Abraham Morgentaler da Universidade de Harvard.',
            urlConteudo: '',
            ordem: 4,
            autor: 'MEDX Tecnologia',
        },
        {
            id: 'd3a7fa5c-c802-401b-a068-e6645ac630f6',
            titulo: 'Bases científicas para cura pela fé',
            descricao: '"Bases científicas para cura pela fé" com Dr Fabrício Assini.',
            urlConteudo: 'https://b-vz-d2250563-9e5.tv.pandavideo.com.br/9f24023f-14fe-449f-9ede-e817fc58df61/playlist.m3u8',
            ordem: 5,
            autor: 'MEDX Tecnologia',
        },
        {
            id: '6e4a36d9-0a7a-44b5-849c-d2727334101d',
            titulo: 'Cannabis medicinal e síndromes demenciais',
            descricao: '"Cannabis medicinal e síndromes demenciais" com Dr Gustavo Medeiros.',
            urlConteudo: 'https://b-vz-d2250563-9e5.tv.pandavideo.com.br/79f4df14-5019-4005-922a-74c9258ff70f/playlist.m3u8',
            ordem: 6,
            autor: 'MEDX Tecnologia',
        },
        {
            id: '43f4cd5f-13c7-470e-b864-c38bcf405403',
            titulo: 'Reset Break: Gustavo Ariel Stand Up Comedy',
            descricao: null,
            urlConteudo: 'https://b-vz-d2250563-9e5.tv.pandavideo.com.br/a6e7046f-3e09-4772-80f1-14aab20ad6d9/playlist.m3u8',
            ordem: 7,
            autor: 'MEDX Tecnologia',
        },
        {
            id: 'ee0adfa2-82d7-470e-8a6e-b8adbd262ac4',
            titulo: 'Treino excessivo e disfunção metabólica em atletas',
            descricao: '"Treino excessivo e disfunção metabólica em atletas" com Dr Carlos Werutsky.',
            urlConteudo: 'https://b-vz-d2250563-9e5.tv.pandavideo.com.br/cbba5de6-39b3-4cca-9725-2ee36f98ac7c/playlist.m3u8',
            ordem: 8,
            autor: 'MEDX Tecnologia',
        },
        {
            id: '85f6149c-afa6-4929-9a57-aa902af1906c',
            titulo: 'Reset Break: Dr Zoreba',
            descricao: null,
            urlConteudo: 'https://b-vz-d2250563-9e5.tv.pandavideo.com.br/c0d8b900-e2df-45d4-9651-c3051775c5bd/playlist.m3u8',
            ordem: 9,
            autor: 'MEDX Tecnologia',
        },
        {
            id: '3b451fc5-4a3b-4897-bf9f-a8c72e835aa8',
            titulo: 'Por que seu marketing digital traz engajamento mas não traz pacientes?',
            descricao:
                '"Por que seu marketing digital traz engajamento mas não traz pacientes?" com Renzo Correia, CEO da Pixelfy',
            urlConteudo: 'https://b-vz-d2250563-9e5.tv.pandavideo.com.br/f361bc98-0a1c-436e-a43f-b6ae72b73323/playlist.m3u8',
            ordem: 10,
            autor: 'MEDX Tecnologia',
        },
        {
            id: '9f216968-2b14-4f3c-955e-a4f561dd5a38',
            titulo: 'Highlights na terapia injetável para síndrome metabólica',
            descricao: '"Highlights na terapia injetável para síndrome metabólica" com Dr Guilherme Renke',
            urlConteudo: 'https://b-vz-d2250563-9e5.tv.pandavideo.com.br/a531ca2c-acc1-4af9-b43d-42fe953dfb5e/playlist.m3u8',
            ordem: 11,
            autor: 'MEDX Tecnologia',
        },
        {
            id: 'fbcb5585-e9a2-4b37-97e4-ec6701eb0038',
            titulo: 'Indicações e cuidados na terapia hormonal injetável de testosterona',
            descricao: '"Indicações e cuidados na terapia hormonal injetável de testosterona" com Dr Guilherme Renke',
            urlConteudo: 'https://b-vz-d2250563-9e5.tv.pandavideo.com.br/77b86582-6f3a-4cbc-8b93-16fe2f71ec71/playlist.m3u8',
            ordem: 12,
            autor: 'MEDX Tecnologia',
        },
        {
            id: '400f7c61-d755-4044-be18-a2de9dd6346a',
            titulo: 'Reset Break: Marco Lobo percussionista',
            descricao: null,
            urlConteudo: 'https://b-vz-d2250563-9e5.tv.pandavideo.com.br/4c6c4b84-4eef-4b4c-98d7-88381d09fa8e/playlist.m3u8',
            ordem: 13,
            autor: 'MEDX Tecnologia',
        },
        {
            id: '712ed783-e107-4b2b-8974-949f2a0cf5a4',
            titulo: 'A abordagem terapêutica dos implantes hormonais não absorvíveis e seu impacto positivo na saúde',
            descricao:
                '"A abordagem terapêutica dos implantes hormonais não absorvíveis e seu impacto positivo na saúde" com Dra Fabiane Berta.',
            urlConteudo: 'https://b-vz-d2250563-9e5.tv.pandavideo.com.br/ba5b814f-9572-4629-9681-3a4acd4cb578/playlist.m3u8',
            ordem: 14,
            autor: 'MEDX Tecnologia',
        },
        {
            id: '2ea7f366-39b2-4153-ab55-8a2355084e8c',
            titulo: 'DNA aging clocks e senolíticos',
            descricao: '"DNA aging clocks e senolíticos" com Dr Ellysson Abinader',
            urlConteudo: 'https://b-vz-d2250563-9e5.tv.pandavideo.com.br/7b73d170-880f-4601-bfb8-73056504c87f/playlist.m3u8',
            ordem: 15,
            autor: 'MEDX Tecnologia',
        },
        {
            id: '944b60da-17f5-44e2-baa6-7fea6796562c',
            titulo: 'HRV: aplicações práticas da variabilidade da frequência cardíaca em atletas e não atletas',
            descricao:
                '"HRV: aplicações práticas da variabilidade da frequência cardíaca em atletas e não atletas" com Dra Ana Crepaldi.',
            urlConteudo: 'https://b-vz-d2250563-9e5.tv.pandavideo.com.br/9bf7c240-6661-4f44-88af-57a51c8bbea7/playlist.m3u8',
            ordem: 16,
            autor: 'MEDX Tecnologia',
        },
        {
            id: '4f714357-f66f-46bd-99ba-72f681f95728',
            titulo: 'Reset Break: Gustavo Ariel Stand Up Comedy',
            descricao: null,
            urlConteudo: 'https://b-vz-d2250563-9e5.tv.pandavideo.com.br/cdffea27-0d61-4fb0-92b3-6ccb8dbb0127/playlist.m3u8',
            ordem: 17,
            autor: 'MEDX Tecnologia',
        },
        {
            id: '9ab926c3-fbb2-4852-b14c-4b5a83d66e50',
            titulo: 'Novidades em métodos de composição corporal',
            descricao: '"Novidades em métodos de composição corporal" com Dr Márcio Pinho.',
            urlConteudo: 'https://b-vz-d2250563-9e5.tv.pandavideo.com.br/7484a812-1858-4c69-a770-8a02459038b2/playlist.m3u8',
            ordem: 18,
            autor: 'MEDX Tecnologia',
        },
        {
            id: '42ea66da-d4e1-44bf-99ea-433a05a0e443',
            titulo: 'Reset Break: Frederico Puppy Violoncelo ',
            descricao: null,
            urlConteudo: 'https://b-vz-d2250563-9e5.tv.pandavideo.com.br/7ebacacd-508c-448b-86c4-881263d649ea/playlist.m3u8',
            ordem: 19,
            autor: 'MEDX Tecnologia',
        },
        {
            id: '21b01aa6-1200-4e8e-9ac2-3e6e222f25af',
            titulo: 'Por que seu tratamento de hipotireoidismo não entrega o resultado que seu paciente espera?',
            descricao:
                '"Por que seu tratamento de hipotireoidismo não entrega o resultado que seu paciente espera?" com Dr Ronald Canejo',
            urlConteudo: 'https://b-vz-d2250563-9e5.tv.pandavideo.com.br/51705ab3-b447-49c4-a056-e08a771110e2/playlist.m3u8',
            ordem: 20,
            autor: 'MEDX Tecnologia',
        },
        {
            id: 'b3b177c7-f387-4177-b7c0-3ccca1b2eb87',
            titulo: 'Cannabis medicinal e autismo',
            descricao: '"Cannabis medicinal e autismo" com Dr Ronaldo Bufaiçal.',
            urlConteudo: 'https://b-vz-d2250563-9e5.tv.pandavideo.com.br/80725808-da20-40b4-9116-098b8a891145/playlist.m3u8',
            ordem: 21,
            autor: 'MEDX Tecnologia',
        },
        {
            id: 'c88d6dd3-cfaa-42a5-8910-3b3e2c8481a6',
            titulo: 'Update em tratamento de menopausa',
            descricao: '"Update em tratamento de menopausa" com Dr André Vinícius',
            urlConteudo: 'https://b-vz-d2250563-9e5.tv.pandavideo.com.br/c294565f-216a-4732-a643-b2685e30c0b2/playlist.m3u8',
            ordem: 22,
            autor: 'MEDX Tecnologia',
        },
        {
            id: 'c8e6e133-3103-4433-8346-39e4f6f417af',
            titulo: 'Update em tratamento de síndrome de ovários policísticos',
            descricao: '"Update em tratamento de síndrome de ovários policísticos" com Dr André Vinícius',
            urlConteudo: 'https://b-vz-d2250563-9e5.tv.pandavideo.com.br/71b300c7-f608-4548-b1e7-f887f90f2e1c/playlist.m3u8',
            ordem: 23,
            autor: 'MEDX Tecnologia',
        },
        {
            id: '5566e167-aa21-40d3-b006-33e8b759fc4a',
            titulo: 'Implantes hormonais absorvíveis de última geração',
            descricao: '"Implantes hormonais absorvíveis de última geração" com Dr Wilson Delapaschoa',
            urlConteudo: 'https://b-vz-d2250563-9e5.tv.pandavideo.com.br/135db52d-b52f-460e-a6dd-854fc301d6d0/playlist.m3u8',
            ordem: 24,
            autor: 'MEDX Tecnologia',
        },
        {
            id: '49fee57d-e2d6-48cf-b1ca-31232fe4eb67',
            titulo: 'Reset Break: Gustavo Ariel Stand Up Comedy',
            descricao: null,
            urlConteudo: 'https://b-vz-d2250563-9e5.tv.pandavideo.com.br/0476724b-e991-40b3-982b-3c075c60ad55/playlist.m3u8',
            ordem: 25,
            autor: 'MEDX Tecnologia',
        },
        {
            id: 'b965d471-2d95-424d-b679-24dba66f099f',
            titulo: 'Otimização dos saudáveis e a medicina da imortalidade',
            descricao: '"Otimização dos saudáveis e a medicina da imortalidade" com Dr Ítalo Rachid.',
            urlConteudo: 'https://b-vz-d2250563-9e5.tv.pandavideo.com.br/d8f07142-bbd5-44f1-8972-df0ebb518998/playlist.m3u8',
            ordem: 26,
            autor: 'MEDX Tecnologia',
        },
    ];
    const [openPopup, setOpenPopup] = useState('none');
    const [urlVideo, setUrlVideo] = useState('');
    const [infoJornada, setInfoJornada] = useState({
        nome: '',
        aulas: [],
    });
    const params = useParams();

    function openPopupVideo(urlImg) {
        console.log(urlImg);
        setOpenPopup('flex');
        setUrlVideo(urlImg);
    }
    async function carregaVideosJornada(id) {
        try {
            const responseCarregaVideosJornada = await api.get(`Jornada/${id}/aulas`);
            setInfoJornada(responseCarregaVideosJornada.data);
            debugger;
        } catch (error) {
            toast.error('Erro ao carregar vídeos.');
        }
    }

    useEffect(() => {
        if (params.idJornada) carregaVideosJornada(params.idJornada);
        else toast.error('Erro ao achar jornada.');
    }, []);

    return (
        <Container>
            <DivLogo>
                <img src={constantes.logo} onError={(e) => (e.target.src = logo)} />
            </DivLogo>
            <div style={{ height: '60px' }}>
                <h1>{infoJornada.nome}</h1>
            </div>
            <ContainerVideos>
                {infoJornada.aulas.map((video) => {
                    return (
                        <ContainerVideosInfo key={video.id}>
                            <div>
                                <span>Nome: {video.titulo}</span>
                            </div>
                            <div>
                                <span>Descrição: {video.descricao}</span>
                            </div>
                            <div>
                                <Button onClick={() => openPopupVideo(video.urlConteudo)}>Assistir</Button>
                            </div>
                        </ContainerVideosInfo>
                    );
                })}
            </ContainerVideos>
            <PopupExperience className="popup" id="popUpLivros" display={openPopup}>
                <div className="contentPopUp" style={{ width: 'calc(100vw - 50px)', height: '100vh', position: 'relative' }}>
                    <TituloPopupExperience className="TitlePopUp">
                        <span id="TitlePopUp">MEDX EXPERIENCE</span>
                    </TituloPopupExperience>
                    <ImagemFecharPopup className="close" onClick={() => setOpenPopup('none')}>
                        <img src={ImgClose} onClick={() => setOpenPopup('none')} />
                    </ImagemFecharPopup>
                    <div className="conteudoPopUp">
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: 'calc(100vh - 80px)',
                            }}
                        >
                            {/* <iframe
                      width={'100%'}
                      height={'100%'}
                        src='https://player-vz-d2250563-9e5.tv.pandavideo.com.br/embed/?v=5f72c660-e04f-4970-afba-823ad73502c5'
                      /> */}
                            {openPopup != 'none' && (
                                <ReactHlsPlayer
                                    autoPlay
                                    src={urlVideo}
                                    controls={true}
                                    controlsList="nodownload"
                                    style={{ width: 'calc(100% - 100px)', height: 'calc(100vh - 100px)' }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </PopupExperience>
        </Container>
    );
}
