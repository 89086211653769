import styled from "styled-components";

export const Container = styled.div`
    width:100%;
    height:100vh;
    overflow:hidden;
    background:${p=>p.theme.colors.background};
    color:${p => p.theme.colors.text};
`

export const ContainerVideos = styled.div`
    display:flex;
    flex-direction:column;
    height: calc(100% - 180px);
    overflow:hidden auto;
    padding:0 12px;
    
`
export const ContainerVideosInfo = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    font-size:18px;
    padding:8px;
    border:1px solid #fff;
    border-radius:8px;
    margin-bottom:12px;
    >div{
        margin-bottom:8px;
    }
`
export const PopupExperience = styled.div`
    display: ${p=>p.display};
    align-items:flex-start;
    position:fixed;
    background:#181818;
    top:0 ;
    width:100%;
`
export const TituloPopupExperience = styled.div`
    height: 65px;
    font-size: 32px;
`
export const ImagemFecharPopup = styled.div`
    z-index:1;
    position: absolute;
    right: 0;
    top: 13px;
    transition:.5s;
    cursor:pointer;
    :hover{
        transform:scale(1.08);
    }
    img{
        width:40px;
    }
`
export const DivLogo = styled.div`
    height:70px;
    padding: 24px 0 0 0;
`