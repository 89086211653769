import styled from "styled-components";

export const Container = styled.div`
    /* background:${p=>p.theme.colors.background}; */
    
    display:flex;
    width:100%;
    height:100vh;
    overflow:hidden;
    flex-direction:column;
    color:${p=>p.theme.colors.text};
    align-items:center;
    
`
export const DivLogo = styled.div`
        display:flex;
        flex:0.4;
        align-items:center;
    @media screen and (max-width: 767px){
        >img{
            width:200px;
        }
    }
`
export const Body = styled.form`
    display:flex;
    background:${p=>p.theme.colors.background};
    align-items: center;
    border-radius:8px;
    @media screen and (max-width: 767px) {
        flex-direction: column-reverse;
    };
`
export const BodyBorda = styled.div`
    background: linear-gradient(275deg, rgb(208, 208, 208) 0%, rgb(0, 212, 255) 54%, rgb(33, 171, 227) 100%);
    padding: 2px;
    border-radius: 8px;
    @media screen and (max-width:767px){
        overflow:auto;
        padding-bottom:70px;
        background:${p=>p.theme.colors.background};
    }
`
export const DivInfoAluno = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    margin:0 0 12px 0; 
    text-align:start;
    span{
        width:fit-content;
        min-width:120px;    
    }
    input{
        flex:1;
    }
    @media screen and (max-width: 767px) {
        flex-direction: column;
    }
`
export const Content = styled.div`
    padding: 12px;
    height: 100%;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
`
export const Img = styled.img`
   
`
export const Avatar = styled.div`
    display: flex;
    flex-direction: column;
    margin:0 50px;
    @media screen and (max-width: 767px) {
        margin:0;
    }
`
export const DivContent = styled.div`
    
margin-right:12px;
flex:1;
>input{
    
    width:-webkit-fill-available;
}
>span{
    margin-left:5px;
}
@media screen and (max-width: 767px) {
        margin-left:0;
        width:100%;
    }

`
export const DivImgAvatar = styled.div`
    max-width:350px;
    justify-content:center;
`
export const SubErrorForm = styled.sub`
    color: ${p=>p.theme.colors.vermelho};
    margin-left: 15px;
    font-size: 12px;
    font-weight: 600;
`;