import Button from '@mui/material/Button';
import { useMemo, useState } from 'react';
import imgDefault from '../../assets/compra/sticker.png';
import logo from '../../assets/login/logo.png';
import constantes from '../../utils/constantes';
import {
    Aside,
    Container,
    ContainerProgramacao,
    DescricaoAula,
    DivApresentacao,
    DivBodyCompra,
    DivImgLogo,
    DivInfoValor,
    DivInternaValor,
    DivProgramacao,
    H1TituloJornada,
    ImgApresentacao,
    ImgSticker,
    PApresentacao,
    Section,
    TitulAula,
} from './styledCompra';
export default function Compra({
    tituloJornada,
    imgApresentacao,
    descricaoPrincipal,
    descricaoAdicional,
    descricaoDiamante,
    descricaoMoeda,
    acaoBotao,
    descricaoBotao,
    preco,
    jornadas,
    sticker,
    cupom,
}) {
    const [mostraDescricao, setmostraDescricao] = useState(true);
    const valorComDisconto = useMemo(() => {
        if (!cupom) {
            return preco;
        } else {
            return preco - (preco * parseInt(cupom.percentual)) / 100;
        }
    }, [cupom, preco]);

    return (
        <Container>
            <DivImgLogo>
                <img src={constantes.logo} onError={(e) => (e.target.src = logo)} />
            </DivImgLogo>
            {!imgApresentacao && <H1TituloJornada>{tituloJornada}</H1TituloJornada>}
            <DivBodyCompra id="BodyCompra">
                <Section id="sectionCompra">
                    <DivApresentacao>
                        <ImgApresentacao src={imgApresentacao} />

                        <PApresentacao dangerouslySetInnerHTML={{ __html: descricaoPrincipal }}></PApresentacao>
                        <Button
                            variant="outlined"
                            style={{ marginTop: '12px' }}
                            onClick={() => setmostraDescricao(!mostraDescricao)}
                        >
                            {mostraDescricao ? 'Ver menos' : 'Ver Conteúdo'}
                        </Button>
                    </DivApresentacao>
                    {mostraDescricao && (
                        <ContainerProgramacao>
                            <h3>Conteúdo da Jornada</h3>
                            {jornadas.map((jornada, index) => (
                                <div key={jornada.id}>
                                    <h1 style={{ textAlign: 'center', borderBottom: '2px solid #ffffff61' }}>
                                        {index + 1}ª Jornada - {jornada.nome}
                                    </h1>
                                    <DivProgramacao>
                                        {jornada.aulas?.map((aula) => {
                                            return (
                                                <div key={aula.id}>
                                                    <TitulAula>{aula.titulo}</TitulAula>
                                                    <DescricaoAula style={{ color: '#ffffff85' }}>{aula.descricao}</DescricaoAula>
                                                </div>
                                            );
                                        })}
                                    </DivProgramacao>
                                </div>
                            ))}
                        </ContainerProgramacao>
                    )}
                </Section>
                <Aside>
                    <DivInfoValor>
                        <DivInternaValor>
                            <div style={{ display: 'flex' }}>
                                {descricaoBotao.toLowerCase() == 'jogar' ? '' : <ImgSticker src={sticker || imgDefault} />}
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        flexDirection: 'column',
                                        marginLeft: '24px',
                                    }}
                                >
                                    {descricaoBotao.toLowerCase() == 'jogar' ? (
                                        ''
                                    ) : (
                                        <div style={{ fontSize: '24px' }}>
                                            {/* {preco.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} */}
                                        </div>
                                    )}
                                    {descricaoBotao.toLowerCase() == 'jogar' ? (
                                        ''
                                    ) : (
                                        <div style={{ textAlign: 'start', fontSize: '24px' }}>
                                            {cupom && valorComDisconto < preco ? (
                                                <>
                                                    <label style={{ textDecoration: 'line-through', fontSize: '16px' }}>
                                                        De 12x de{' '}
                                                    </label>
                                                    <strong style={{ textDecoration: 'line-through', fontSize: '16px' }}>
                                                        {(preco / 12).toLocaleString('pt-br', {
                                                            style: 'currency',
                                                            currency: 'BRL',
                                                        })}
                                                    </strong>
                                                    <br />
                                                    <label style={{ color: '#1976d2' }}>Por 12x de </label>
                                                    <strong style={{ color: '#1976d2' }}>
                                                        {(valorComDisconto / 12).toLocaleString('pt-br', {
                                                            style: 'currency',
                                                            currency: 'BRL',
                                                        })}
                                                    </strong>{' '}
                                                    <label> no cartão sem juros</label>
                                                </>
                                            ) : (
                                                <>
                                                    12x de{' '}
                                                    <strong>
                                                        {(preco / 12).toLocaleString('pt-br', {
                                                            style: 'currency',
                                                            currency: 'BRL',
                                                        })}
                                                    </strong>{' '}
                                                    no cartão sem juros
                                                </>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <Button variant="contained" color="success" onClick={acaoBotao}>
                                {descricaoBotao}
                            </Button>
                        </DivInternaValor>
                    </DivInfoValor>
                    {/* {descricaoDiamante && descricaoMoeda && (
                        <DivInfoValor
                            style={{
                                background:
                                    'linear-gradient(275deg, rgba(208,208,208,1) 0%, rgba(0,212,255,1) 54%, rgba(33,171,227,1) 100%)',
                            }}
                        >
                            <DivInternaValor>
                                <div>
                                    <div style={{ display: 'flex' }}>
                                        <img style={{ width: '35px', marginRight: '12px' }} src={diamante} />
                                        <span style={{ fontSize: '28px' }}>DIAMANTES</span>
                                    </div>
                                    <p style={{ textAlign: 'start' }}>{descricaoDiamante}</p>
                                </div>
                                <div>
                                    <div style={{ display: 'flex' }}>
                                        <img style={{ width: '35px', marginRight: '12px' }} src={coin} />
                                        <span style={{ fontSize: '28px' }}>GAMMYX COINS</span>
                                    </div>
                                    <p style={{ textAlign: 'start' }}>{descricaoMoeda}</p>
                                </div>
                            </DivInternaValor>
                        </DivInfoValor>
                    )} */}
                </Aside>
            </DivBodyCompra>
        </Container>
    );
}
