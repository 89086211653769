import Button from '@mui/material/Button';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import GamepadIcon from '@mui/icons-material/Gamepad';
import ListIcon from '@mui/icons-material/List';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LoadingLottie from '../../../components/loadingLottie/loadingLottie';
import api from '../../../service/api';
import gameInitStore from '../../../store/gameInitStore';
import {
    CardInfosAutorJornda,
    CardInfosJornada,
    Container,
    ContainerBody,
    ContainerBodyPrimeiro,
    ContainerInfosInit,
    ContainerRightBody,
    Cover,
    Progress,
} from './stylesMinhaJornanda';

function MinhaJornanda() {
    const params = useParams();
    const jornadaId = params?.idJornada;
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [autor, setAutor] = useState({});

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const aluno = useMemo(() => {
        const dadosAluno = toJS(gameInitStore.aluno);
        console.log('🚀 ~ file: MinhaJornada.js:14 ~ aluno ~ dadosAluno', dadosAluno);
        return dadosAluno;
    }, [gameInitStore.aluno]);

    const infosJornada = useMemo(() => {
        const jornada = toJS(gameInitStore.jornadaCompleta);

        if (jornada) {
            console.log('🚀 ~ file: MinhaJornada.js:41 ~ infosJornada ~ jornada', jornada.jornada);

            return jornada.jornada;
        } else {
            return null;
        }
    }, [gameInitStore.jornadaCompleta]);

    const aulasJoranda = useMemo(() => {
        if (infosJornada?.aulas) {
            const aulasDaJornada = infosJornada.aulas;
            console.log('🚀 ~ file: MinhaJornada.js:30 ~ aulasJoranda ~ aulasDaJornada', aulasDaJornada);
            return aulasDaJornada;
        }

        return [];
    }, [infosJornada]);

    const duracaoJornada = useMemo(() => {
        return aulasJoranda.reduce((acc, cur, i, array) => acc + cur.duracaoVideo, 1);
    }, [infosJornada]);

    const AulasAssistidas = useMemo(() => {
        const aulasLiberadas = aulasJoranda.filter((aula) => aula.aulaAluno.length);
        return aulasLiberadas.filter((aula) => aula.aulaAluno[0].abriuAula);
    }, [aulasJoranda]);

    const objConfigJornada = useMemo(() => {
        if (!infosJornada) return null;

        if (infosJornada.jsonConfigurationMap) {
            try {
                const configJornada = JSON.parse(infosJornada.jsonConfigurationMap);
                console.log('🚀 ~ file: MinhaJornada.js:39 ~ objConfigJornada ~ configJornada', configJornada);
                return configJornada;
            } catch (error) {
                console.error('Erro ao tentar fazer parse da config da jornada.');
                console.error(error);
                return {};
            }
        }
        return {};
    }, [infosJornada]);

    async function init(params) {
        const jornadaId = params?.idJornada;
        if (!jornadaId) return console.log(jornadaId);
        await gameInitStore.getJornadaCompleta(jornadaId);
        const autor = await carregaAutorPrincipalDaJornada(jornadaId);
        setAutor(autor);
        console.log('🚀 ~ file: MinhaJornada.js:94 ~ init ~ autores', autor);
    }

    async function carregaAutorPrincipalDaJornada(idJornada) {
        try {
            const { data: autor } = await api.get(`autor/principal/jornada/${idJornada}`);
            return autor;
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        init(params);
    }, [params]);

    if (!infosJornada) return <LoadingLottie />;

    return (
        <Container>
            <Cover imgBackground={infosJornada.urlImagemApresentacao} />
            <ContainerInfosInit>
                <h1>Minha Jornada</h1>
                <div>
                    <Button
                        onClick={handleClick}
                        color="success"
                        sx={{ marginRight: '15px', background: '#1a851f', color: '#fefefe' }}
                        variant="contained"
                        startIcon={<PlayCircleFilledWhiteIcon />}
                    >
                        Iniciar Minha Jornada
                    </Button>

                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                handleClose();
                                navigate(`/app/game/${jornadaId}`);
                            }}
                        >
                            <ListItemIcon>
                                <GamepadIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Jornada Game</ListItemText>
                        </MenuItem>
                        <Divider />
                        <MenuItem
                            onClick={() => {
                                handleClose();
                                navigate(`/app/gameui/${jornadaId}`);
                            }}
                        >
                            <ListItemIcon>
                                <ListIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Jornada Classica</ListItemText>
                        </MenuItem>
                    </Menu>
                </div>
            </ContainerInfosInit>
            <ContainerBody>
                <ContainerBodyPrimeiro>
                    <div style={{ flex: 1, paddingRight: '15px' }}>
                        <span
                            style={{
                                display: 'block',
                                textAlign: 'left',
                                whiteSpace: 'pre-line',
                                fontWeight: 600,
                                color: '#fefefe',
                            }}
                        >
                            {infosJornada.descricao}
                        </span>
                    </div>
                    <ContainerRightBody>
                        <CardInfosJornada>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                <span>Progresso</span>
                                <div style={{ display: 'flex', flex: 1, width: '100%' }}>
                                    <Progress
                                        style={{
                                            backgroundPosition: `${
                                                100 - (AulasAssistidas.length * 100) / aulasJoranda.length
                                            }% 0`,
                                        }}
                                    />
                                    <span style={{ fontWeight: '600', fontSize: '14px' }}>
                                        {((AulasAssistidas.length * 100) / aulasJoranda.length).toFixed(0)}%
                                    </span>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span>Duração</span>
                                    <div style={{ display: 'flex', flex: 1, width: '100%' }}>
                                        <span style={{ fontWeight: '600', fontSize: '14px' }}>
                                            {`${(duracaoJornada / 60).toFixed(0)} Min`}
                                        </span>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span>Alunos(as)</span>
                                    <div style={{ display: 'flex', flex: 1, width: '100%' }}>
                                        <span style={{ fontWeight: '600', fontSize: '14px' }}>50</span>
                                    </div>
                                </div>
                            </div>
                        </CardInfosJornada>
                        {autor?.id && (
                            <CardInfosAutorJornda>
                                <div>
                                    <img src={autor.foto} />
                                    <div>
                                        <span>{autor.nome}</span>
                                        <Button startIcon={<AlternateEmailIcon />} href={`mailto:${autor.email}`}>
                                            Enviar Email
                                        </Button>
                                    </div>
                                </div>
                                <span>{autor.descricaoAcademica}</span>
                            </CardInfosAutorJornda>
                        )}
                    </ContainerRightBody>
                </ContainerBodyPrimeiro>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        padding: '15px',
                        flex: 1,
                        gap: '20px',
                    }}
                >
                    <h1>Aulas da Jornada</h1>
                    {aulasJoranda
                        .sort((a, b) => a.ordem - b.ordem)
                        .map((aula) => (
                            <div key={aula.id} style={{ display: 'flex' }}>
                                <div style={{ width: '300px', maxWidth: '25%', marginRight: '20px' }}>
                                    <img
                                        style={{ width: '100%', borderRadius: '10px', filter: 'brightness(0.7)' }}
                                        src={infosJornada.urlImagemApresentacao}
                                    />
                                </div>
                                <div
                                    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flex: 1 }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontWeight: 600, fontSize: '20px' }}>{aula.titulo}</span>
                                        <span style={{ color: '#b7b7b7', marginLeft: '5px', fontSize: '14px' }}>
                                            {aula.descricao}
                                        </span>
                                    </div>
                                    <span
                                        style={{
                                            fontSize: '12px',
                                            fontWeight: 600,
                                            color: '#b7b7b7',
                                        }}
                                    >{`${(aula.duracaoVideo / 60).toFixed(0)} Min`}</span>
                                </div>
                            </div>
                        ))}
                </div>
            </ContainerBody>
        </Container>
    );
}

export default observer(MinhaJornanda);
