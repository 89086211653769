import { Box, Typography, useTheme } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import styled from 'styled-components';

function JornadaDuvidas() {
    const { jornada } = useOutletContext();
    const theme = useTheme();
    return (
        <Box display={'flex'} flexDirection={'column'} padding={'10px'}>
            <Box display={'flex'} justifyContent={'space-between'} padding={'10px 15px'}>
                <Typography variant="h5" textAlign={'center'}>
                    Duvidas da jornada
                </Typography>
            </Box>
            <Tabela style={{}}>
                {jornada.duvidas.map((duvida) => (
                    <ItemTabela
                        key={duvida.id}
                        style={{
                            backgroundColor: theme.palette.background.default,
                            boxShadow: duvida.urlVideoResposta ? '' : `0px 0px 6px 0px ${theme.palette.primary.main}`,
                        }}
                        title={`Ver duvida da jornada`}
                    >
                        <Typography color={theme.palette.primary.dark} variant="h6" textAlign={'start'}>
                            {duvida.aluno.nome}
                        </Typography>

                        <Typography fontWeight={600}>Pergunta:</Typography>
                        <Box padding={'0px 15px'}>
                            <Typography textAlign={'start'} color={'text.secondary'}>
                                {duvida.duvida}
                            </Typography>
                        </Box>
                        <Typography fontWeight={600}>Resposta:</Typography>
                        <Box padding={'0px 15px'}>
                            <Typography color={duvida.urlVideoResposta ? 'text.secondary' : 'primary.main'}>
                                {duvida.urlVideoResposta || 'Sem resposta, clique para responder.'}
                            </Typography>
                        </Box>
                    </ItemTabela>
                ))}
            </Tabela>
        </Box>
    );
}

export default JornadaDuvidas;

const Tabela = styled.div`
    display: flex;
    flex: 1;
    margin-bottom: 10px;
    padding: 10px 10px;
    gap: 15px;
    flex-direction: column;
    overflow-y: auto;
`;

const ItemTabela = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* border: 1px solid #1e1e1e; */
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    transition-duration: 0.5s;
    gap: 10px;

    .textoDescricao {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    :hover {
        transform: scale(0.98);
    }
`;
