import { createRoot } from 'react-dom/client';
import './index.css'
import Routers from './App'
import { ToastContainer } from 'react-toastify';

const container = document.getElementById('root');
const root = createRoot(container!); 

root.render(
    <>
        <Routers />
        <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            className="toast-colored"
        />
    </>
);