import api from './api';

export const apiUrl = 'jornada/adm';

export const getJornadas = async () => {
    const { data } = await api.get(apiUrl.replace('/adm', ''));
    return data;
};

export const getJornada = async (idJornada) => {
    const { data } = await api.get(`${apiUrl}/${idJornada}`);
    return data;
};

// export const createCurso = async (curso) => {
//     const { data } = await api.post(apiUrl, curso);
//     return data;
// };

// export const updateCurso = async (idCurso, curso) => {
//     const { data } = await api.put(`${apiUrl}/${idCurso}`, curso);
//     return data;
// };

// export const deleteCurso = async (idCurso) => {
//     const { data } = await api.delete(`${apiUrl}/${idCurso}`);
//     return data;
// };
