import { Box, Button, InputAdornment, TextField, Typography } from '@mui/material';
import { useOutletContext } from 'react-router-dom';

// import { Container } from './styles';

function CursoConfig() {
    const { curso, setCurso } = useOutletContext();
    return (
        <Box display={'flex'} flexDirection={'column'} padding={'20px 10px'} gap={'15px'}>
            <Typography variant="h5" textAlign={'start'}>
                Configurações do Curso
            </Typography>
            <Box
                display={'flex'}
                title="Este campo é um campo de referencia para o curso, não sem preocupe com este valor, ele é gerado automaticamente."
            >
                <Typography textAlign={'start'} fontWeight={600} variant="body1" marginRight={'10px'}>
                    Identificador do Curso:
                </Typography>
                <Typography textAlign={'start'} variant="body1">
                    {curso.id}
                </Typography>
            </Box>
            <Box>
                <TextField
                    id="cursoForm-nome"
                    label={'Nome do Curso'}
                    value={curso.nome}
                    onChange={(e) => setCurso((old) => ({ ...old, nome: e.target.value }))}
                    fullWidth
                />
            </Box>
            <Box>
                <TextField
                    id="cursoForm-alias"
                    label={'Alias do Curso'}
                    helperText={`O alias do curso, é um nome reduzido sem espaço ou caracteres especiais que sera usado para crir uma URL "amigavel" para o aluno`}
                    value={curso.alias}
                    onChange={(e) => setCurso((old) => ({ ...old, alias: e.target.value }))}
                    fullWidth
                />
            </Box>
            <Box>
                <TextField
                    id="cursoForm-descricao"
                    label={'Descricao do Curso'}
                    multiline
                    value={curso.descricao}
                    onChange={(e) => setCurso((old) => ({ ...old, descricao: e.target.value }))}
                    fullWidth
                />
            </Box>
            <Box>
                <TextField
                    id="cursoForm-descricaoResumida"
                    label={'Descricao Resumida do Curso'}
                    helperText={`A descrição resumida deve ser pequena contendo apenas algumas palavas descrevendo o curso`}
                    multiline
                    maxRows={1}
                    value={curso.descricaoResumida}
                    onChange={(e) => setCurso((old) => ({ ...old, descricaoResumida: e.target.value }))}
                    fullWidth
                />
            </Box>
            <Box>
                <TextField
                    id="cursoForm-valor"
                    label={'Valor do curso'}
                    InputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                    }}
                    value={curso.valor.toFixed(2)}
                    onChange={(e) => setCurso((old) => ({ ...old, valor: e.target.value }))}
                    fullWidth
                />
            </Box>
            <Box>
                <Button variant="contained" fullWidth>
                    Salvar Alterações
                </Button>
            </Box>
        </Box>
    );
}

export default CursoConfig;
