// import { Container } from './styles';

import { Autocomplete, Box, Button, InputAdornment, TextField, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { Suspense, useEffect, useRef, useState } from 'react';
import { Await, Form, useLoaderData, useSubmit } from 'react-router-dom';
import { getAlunosByNomeOuEmail } from '../../../admServices/aluno.service';
import { postAdmCupom } from '../../../admServices/cupom.service';
import { Container } from './cursos.adm.styles';

export async function salvarCupom({ request, params }) {
    const formData = await request.formData();
    const newData = Object.fromEntries(formData);
    newData.idCurso = params.idCurso;

    console.log('updates', newData);
    if (params.idCupom == 0) {
        newData.idCupom = 0;
        await postAdmCupom(newData);
    } else {
        console.log('Caiu na edição do cupom');
    }
    return null;
}

function CursosCupomEdit() {
    const submit = useSubmit();
    const cupomLoaded = useLoaderData();
    const [open, setOpen] = useState(false);
    const [opcoesSelectAlunos, setOptions] = useState([]);
    const formRef = useRef();
    const [pacienteSelecionado, setPacienteSelecionado] = useState(null);

    useEffect(() => {
        console.log('>>>> Dados baixados cupom');
    }, [cupomLoaded]);

    function salvarDados(params) {
        const formData = new FormData(formRef.current);
        if (pacienteSelecionado) formData.append('idAluno', pacienteSelecionado);

        submit(formData, { method: 'post' });
    }

    async function buscarAlunosByNomeOuEmail(busca) {
        const alunos = await getAlunosByNomeOuEmail(busca);
        return alunos;
    }

    function filtarAluno(busca) {
        buscarAlunosByNomeOuEmail(busca).then((resultado) => {
            setOptions(resultado);
        });
    }

    return (
        <Suspense
            fallback={
                <Container>
                    <h1>Carregando dados do cupom...</h1>
                </Container>
            }
        >
            <Await resolve={cupomLoaded}>
                {(cupom) => (
                    <Container>
                        <Box display={'flex'} flex={1} flexDirection={'column'} gap={'25px'} padding={'10px'}>
                            <Box>
                                <Typography variant="h5">{cupom.id ? `Editando Cupom` : 'Novo Cupom'}</Typography>
                            </Box>
                            {cupom.id && (
                                <Box>
                                    <Typography variant="body1">Codigo de identificação: {cupom.id}</Typography>
                                </Box>
                            )}
                            <Box>
                                <Form
                                    method="post"
                                    id="cupom-config-form"
                                    style={{ display: 'flex', flexDirection: 'column', gap: '25px' }}
                                    ref={formRef}
                                >
                                    <Box>
                                        <TextField
                                            name="cupom"
                                            label={'Nome do Cupom'}
                                            helperText={'Texto que deve ser usado pelo cliente para aplicar desconto.'}
                                            defaultValue={cupom?.cupom}
                                            fullWidth
                                        />
                                    </Box>
                                    <Box>
                                        <TextField
                                            name="percentual"
                                            type="number"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            }}
                                            inputProps={{
                                                max: 100,
                                                step: 0.1,
                                                min: 0.1,
                                            }}
                                            label={'Percentual de desconto'}
                                            helperText={'Percentual de desconto que sera aplicado na compra.'}
                                            defaultValue={cupom?.percentual}
                                            fullWidth
                                        />
                                    </Box>
                                    <Box>
                                        <TextField
                                            name="validade"
                                            type="date"
                                            label={'Validade'}
                                            helperText={'Data de expiração do cupom'}
                                            defaultValue={
                                                cupom?.validade
                                                    ? dayjs(cupom.validade).format('YYYY-MM-DD')
                                                    : dayjs().format('YYYY-MM-DD')
                                            }
                                            fullWidth
                                        />
                                    </Box>
                                    <Box>
                                        <Autocomplete
                                            id="-demo"
                                            open={open}
                                            onOpen={() => {
                                                setOpen(true);
                                            }}
                                            onClose={() => {
                                                setOpen(false);
                                            }}
                                            onChange={(event, newValue) => {
                                                setPacienteSelecionado(newValue.id);
                                            }}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            getOptionLabel={(option) => option.nome}
                                            noOptionsText="Nenhum aluno"
                                            autoComplete
                                            filterSelectedOptions
                                            onInputChange={(event, newInputValue) => {
                                                if (newInputValue.length >= 3) {
                                                    filtarAluno(newInputValue);
                                                } else {
                                                    setOptions([]);
                                                }
                                            }}
                                            fullWidth
                                            options={opcoesSelectAlunos}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    label="Aluno vinculado ao cupom"
                                                    helperText="Digite o nome ou email para buscar um aluno. *Ao menos 3 caracteres."
                                                    InputProps={{
                                                        ...params.InputProps,
                                                    }}
                                                />
                                            )}
                                        />
                                    </Box>
                                    <Box>
                                        <Button variant="contained" onClick={salvarDados}>
                                            Salvar Cupom
                                        </Button>
                                    </Box>
                                </Form>
                            </Box>
                        </Box>
                    </Container>
                )}
            </Await>
        </Suspense>
    );
}

export default CursosCupomEdit;
