import styled from 'styled-components';

export const ContainerArrowBack = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0px 10px;
    gap: 5px;

    > div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 7px;
        border-radius: 50%;
        background-color: #ffffff9e;
        cursor: pointer;
        transition-duration: 0.3s;
    }

    > div:hover {
        transform: scale(1.1);
    }

    > div > svg {
        font-size: 20px;
    }
`;

export const LiMenuResponsivo = styled.li`
    list-style: none;
    background-color: #424242a1;
    border-radius: 6px;
    width: fit-content;
    position: absolute;
    left: 6px;
    top: 6px;

    ul {
        :nth-last-child(1) {
            border-bottom: 0px;
        }
        border-bottom: 1px solid;
        margin: 0;
        padding: 6px;
    }

    @media screen and (min-width: 768px) {
        display: none;
    }
`;

export const ImgMenuResponsivo = styled.img`
    width: 25px;
`;
