import React, { useState, useMemo } from 'react';
import { Input } from '../../../components/commonComponents/commonComponents';
import Button from '@mui/material/Button';
import { Container } from './stylesForgotPassword';
import api from '../../../service/api';
import { toast } from 'react-toastify';
import ToastGame from '../../../components/toastGame/ToastGame';
import { useLocation } from 'react-router-dom';

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [senhaNova, setSenhaNova] = useState('');
    const location = useLocation();

    const params = useMemo(() => {
        return new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
    }, []);

    const token = params?.token;

    async function enviarLink(email) {
        try {
            if (!email)
                return toast.error(<ToastGame title={`Email`} text={'Email Inválido!'} />, {
                    icon: false,
                });
            const result = await api.post(`Login/aluno/esqueci?email=${email}`);
            return toast.error(<ToastGame title={`Email Enviado!`} text={`Link enviado para email: ${email} `} />, {
                icon: false,
            });
        } catch (error) {
            toast.error(<ToastGame title={'Erro ao tentar link!'} text={'Erro'} />, {
                // autoClose: 100000,
                icon: false,
            });
        }
    }

    async function trocarSenha(senha) {
        try {
            if (!senhaNova)
                return toast.error(<ToastGame title={`Senha invalida`} text={'Senha invalida'} />, {
                    icon: false,
                });

            const result = await api.post(`Login/aluno/trocaSenha`, { password: senhaNova, token: token });

            if (result?.data == true) {
                toast.error(<ToastGame title={`Senha atualizada`} text={`Senha atualizada com sucesso!`} />, {
                    icon: false,
                });
                setTimeout(() => {
                    window.location.href = window.location.origin;
                }, 2000);
            } else {
                toast.error(
                    <ToastGame
                        title={'Erro ao trocar senha'}
                        text={'A senha deve conter pelo menos 8 caracteres, com ao menos 1 letra Maiúscula e 1 numero.'}
                    />,
                    {
                        // autoClose: 100000,
                        icon: false,
                    },
                );
            }
        } catch (error) {
            toast.error(<ToastGame title={'Erro ao tentar link!'} text={'Erro'} />, {
                // autoClose: 100000,
                icon: false,
            });
        }
    }

    return (
        <Container>
            {token ? (
                <div>
                    <h1>Trocar Senha</h1>
                    <input onChange={(e) => setSenhaNova(e.target.value)} placeholder="Digite a nova senha" />
                    <sub>Digite aqui sua nova senha</sub>
                    <sub style={{ color: '#00e7ff' }}>
                        A senha deve conter pelo menos 8 caracteres, com ao menos 1 letra Maiúscula e 1 numero.
                    </sub>

                    <Button onClick={() => trocarSenha(senhaNova)} variant="contained">
                        Trocar senha
                    </Button>
                </div>
            ) : (
                <div>
                    <h1>Esqueceu sua senha?</h1>
                    <input
                        type={'mail'}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Digite aqui seu email cadastrado"
                    />
                    <sub>Iremos enviar um email com link para troca da senha.</sub>

                    <Button onClick={() => enviarLink(email)} variant="contained">
                        Enviar link para trocar senha.
                    </Button>
                </div>
            )}
        </Container>
    );
}

export default ForgotPassword;
