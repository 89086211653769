import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Container } from './home.adm.styles';
import { Box, Button, ButtonGroup, Chip, FormControlLabel, Paper, Switch, Typography, useTheme } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Chart from 'react-apexcharts';
import { getNumerosComparativosMes, getVendasPorPeriodo } from '../../../admServices/relatorio.service';
import dayjs from 'dayjs';
import DataGrid from 'react-data-grid';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import 'react-data-grid/lib/styles.css';
import { toast } from 'react-toastify';
import ToastGame from '../../../components/toastGame/ToastGame';
dayjs.extend(quarterOfYear);

function HomeAdm() {
    const theme = useTheme();
    const [vendas, setVendas] = useState(null);
    const [comparativos, setComparativos] = useState(null);
    const [parametrosVendas, setParametrosVendas] = useState({
        inicio: dayjs().startOf('month').format(),
        final: dayjs().endOf('month').format(),
        pagos: true,
    });

    async function getVendas(inicio, final, pagos) {
        try {
            const vendas = await getVendasPorPeriodo({ final, inicio, pagos });
            return vendas;
        } catch (error) {
            toast.error(<ToastGame title={'Erro ao tentar baixa dados'} text={'Erro ao tentar baixa dados de venda!'} />);
            console.error(error);
            return [];
        }
    }

    async function getComparativos() {
        try {
            const comparativos = await getNumerosComparativosMes();
            return comparativos;
        } catch (error) {
            toast.error(
                <ToastGame
                    title={'Erro ao tentar baixa dados comparativos.'}
                    text={'Erro ao tentar baixa dados comparativos!'}
                />,
            );
            console.error(error);
            return {};
        }
    }

    async function atualizaDados(inicio, final, pagos) {
        try {
            const inicioIso = new Date(inicio).toISOString();
            const finalIso = new Date(final).toISOString();
            const dados = await getVendas(inicioIso, finalIso, pagos);
            setVendas(dados);
        } catch (error) {
            console.error(error);
        }
    }

    const dadosGraficoArea = useMemo(() => {
        if (vendas && vendas.length) {
            const cursosObj = vendas.reduce((acc, cur, i, arr) => {
                const id = cur.curso.id;
                if (!acc[id]) {
                    acc[id] = cur.curso;
                }
                return acc;
            }, {});
            const cursosArray = Object.keys(cursosObj).map((key) => cursosObj[key]);

            const pagamentosPorDia = vendas.reduce((acc, cur, i, arr) => {
                const dia = new Date(cur.dataPagamento).toDateString();
                if (acc[dia]) {
                    acc[dia].push(cur);
                } else {
                    acc[dia] = [cur];
                }
                return acc;
            }, {});

            const arryOrganizadoDosPagamentosPorDia = Object.keys(pagamentosPorDia).sort((a, b) => new Date(b) - new Date(a));

            //cada serie vai ser um curso, e cada item é a quantidade de vendas por cada data
            const series = cursosArray.map((curso) => {
                const vendasDesteCursoPorData = arryOrganizadoDosPagamentosPorDia.map((key) =>
                    pagamentosPorDia[key].filter((venda) => venda.curso.id === curso.id),
                );
                const data = vendasDesteCursoPorData.map((vendas) => vendas.length);

                return {
                    name: curso.nome,
                    data,
                };
            });

            const categories = arryOrganizadoDosPagamentosPorDia.map((data) => new Date(data).toISOString());

            return {
                series: series,
                options: {
                    chart: {
                        height: 350,
                        type: 'area',
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    stroke: {
                        curve: 'smooth',
                    },
                    grid: {
                        show: false,
                    },
                    yaxis: {
                        labels: {
                            show: false,
                        },
                    },
                    xaxis: {
                        type: 'datetime',
                        categories: categories,
                        axisBorder: {
                            show: false,
                            color: '#78909C',
                            height: 1,
                            width: '100%',
                            offsetX: 0,
                            offsetY: 0,
                        },
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shade: 'dark',
                            type: 'vertical',
                            shadeIntensity: 1,
                            gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
                            inverseColors: true,
                            opacityFrom: 1,
                            opacityTo: 0.1,
                            stops: [0, 90],
                            colorStops: [],
                        },
                    },
                    tooltip: {
                        x: {
                            format: 'dd/MM/yy HH:mm',
                        },
                    },
                },
            };
        } else {
            return {
                series: [],
                options: {
                    chart: {
                        height: 350,
                        type: 'area',
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    stroke: {
                        curve: 'smooth',
                    },
                    grid: {
                        show: false,
                    },
                    yaxis: {
                        labels: {
                            show: false,
                        },
                    },
                    xaxis: {
                        type: 'datetime',
                        categories: [],
                        axisBorder: {
                            show: false,
                            color: '#78909C',
                            height: 1,
                            width: '100%',
                            offsetX: 0,
                            offsetY: 0,
                        },
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shade: 'dark',
                            type: 'vertical',
                            shadeIntensity: 1,
                            gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
                            inverseColors: true,
                            opacityFrom: 1,
                            opacityTo: 0.1,
                            stops: [0, 90],
                            colorStops: [],
                        },
                    },
                    tooltip: {
                        x: {
                            format: 'dd/MM/yy HH:mm',
                        },
                    },
                },
            };
        }
    }, [vendas]);

    const vendasGrid = useMemo(() => {
        if (!vendas || !vendas.length) return [];
        return vendas.map((venda) => ({
            ...venda,
            dataPagamento: dayjs(venda.dataPagamento).format('DD/MM/YYYY HH:mm'),
            emailCliente: venda.aluno.email,
            valor: parseInt(venda.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            nomeCliente: venda.aluno.nome,
            nomeCurso: venda.curso.nome,
        }));
    }, [vendas]);

    const dadosGraficoPizza = useMemo(() => {
        const dados = { series: [], labels: [] };
        if (!vendas || !vendas.length) return dados;

        const cursosObj = vendas.reduce((acc, cur, i, arr) => {
            const id = cur.curso.id;
            if (!acc[id]) {
                acc[id] = cur.curso;
            }
            return acc;
        }, {});

        const cursosArray = Object.keys(cursosObj).map((key) => cursosObj[key]);

        const series = cursosArray.map(({ id }) => {
            const todasVendas = vendas.filter((venda) => venda.curso.id === id);
            return todasVendas.length;
        });

        return { series: series, labels: cursosArray.map((i) => i.nome) };
    }, [vendas]);

    useEffect(() => {
        atualizaDados(parametrosVendas.inicio, parametrosVendas.final, parametrosVendas.pagos);
    }, [parametrosVendas]);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        const comparativos = await getComparativos();
        setComparativos(comparativos);
    }

    return (
        <Container>
            {/* <Box display={'flex'} justifyContent={'space-between'} padding={'10px 15px'}>
                <Typography color={theme.palette.text.primary} variant="h4">
                    Home
                </Typography>
            </Box> */}
            <Box display={'flex'} gap={'10px'} padding={'15px'} justifyContent={'space-between'}>
                <Box flex={1}>
                    <Paper elevation={1} padding={'10px'} display={'flex'}>
                        <Box padding={'15px 10px'} display={'flex'} alignItems={'flex-end'}>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                flex={1}
                                alignItems={'flex-start'}
                                justifyContent={'center'}
                                gap={'10px'}
                            >
                                <Typography variant="subtitle2" color={theme.palette.grey[400]}>
                                    Novos Alunos no mês
                                </Typography>
                                <Typography variant="h5" color="primary" lineHeight={'1.5rem'}>
                                    {comparativos?.alunosEsteMes || 0}
                                </Typography>
                            </Box>
                            {/* <Box display={'flex'} flexDirection={'column'} alignItems={'flex-end'} justifyContent={'flex-end'}>
                                <Chip
                                    icon={<ArrowUpwardIcon sx={{ fontSize: '14px', fontWeight: 600 }} />}
                                    label={`${'20'}%`}
                                    color="primary"
                                    variant="outlined"
                                    fontSize="small"
                                    sx={{ paddingLeft: '5px' }}
                                />
                            </Box> */}
                        </Box>
                    </Paper>
                </Box>

                <Box flex={1}>
                    <Paper elevation={1} padding={'10px'} flex={1} display={'flex'}>
                        <Box padding={'15px 10px'} display={'flex'} alignItems={'flex-end'}>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                flex={1}
                                alignItems={'flex-start'}
                                justifyContent={'center'}
                                gap={'10px'}
                            >
                                <Typography variant="subtitle2" color={theme.palette.grey[400]}>
                                    Vendas de Cursos no mês
                                </Typography>
                                <Typography variant="h5" color="primary" lineHeight={'1.5rem'}>
                                    {comparativos?.vendasEsteMes || 0}
                                </Typography>
                            </Box>
                            {/* <Box display={'flex'} flexDirection={'column'} alignItems={'flex-end'} justifyContent={'flex-end'}>
                                <Chip
                                    icon={<ArrowDownwardIcon sx={{ fontSize: '14px', fontWeight: 600 }} />}
                                    label={`${'5'}%`}
                                    color="error"
                                    variant="outlined"
                                    fontSize="small"
                                    sx={{ paddingLeft: '5px' }}
                                />
                            </Box> */}
                        </Box>
                    </Paper>
                </Box>
                <Box flex={1}>
                    <Paper elevation={1} padding={'10px'} flex={'1'} display={'flex'} flexDirection={'column'}>
                        <Box padding={'15px 10px'} display={'flex'} alignItems={'flex-end'}>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                flex={1}
                                alignItems={'flex-start'}
                                justifyContent={'center'}
                                gap={'10px'}
                            >
                                <Typography variant="subtitle2" color={theme.palette.grey[400]}>
                                    Visitantes no mês
                                </Typography>
                                <Typography variant="h5" color="primary" lineHeight={'1.5rem'}>
                                    {comparativos?.visitantesEsteMes || 0}
                                </Typography>
                            </Box>
                            {/* <Box display={'flex'} flexDirection={'column'} alignItems={'flex-end'} justifyContent={'flex-end'}>
                                <Chip
                                    icon={<ArrowUpwardIcon sx={{ fontSize: '14px', fontWeight: 600 }} />}
                                    label={`${'11,5'}%`}
                                    color="primary"
                                    variant="outlined"
                                    fontSize="small"
                                    sx={{ paddingLeft: '5px' }}
                                />
                            </Box> */}
                        </Box>
                    </Paper>
                </Box>
            </Box>
            {vendas === null ? (
                <Box display={'flex'} flex={1} justifyContent={'center'} alignItems={'center'}>
                    <Typography variant="h4" color={theme.palette.text.primary}>
                        Carregando dados...
                    </Typography>
                </Box>
            ) : (
                <Box display={'flex'} flexDirection={'column'} padding={'15px'} flex={1} overflow={'hidden'}>
                    <Typography variant="h5" textAlign={'start'} color={theme.palette.text.primary}>
                        {`Vendas Cursos de ${new Date(parametrosVendas.inicio).toLocaleDateString()} até ${new Date(
                            parametrosVendas.final,
                        ).toLocaleDateString()} - Total: ${vendas.length} vendas `}
                    </Typography>
                    <Typography variant="subtitle1" textAlign={'start'} color={theme.palette.grey[500]}>
                        Quantas vendas de curso foram feitas em um tenterminado periodo de tempo
                    </Typography>
                    <Box marginTop={'10px'} display={'flex'} justifyContent={'space-between'}>
                        <ButtonGroup variant="outlined" aria-label="Filtros dos dados">
                            <Button
                                sx={{ textTransform: 'capitalize' }}
                                onClick={() =>
                                    setParametrosVendas((old) => ({
                                        pagos: old.pagos,
                                        inicio: dayjs().startOf('month').format(),
                                        final: dayjs().endOf('month').format(),
                                    }))
                                }
                            >
                                Este Mês
                            </Button>
                            <Button
                                sx={{ textTransform: 'capitalize' }}
                                onClick={() =>
                                    setParametrosVendas((old) => ({
                                        pagos: old.pagos,
                                        inicio: dayjs().startOf('quarter').format(),
                                        final: dayjs().endOf('quarter').format(),
                                    }))
                                }
                            >
                                Este Trimestre
                            </Button>
                            <Button
                                sx={{ textTransform: 'capitalize' }}
                                onClick={() => {
                                    const primeiroTrimestre = new Date().getMonth() + 1 > 6 ? false : true;
                                    setParametrosVendas((old) => ({
                                        pagos: old.pagos,
                                        inicio: dayjs()
                                            .month(primeiroTrimestre ? 0 : 6)
                                            .startOf('month')
                                            .format(),
                                        final: dayjs()
                                            .month(primeiroTrimestre ? 5 : 11)
                                            .endOf('month')
                                            .format(),
                                    }));
                                }}
                            >
                                Este Semestre
                            </Button>
                            <Button
                                sx={{ textTransform: 'capitalize' }}
                                onClick={() =>
                                    setParametrosVendas((old) => ({
                                        pagos: old.pagos,
                                        inicio: dayjs().month(0).startOf('year').format(),
                                        final: dayjs().endOf('year').format(),
                                    }))
                                }
                            >
                                Este Ano
                            </Button>
                        </ButtonGroup>
                        <FormControlLabel
                            labelPlacement="start"
                            sx={{ color: theme.palette.text.primary }}
                            control={
                                <Switch
                                    checked={parametrosVendas.pagos}
                                    onChange={(e) => setParametrosVendas((old) => ({ ...old, pagos: e.target.checked }))}
                                />
                            }
                            label="Mostrar somente Pagos"
                        />
                    </Box>
                    {!vendas.length ? (
                        <Box display={'flex'} flex={1} justifyContent={'center'} alignItems={'center'}>
                            <Typography variant="h5" color={theme.palette.text.primary}>
                                {`Não foram localizadas vendas para o perido  ${new Date(
                                    parametrosVendas.inicio,
                                ).toLocaleDateString()} até ${new Date(parametrosVendas.final).toLocaleDateString()}  ${
                                    parametrosVendas.pagos ? ' com status pago.' : ' sem filtro de status.'
                                }`}
                            </Typography>
                        </Box>
                    ) : (
                        <Box display={'flex'} flexDirection={'column'} flex={1}>
                            <Box display={'flex'} flex={1}>
                                <Box flex={1}>
                                    <Chart
                                        options={dadosGraficoArea.options}
                                        series={dadosGraficoArea.series}
                                        type="area"
                                        height={window.innerHeight / 4}
                                    />
                                </Box>
                                <Box width={300}>
                                    <Chart
                                        options={{
                                            labels: dadosGraficoPizza.labels,
                                            legend: {
                                                show: false,
                                            },
                                        }}
                                        series={dadosGraficoPizza.series}
                                        type="donut"
                                        width={300}
                                        height={window.innerHeight / 4}
                                    />
                                </Box>
                            </Box>
                            <Box flex={1}>
                                <DataGrid
                                    groupBy={['nomeCurso']}
                                    columns={[
                                        { key: 'nomeCliente', name: 'Nome Cliente' },
                                        { key: 'emailCliente', name: 'Email Cliente' },
                                        { key: 'nomeCurso', name: 'Curso' },
                                        { key: 'valor', name: 'Valor' },
                                        { key: 'stutus', name: 'Status' },
                                        { key: 'dataPagamento', name: 'Pagamento' },
                                        { key: 'tid', name: 'TID' },
                                        { key: 'cupom', name: 'Cupom' },
                                        { key: 'idLead', name: 'Lead', width: 60 },
                                        { key: 'id', name: 'ID da Venda', width: 60 },
                                    ]}
                                    rows={vendasGrid}
                                    // rowKeyGetter={(row) => console.log('clicou', row)}
                                />
                            </Box>
                        </Box>
                    )}
                </Box>
            )}
        </Container>
    );
}

export default HomeAdm;
