import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    top: 0px;
    background-color: #000;
    z-index: 20;
`;

export const Fechar = styled.div`
    position: absolute;
    top: 10px;
    z-index: 21;
    right: 15px;
    color: #fff;
    cursor: pointer;
`;

export const ProgressContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 80vw;
    padding: 10px 0;
    cursor: pointer;

    &.pause > div {
        animation-play-state: paused;
    }
`;

const Loader = keyframes`
    0%   { background-position: 100% 0; }
    100% { background-position: 0 0; }
`;

export const Progress = styled.div`
    height: 2px;
    flex-grow: 1;
    border-radius: 4px;
    margin: 10px 5px;
    display: flex;
    background-image: -webkit-linear-gradient(
        left,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(88, 89, 104, 0.5) 50.001%,
        rgba(88, 89, 104, 0.5) 100%
    );
    background-repeat: no-repeat;
    background-size: 200%;
    background-color: #666;
    background-position: 100% 50%;
    animation-timing-function: linear;
    animation-delay: 0.2s;
    animation-duration: 10s;

    &.active {
        animation-name: ${Loader};
    }

    &.passed {
        background-position: 0 0;
    }
`;

export const ContainerBody = styled.div`
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    position: relative;
`;
export const ContainerVideo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: calc(100% - 50px);
    max-width: 100vw;
    border-radius: 20px;
    overflow: hidden;
    position: relative;

    > .goPrevious {
        position: absolute;
        height: 100%;
        width: 50%;
        left: 0px;
    }

    > .goNext {
        right: 0px;
        position: absolute;
        height: 100%;
        width: 50%;
    }
`;

export const VerMais = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 40px;
    color: #c7c7c7;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition-duration: 0.7s;
    font-weight: 600;

    :hover {
        color: #fff;
        text-decoration: underline;
    }

    > svg {
        margin-bottom: 5px;
        font-size: 20px;
        transform: rotate(270deg);
    }
`;
export const ContainerPergunta = styled.div`
    width: 100%;
    height: 200px;
    bottom: 20px;
    position: absolute;
    background-color: #fff;
    color: #333;
    transition-duration: 0.8s;
    border-radius: 20px;
    transform: translateY(130%);
    display: flex;
    flex-direction: column;

    &.visivel {
        transform: translateY(0%);
    }

    > span {
        font-weight: 600;
        font-size: 18px;
    }
`;

export const ClosePergunta = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    font-size: 20px;
    cursor: pointer;
`;

export const ContainerPerguntaTexto = styled.div`
    flex: 1;
    padding: 16px;
    padding-top: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0px 15px;
    font-weight: 600;

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    ::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
    }
`;
