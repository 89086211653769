import EasyStar from 'easystarjs';
import Phaser from 'phaser';
import { autorun, toJS } from 'mobx';
import Player from '../objects/player';
import Bandeira from '../objects/bandeira';
import Auditorio from '../objects/auditorio';
// import PhaserTooltip, { addTooltip } from '../components/tooltips';
import StorePopUps from '../../store/StorePopUps';
import gameInitStore from '../../store/gameInitStore';
import StoreJornada from '../../store/StoreJornada';
const CreateDOM = function (scene, content) {
    var dom = scene.add.dom().createFromHTML(content);
    dom.node.style.width = '400px';
    dom.updateSize();
    return dom;
};

export const TIPOS_AULAS = {
    aula: 1,
    casoClinico: 2,
    auditorio: 3,
    diploma: 4,
};
export const posicaoInicialPlayer = { x: 470, y: 5000 };

export const JORNADA_ID = document.location.pathname.replace('/app/game/', '');
export const KEY_POSICAO_PLAYER = `ultima_posicao_player-${JORNADA_ID}`;

export default class GameScene extends Phaser.Scene {
    constructor(scene) {
        super(scene);
        this.jornada = null;
        this.map = null;
        this.tiles = null;
        this.layer = null;
        this.player = null;
        this.finder = new EasyStar.js();
        this.target = new Phaser.Math.Vector2();
        this.Bandeiras = [];
        this.scoreText = null;
        this.camera = null;
        this.scoreTextMoedas = null;
        this.scoreTextDiamantes = null;
        this.duvidaImage = null;
        this.emitter = null;
        this.graphics = null;
        this.anyPopUpisOpen = false;
        this.listElementosGenericos = [];
        this.listBibliotecas = [];
    }

    preload() {
        // this.load.scenePlugin('ObsidadeScene', PhaserTooltip, 'PhaserTooltip', 'tooltip');
        autorun(() => {
            this.jornada = toJS(gameInitStore.jornadaCompleta.jornada);
            this.anyPopUpisOpen = StorePopUps.anyPopupIsOpen() || toJS(StoreJornada.popUpVideoAulaAberto);
        });
    }

    create() {
        try {
            this.input.mouse.disableContextMenu();
            // console.log('Dados jornada Scene', this.jornada);

            const aulas = this.jornada.aulas
                .filter((item) => item.tipo == TIPOS_AULAS.aula || item.tipo == TIPOS_AULAS.diploma)
                .sort((a, b) => a.ordem - b.ordem);
            const casosClinicos = this.jornada.aulas.filter((item) => item.tipo == TIPOS_AULAS.casoClinico);
            const auditorio = this.jornada.aulas.find((item) => item.tipo == TIPOS_AULAS.auditorio);
            const bibliotecas = this.jornada.bibliotecas;
            const objJornada = JSON.parse(this.jornada.jsonConfigurationMap);
            const elementosGenericos = objJornada.elementosGenericos || [];

            console.log('Aulas', aulas);
            console.log('Casos Clinicos', casosClinicos);
            // console.log('Auditorio', auditorio);
            //FUNCAO CREATE MONTA O JOGO

            this.input.on('pointerdown', this.handleClickInMap.bind(this));
            // input.activePointer.leftButton.isDown
            //cria mapa
            this.map = this.make.tilemap({ key: 'map' });
            //CADA LAYER TEM QUE SER CRIADO COMO OBJETO SEPARADO, COM SEUS TILES DE ORIGEM
            this.camera = this.cameras.main;
            this.camera.setVisible(true);

            this.tilesElements = this.map.addTilesetImage('tileset_elements_campus', 'tilesElements');
            this.tiles = this.map.addTilesetImage('tileset_ground_campus', 'tiles');

            this.layer = this.map.createLayer('Ground', this.tiles, 0, 0);
            this.layerElements2 = this.map.createLayer('Elementos2', this.tilesElements, 0, 0);

            this.layerElements1 = this.map.createLayer('Elementos1', this.tilesElements, 0, 0);
            this.layerElements = this.map.createLayer('Elementos', this.tilesElements, 0, 0);

            const posicaoString = localStorage.getItem(KEY_POSICAO_PLAYER);

            if (posicaoString) {
                const posicoes = JSON.parse(posicaoString);
                this.player = new Player(this, posicoes.x, posicoes.y, 'avatarSprit');
            } else {
                this.player = new Player(this, posicaoInicialPlayer.x, posicaoInicialPlayer.y, 'avatarSprit');
            }

            this.player.scale = window.innerWidth > 900 ? 0.9 : 1;
            this.particles = this.add.particles('white');
            this.emitter = this.particles.createEmitter({
                speed: 80,
                scale: { start: 0.07, end: 0 },
                blendMode: 'ADD',
            });

            //criando bandeira de aulas
            for (let index = aulas.length - 1; index >= 0; index--) {
                // const { positions, playerPostion, Title, sacoMoedas, ...resto } = aulas[index];
                if (aulas[index].tipo == TIPOS_AULAS.diploma) {
                    aulas[index].diploma = true;
                }
                if (index != aulas.length - 1) {
                    //cria a proxima bandeira com a referencia da anterior
                    const bandeira = new Bandeira(
                        this,
                        aulas[index].posicaoX,
                        aulas[index].posicaoY,
                        { x: aulas[index].posicaoJogadorX, y: aulas[index].posicaoJogadorY },
                        this.player,
                        aulas[index].titulo,
                        aulas[index].sacoMoedas,
                        aulas[index],
                        this.Bandeiras[this.Bandeiras.length - 1],
                        this.emitter,
                    );

                    this.Bandeiras.push(bandeira);
                } else {
                    // ultima bandeira que não precisa da referencia da anterior pois não vai ter que libera-la
                    const bandeira = new Bandeira(
                        this,
                        aulas[index].posicaoX,
                        aulas[index].posicaoY,
                        { x: aulas[index].posicaoJogadorX, y: aulas[index].posicaoJogadorY },
                        this.player,
                        aulas[index].titulo,
                        aulas[index].sacoMoedas,
                        aulas[index],
                        false,
                        this.emitter,
                    );
                    this.Bandeiras.push(bandeira);
                }
            }

            this.Bandeiras.forEach((Bandeiras) => (Bandeiras.scale = window.innerWidth > 900 ? 0.8 : 1));
            this.map.currentLayerIndex = 0;

            if (elementosGenericos.length) {
                elementosGenericos.forEach((elemento, index) => {
                    const elementoGenerico = this.add.image(elemento.x, elemento.y, `elementoGenerico-${index}`);
                    elementoGenerico.scale = 1.1;
                    elementoGenerico.setDepth(10);
                    this.listElementosGenericos.push(elementoGenerico);
                });
            }

            if (bibliotecas.length) {
                bibliotecas.forEach((biblioteca, index) => {
                    const lib = this.add.image(biblioteca.prosicaoX, biblioteca.prosicaoY, biblioteca.urlImagemNoJogo);
                    lib.scale = 1.15;
                    lib.setDepth(10);
                    lib.setInteractive().on('pointerup', () => {
                        const { x: PlayerX, y: PlayerY } = this.player.getPositionInTiles();
                        this.findPathAndMove(PlayerX, PlayerY, biblioteca.playerPosicaoX, biblioteca.playerPosicaoY, () =>
                            this.scene.start('BibliotecaScene'),
                        );
                    });
                    this.listBibliotecas.push(lib);
                });
            }

            if (auditorio) {
                const Predio_auditorio = new Auditorio(
                    this,
                    auditorio.posicaoX,
                    auditorio.posicaoY,
                    this.player,
                    auditorio.titulo,
                    auditorio,
                );
                Predio_auditorio.setDepth(10);
            }

            // Place the player above the tile layers
            // this.player.setDepth(10);
            // Place the overhead layer above everything else
            // overheadLayer.setDepth(20);

            //DEFINE A CAMERA COM LIMITES DO MAPA, ZOOM 100%, CENTRALIZA EM 0,0 PARA COMEÇAR
            // debugger;

            const larguraContainerMiniCam = window.innerWidth > 900 ? 250 : 100;

            // this.minimap = this.cameras
            //     .add(window.innerWidth - larguraContainerMiniCam, 10)
            //     .setZoom(0.1)
            //     .setName('mini')
            //     .startFollow(this.player);

            // this.minimap.alpha = 0.9;
            // this.minimap.setSize(larguraContainerMiniCam, window.innerWidth > 900 ? 200 : 150);
            // this.minimap.setBounds(0, 0);

            this.camera.setBounds(0, 0, this.map.width * 32, this.map.height * 32);
            this.camera.startFollow(this.player); //SEGUE O JOGADOR
            this.camera.setZoom(window.innerWidth > 900 ? 1 : 0.5);

            // this.physics.add.collider(this.player, this.layer);
            this.configPathFinder();

            this.layer.setDepth(0);
            this.particles.setDepth(5);
            this.layerElements.setDepth(10); // layer primeira arvore
            this.player.setDepth(15);
            this.layerElements1.setDepth(25);
            this.layerElements2.setDepth(25);
        } catch (error) {
            console.log(error);
        }
    }

    update() {
        this.player.lastpositionX = this.player.body.position.x;
        this.player.lastpositionY = this.player.body.position.y;
    }

    configPathFinder() {
        const getTileID = (x, y) => {
            //AQUI CATA QUAL CODIGO DO TILE PELA COORDENADA X,Y
            var tile = this.map.getTileAt(x, y, false, this.layer);
            if (tile != null) {
                return tile.index;
            }
        };
        let tileset = this.map.tilesets[0];
        let properties = tileset.tileProperties;
        let acceptableTiles = [];
        let grid = [];

        for (var y = 0; y < this.map.height; y++) {
            let col = [];
            for (var x = 0; x < this.map.width; x++) {
                // In each cell we store the ID of the tile, which corresponds
                // to its index in the tileset of the map ("ID" field in Tiled)
                col.push(getTileID(x, y));
            }
            grid.push(col);
        }

        this.finder.setGrid(grid);

        //FUNCAO QUE CARREGA EM MATRIZ OS TILES ACEITÁVEIS PARA "ANDAR"

        // We need to list all the tile IDs that can be walked on. Let's iterate over all of them
        // and see what properties have been entered in Tiled.
        for (var i = tileset.firstgid - 1; i < this.tiles.total; i++) {
            // firstgid and total are fields from Tiled that indicate the range of IDs that the tiles can take in that tileset

            if (!properties.hasOwnProperty(i)) {
                // If there is no property indicated at all, it means it's a walkable tile
                acceptableTiles.push(i + 1);
                continue;
            }

            if (!properties[i].collides) {
                acceptableTiles.push(i + 1);
                continue;
            }
        }

        this.finder.setAcceptableTiles(acceptableTiles);
    }

    handleClickInMap(pointer) {
        if (pointer.rightButtonDown()) return;
        console.log(pointer.worldX.toFixed(0), pointer.worldY.toFixed(0));
        let tileClicked = this.map.getTileAtWorldXY(pointer.worldX, pointer.worldY);
        const { x: fromX, y: fromY } = this.player.getPositionInTiles();
        this.findPathAndMove(fromX, fromY, tileClicked.x, tileClicked.y);
    }

    findPathAndMove(fromX, fromY, toX, toY, acaoDepoisDeAndar) {
        if (this.anyPopUpisOpen) return; //console.log('Algum popup esta aberto');
        //converte coordenadas para int caso venham de cálculos com decimal
        fromX = parseInt(fromX);
        fromY = parseInt(fromY);
        toX = parseInt(toX);
        toY = parseInt(toY);

        this.finder.findPath(fromX, fromY, toX, toY, (path) => {
            if (path === null) {
                return console.error('Path was not found.');
            } else {
                console.log(toX, toY);
                this.player.move(path, this.map, acaoDepoisDeAndar);
            }
        });
        this.finder.calculate();
        //  // SE NÃO CHAMAR AQUI ELE NÃO FAZ NADA!
    }
}
