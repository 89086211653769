import { Box, Typography, useTheme } from '@mui/material';
import { TIPOS_AULAS } from '../../../../game/scenes/ObsidadeScene';
import { ItemTabela, Tabela } from './ListElementosJornada.adm.styles';

const tiposElementos = Object.keys(TIPOS_AULAS).map((key) => [key, TIPOS_AULAS[key]]);

function ListElementosJornada({ elemento: elementos, click }) {
    const theme = useTheme();
    console.log(theme);
    function getTipo(elemento) {
        return tiposElementos.filter((x) => x[1] == elemento.tipo)[0][0];
    }
    return (
        <Tabela>
            {elementos.map((elemento) => (
                <ItemTabela
                    key={elemento.id}
                    style={{ backgroundColor: theme.palette.background.default }}
                    title={`Ver ${getTipo(elemento)} da jornada`}
                    onClick={() =>
                        click instanceof Function
                            ? click(elemento)
                            : console.error('O parametro "click" não foi fornecido ou não é uma função!')
                    }
                >
                    <Box display={'flex'} flex={1} width={'100%'} alignItems={'flex-end'} marginBottom={'25px'}>
                        <Typography color={theme.palette.primary.dark} variant="h5" flex={1} width={'100%'} textAlign={'start'}>
                            {elemento.titulo}
                        </Typography>

                        <Typography color={theme.palette.primary.main} variant="body1" textAlign={'start'}>
                            {`Ordem ${elemento.ordem}`}
                        </Typography>
                    </Box>

                    <Typography color={theme.palette.text.secondary} variant="body1" textAlign={'start'}>
                        {elemento.descricao}
                    </Typography>

                    <Box display={'flex'} justifyContent={'space-between'} gap={'15px'} width={'100%'}>
                        <Box>
                            <Typography color={theme.palette.text.primary} variant="body2" textAlign={'start'} fontWeight={600}>
                                Data de criacao
                            </Typography>
                            <Typography color={theme.palette.text.secondary} textAlign={'start'} variant="body1">
                                {new Date(elemento.dataCriacao).toLocaleString()}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography color={theme.palette.text.primary} variant="body2" textAlign={'start'} fontWeight={600}>
                                Data de Lançamento
                            </Typography>
                            <Typography color={theme.palette.text.secondary} textAlign={'start'} variant="body1">
                                {new Date(elemento.lancamento).toLocaleString()}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography color={theme.palette.text.primary} variant="body2" textAlign={'start'} fontWeight={600}>
                                Autor
                            </Typography>
                            <Typography color={theme.palette.text.secondary} textAlign={'start'} variant="body1">
                                {elemento.autor.nome}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography color={theme.palette.text.primary} variant="body2" textAlign={'start'} fontWeight={600}>
                                Tipo
                            </Typography>
                            <Typography
                                color={theme.palette.text.secondary}
                                textAlign={'start'}
                                variant="body1"
                                sx={{ textTransform: 'capitalize' }}
                            >
                                {getTipo(elemento)}
                            </Typography>
                        </Box>
                    </Box>
                </ItemTabela>
            ))}
        </Tabela>
    );
}

export default ListElementosJornada;
