import axios from 'axios';
import constantes from '../utils/constantes';
import { getToken } from '../service/auth';

// Set config defaults when creating the instance
const api = axios.create({
    baseURL: constantes.APIUrl,
});

// Alter defaults after instance has been created
// instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

// Add a request interceptor
api.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        const token = getToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    },
);

// Add a response interceptor
api.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    },
);

export default api;
