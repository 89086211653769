import api from './api';

export const apiUrlAdm = 'cupom/adm';

export const getAdmCupom = async (idCupom) => {
    const { data } = await api.get(`${apiUrlAdm}/${idCupom}`);
    return data;
};

export const postAdmCupom = async (cupom) => {
    const { data } = await api.post(`${apiUrlAdm}`, cupom);
    return data;
};

export const putAdmCupom = async (cupom) => {
    const { data } = await api.put(`${apiUrlAdm}`, cupom);
    return data;
};
