import styled from 'styled-components';

export const Container = styled.form`
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: #0d0d0d;
    color: #fff;
    gap: 15px;
    overflow-y: auto;

    @media (max-width: 600px) {
        > div > nav > ol > li > a,
        > div > nav > ol > li > h4,
        > div > nav > ol > li > svg {
            font-size: 16px;
        }
    }
`;

export const ContainerGroupItem = styled.div`
    flex: 1;
    display: flex;
`;

export const ContainerItem = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

export const Tabela = styled.div`
    display: flex;
    flex: 1;
    margin-bottom: 10px;
    padding: 0px 10px;
    gap: 15px;
    flex-direction: column;
    overflow-y: auto;
`;

export const ItemTabela = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    /* border: 1px solid #1e1e1e; */
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    transition-duration: 0.5s;

    .imagemTabela {
        border-radius: 50%;
        width: 100px;
        height: 100px;
        object-fit: cover;
        margin-right: 15px;
    }

    .imagemTabelaJornada {
        border-radius: 8px;
        width: 90px;
        height: 50px;
        object-fit: cover;
        margin-right: 15px;
    }

    .textoDescricao {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    :hover {
        transform: scale(0.98);
    }
`;
