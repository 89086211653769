import styled from 'styled-components';

export const ContainerPromocao = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0px;
    height: 80px;
    width: 100vw;
    background-color: ${(p) => p.bgcolor};
    z-index: 1;

    @media (max-width: 600px) {
        height: 150px;
    }
`;

export const ContadorPromocao = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${(p) => p.bgcolor};
    position: absolute;
    justify-self: flex-end;
    bottom: 55px;
    border-radius: 8px;
    padding: 10px 15px;

    @media (max-width: 600px) {
        bottom: 125px;
    }
`;

export const ContainerTextosPromocao = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    padding: 0px 25px;

    @media (max-width: 600px) {
        flex-direction: column;
        text-align: center;
    }
`;
