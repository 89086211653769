import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import imgClose from '../../assets/close.png';
import stiker from '../../game/assets/Sticker.png';
import { Container } from './popUpStyles';
import VideoPlayer from '../videoPlayer/VideoPlayer';
import api from '../../service/api';

const _alfabeto = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i'];

function PopUpPerguntasFase({ popUpAulaPerguntasIsOpen, closePopUpAulaFase, objAulaSelecionada, casoClinico, pauseAmbiente }) {
    const [aula, setAula] = useState(null);
    const [vendoGabarito, setVendoGabarito] = useState(false);
    const [indexPerguntaVendo, setIndexPerguntaVendo] = useState(0);
    const [vendoVideo, setVendoVideo] = useState(false);

    const pergunta = useMemo(() => {
        if (!aula) return null;
        return aula.perguntas[indexPerguntaVendo];
    }, [indexPerguntaVendo, aula]);

    useEffect(() => {
        console.log('pop perguntas aberto para aula', objAulaSelecionada);
        init(objAulaSelecionada, casoClinico);
    }, [objAulaSelecionada, casoClinico]);

    async function init(objAulaSelecionada, casoClinico) {
        setAula({
            ...objAulaSelecionada,
            perguntas: objAulaSelecionada.questoes.map((questao) => {
                // ({...questao, })
                if (questao.respostas == null || questao.respostas.length == 0) {
                    return { ...questao };
                } else {
                    const resposta = questao.respostas.find((resp) => resp.idQuestao === questao.id);
                    return { ...questao, resposta: resposta.idOpcaoRespondida };
                }
            }),
        });
        setVendoGabarito(false);
        setIndexPerguntaVendo(0);
    }

    function atualizaRespostaAula(indexPergunta, idOpcao) {
        if (vendoGabarito) {
            return setVendoGabarito(false);
        }
        let _aula = { ...aula };
        _aula.perguntas[indexPergunta].resposta = idOpcao;
        setAula(_aula);
    }

    async function handleVerGabarito() {
        if (aula.perguntas[indexPerguntaVendo].acertou == true) {
            return setVendoGabarito(true);
        }

        const respostaId = aula.perguntas[indexPerguntaVendo].resposta;
        const idQuestao = aula.perguntas[indexPerguntaVendo].id;

        const aulaId = aula.id;
        const retorno = await salvaResposta(aulaId, idQuestao, respostaId);

        let _aula = { ...aula };
        _aula.perguntas[indexPerguntaVendo].acertou = retorno;
        setAula(_aula);

        setVendoGabarito(true);
    }

    async function salvaResposta(aulaId, questaoId, respostaId) {
        try {
            const { data } = await api.post(
                `Resposta/responder?aulaId=${aulaId}&questaoId=${questaoId}&respostaId=${respostaId}`,
            );

            if (data == 'Resposta correta') {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error(error);
        }
    }

    return !aula ? (
        <div>
            <h2>Carregando questionaro...</h2>
        </div>
    ) : (
        <Container
            className="popup"
            id="popPerguntasUpFase"
            style={{ display: popUpAulaPerguntasIsOpen ? 'flex' : 'none', backgroundColor: '#ffffffc2' }}
        >
            <div className="contentPopUp">
                <div className="TitlePopUp">
                    <span id="TitlePopUp">
                        {casoClinico
                            ? `Casos clínicos da aula ${objAulaSelecionada.ordem}`
                            : `Exercícios da ${objAulaSelecionada.ordem}ª FASE`}
                    </span>
                </div>
                <div
                    className="close"
                    onClick={() => {
                        setVendoGabarito(false);
                        setVendoVideo(false);
                        return closePopUpAulaFase();
                    }}
                >
                    <img src={imgClose} />
                </div>

                {pergunta && (
                    <div className="conteudoPopUp">
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                overflowY: 'auto',
                                overflowX: 'hidden',
                                color: '#fff',
                                position: 'relative',
                            }}
                        >
                            <ContainerOpcoes vendoGabarito={vendoVideo && vendoGabarito}>
                                <h2 style={{ textAlign: 'center' }}>{pergunta?.pergunta}</h2>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        paddingRight: vendoGabarito ? '10px' : '0px',
                                    }}
                                >
                                    {pergunta.perguntas.map((opcao, indexOpcao) => (
                                        <div
                                            key={`${indexOpcao}-opcaoPergunta`}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                textAlign: 'start',
                                                marginBottom: '10px',
                                                alignItems: 'start',
                                                cursor: 'pointer',
                                                padding: '5px 10px',
                                                borderRadius: '8px',
                                                backgroundColor: (() => {
                                                    let cor = '';
                                                    if (opcao.id != pergunta.resposta && opcao.id != pergunta.idOpcaoGabarito)
                                                        return cor;
                                                    if (opcao.id === pergunta.resposta) {
                                                        cor = '#fff';
                                                    }

                                                    if (vendoGabarito) {
                                                        if (opcao.id === pergunta.resposta) {
                                                            if (pergunta.resposta === pergunta.idOpcaoGabarito) {
                                                                return '#2ea12a';
                                                            } else {
                                                                return 'red';
                                                            }
                                                        } else {
                                                            return '';
                                                        }
                                                    }
                                                    return cor;
                                                })(),
                                            }}
                                            onClick={() => atualizaRespostaAula(indexPerguntaVendo, opcao.id)}
                                        >
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <input
                                                    type="radio"
                                                    name={pergunta.id}
                                                    value={opcao.id}
                                                    id={opcao.id}
                                                    checked={opcao.id == pergunta.resposta ? true : false}
                                                    readOnly
                                                />
                                                <label
                                                    htmlFor={opcao.id}
                                                    style={{
                                                        marginLeft: '10px',
                                                        fontSize: '16px',
                                                        textAlign: 'start',
                                                        cursor: 'pointer',
                                                        fontWeight: 600,
                                                        color: (() => {
                                                            let cor = '#fff';
                                                            if (
                                                                opcao.id != pergunta.resposta &&
                                                                opcao.id != pergunta.idOpcaoGabarito
                                                            )
                                                                return cor;
                                                            if (opcao.id === pergunta.resposta) {
                                                                cor = '#000';
                                                            }

                                                            if (vendoGabarito) {
                                                                cor = '#fff';
                                                            }
                                                            return cor;
                                                        })(),
                                                    }}
                                                >
                                                    {`${_alfabeto[indexOpcao].toLocaleUpperCase()} - ${opcao.pergunta}`}
                                                </label>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                                {vendoGabarito &&
                                                    opcao.id === pergunta.resposta &&
                                                    pergunta.resposta === pergunta.idOpcaoGabarito && (
                                                        <span
                                                            style={{
                                                                marginTop: '10px',
                                                                color: '#000',
                                                                fontWeight: 600,
                                                                fontSize: '12px',
                                                            }}
                                                        >
                                                            Parabéns, voce acertou!
                                                        </span>
                                                    )}

                                                {vendoGabarito &&
                                                    opcao.id === pergunta.resposta &&
                                                    pergunta.resposta !== pergunta.idOpcaoGabarito && (
                                                        <span
                                                            style={{
                                                                marginTop: '10px',
                                                                color: '#000',
                                                                fontWeight: 600,
                                                                fontSize: '12px',
                                                            }}
                                                        >
                                                            Resposta errada, veja o video explicando o porque.
                                                        </span>
                                                    )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </ContainerOpcoes>

                            {vendoGabarito &&
                                (vendoVideo ? (
                                    <div id={'containerVideoComponent'} style={{ flex: 1 }}>
                                        <VideoPlayer
                                            pauseAmbiente={pauseAmbiente}
                                            idVideo={pergunta.urlVideoGabarito.replace('https://watch.videodelivery.net/', '')}
                                        />
                                    </div>
                                ) : (
                                    <div style={{ flex: 1 }}>
                                        <img src={stiker} style={{ height: '100px' }} />
                                        <h1>
                                            {pergunta.resposta != pergunta.idOpcaoGabarito
                                                ? `😔 Resposta Incorreta`
                                                : `🥳 Resposta Correta`}
                                        </h1>

                                        <button
                                            style={{
                                                padding: '16px',
                                                border: 'none',
                                                borderRadius: '8px',
                                                color: '#fff',
                                                background: '#1a8616',
                                                fontSize: '16px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => setVendoVideo(true)}
                                        >
                                            Ver Gabarito
                                        </button>
                                    </div>
                                ))}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                            <button
                                style={styleBtn}
                                onClick={() => {
                                    if (vendoGabarito) return setVendoGabarito(false);
                                    // if(Array.isArray(aula.perguntas) && aula.perguntas.length > 1){

                                    // }
                                }}
                            >
                                Voltar
                            </button>
                            {vendoGabarito ? (
                                <button
                                    style={{ ...styleBtn, background: '#1a8616' }}
                                    onClick={() => {
                                        if (indexPerguntaVendo == aula.perguntas.length - 1) {
                                            setVendoGabarito(false);
                                            closePopUpAulaFase();
                                        }
                                        setVendoVideo(false);
                                        setIndexPerguntaVendo((old) => old + 1);
                                        setVendoGabarito(false);
                                    }}
                                >
                                    {indexPerguntaVendo == aula.perguntas.length - 1 ? 'Fechar' : 'Proximo Exercício'}
                                </button>
                            ) : (
                                <button
                                    title={
                                        pergunta.resposta != null
                                            ? 'Clique para verificar sua resposta e ver o gabarito!'
                                            : 'Selecione uma das opções a cima para verificar sua resposta.'
                                    }
                                    style={{
                                        ...styleBtn,
                                        background: pergunta.resposta != null ? '#1a8616' : '#cecece',
                                        cursor: pergunta.resposta != null ? 'pointer' : 'not-allowed',
                                    }}
                                    onClick={() => handleVerGabarito()}
                                >
                                    Verificar Resposta
                                </button>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </Container>
    );
}

export default PopUpPerguntasFase;

export const ContainerOpcoes = styled.div`
    width: ${(props) => (props.vendoGabarito ? '50%' : '100%')};
    height: 100%;
    flex: 1;
`;

const styleBtn = {
    padding: '10px 20px',
    background: '#fd2b2b',
    border: 'none',
    borderRadius: '8px',
    color: '#fff',
    fontWeight: '400',
    fontSize: '16px',
    letterSpacing: '1px',
    cursor: 'pointer',
};
