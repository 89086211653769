import { lighten } from 'polished';
import styled from 'styled-components';
import background from '../../../game/assets/bkgDefault.JPG';
export const Container = styled.div`
    display: flex;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    background-color: #000;
    overflow: hidden auto;
    /* background: ${(p) => p.theme.colors.background}; */
    ::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(${background});
        filter: opacity(0.1) blur(10px);
    }
    color: #fefefe;
    position: relative;
`;

export const Header = styled.div`
    flex: 1;
    display: flex;
    z-index: 10;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    max-height: 80px;
    background: ${(p) => lighten(0.03, p.theme.colors.background)};
    box-shadow: 0px -1px 8px 0px rgb(0 0 0 / 75%);
`;

export const TitleJornada = styled.h1`
    font-size: 30px;
    margin: 0px;
`;

export const DescricaoJornada = styled.div``;

export const ContainerPontos = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    > div {
        width: 80px;
        display: flex;
        align-items: center;
        margin: 0px 10px;
        padding: 0px 4px;
        background-color: #cfcdcd8a;
        border-radius: 10px;
        cursor: pointer;
        transition-duration: 0.3s;

        :hover {
            transform: scale(1.1);
            box-shadow: 0px 3px 5px 0px rgb(0 0 0 / 75%);
        }

        > span {
            color: ${(p) => p.theme.colors.background};
            font-weight: bold;
            font-size: 14px;
        }

        > img {
            width: 25px;
            margin-right: 10px;
        }
    }
`;

export const Body = styled.div`
    scroll-behavior: smooth;
    flex: 1;
    overflow: hidden auto;
    display: flex;
    justify-content: center;
    z-index: 1;
    > div {
        max-width: 1200px;
    }
`;
export const CardAulaBody = styled.div`
    display: flex;
    border-radius: 6px;
    flex-direction: column;
    padding: 10px;
    margin: 30px 0px;
    background-color: #202124;
    transition-duration: 0.3s;
    ${(p) => (p.ativo ? '' : 'filter: brightness(0.6);')}
    > h2 {
        font-size: 20px;
        margin: 0px;
        margin-bottom: 10px;
        line-height: 22px;
    }

    > span {
        font-size: 14px;
        color: #b7b7b7;
        font-weight: 500;
    }
`;

export const ContainerArrowBack = styled.div`
    position: absolute;
    top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0px 10px;
    z-index: 20;

    > div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 7px;
        border-radius: 50%;
        background-color: #ffffff9e;
        cursor: pointer;
        transition-duration: 0.3s;
    }

    > div:hover {
        transform: scale(1.1);
    }

    > div > svg {
        font-size: 20px;
    }
`;

export const IconeProfessorPergunta = styled.div`
    position: absolute;
    bottom: 20px;
    right: 30px;
    width: 80px;
    height: 80px;
    transition-duration: 0.3s;
    cursor: pointer;
    border: 3px solid #1463a6;
    border-radius: 50%;
    z-index: 10;
    img {
        height: 100%;
    }
`;

export const MenuDuvida = styled.div`
    position: absolute;
    top: -90px;
    background: ${(p) => p.theme.colors.background};
    color: #fff;
    box-shadow: 0px 0px 5px 0px rgb(0 0 0);
    left: -110px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 10px;

    > div {
        display: flex;
        justify-content: flex-start;
        justify-items: center;
        gap: 10px;
        transition-duration: 0.2s;
        cursor: pointer;
        :hover {
            color: #1463a6;
        }
    }
`;
