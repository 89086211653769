import { makeAutoObservable } from 'mobx';
import anime from 'animejs';
import api from '../service/api';

function amimacao(elemento, mensagem) {
    try {
        const { top, left, width, height } = document.getElementById(elemento).getBoundingClientRect();
        const meioX = window.innerWidth / 2 - left - width / 2;
        const meioY = window.innerHeight / 2 - top - height / 2;
        const containerTextoParabens = document.getElementById('containerTextoParabens');

        const textSaudacao = document.getElementById('textoParabens');
        textSaudacao.innerText = mensagem;

        anime({
            targets: '#' + elemento,
            translateX: [
                { value: meioX, duration: 10, delay: 0 },
                { value: 0, duration: 2000, delay: 1000 },
            ],
            translateY: [
                { value: meioY, duration: 10, delay: 0 },
                { value: 0, duration: 2000, delay: 1000 },
            ],
            scale: [
                { value: 3, duration: 10, delay: 0 },
                { value: 1, duration: 2000, delay: 1000 },
            ],
            easing: 'easeOutElastic(1, .8)',
            begin: function (anim) {
                containerTextoParabens.style.display = 'flex';
            },
            complete: function (anim) {
                containerTextoParabens.style.display = 'none';
            },
        });
    } catch (error) {
        console.log('erro ao tentar fazer animacao');
        console.error(error);
    }
}
class ScorePlayer {
    diamantes = [];
    moedas = [];

    constructor() {
        makeAutoObservable(this);
    }

    increaseMoedas(saco) {
        if (saco) {
            const existeSaco = this.moedas.find((minhasMoedas) => minhasMoedas.id == saco.id);
            if (!existeSaco) this.moedas.push(saco);
        }
        amimacao(
            'moedaImgScore',
            `Parabéns você ganhou ${saco.quantidadeMoedas} ${saco.quantidadeMoedas > 1 ? 'moedas.' : 'moeda.'}!`,
        );
    }

    increaseDiamantes(diamante) {
        if (diamante) {
            const existeDiamante = this.diamantes.find((meusDiamantes) => meusDiamantes.id == diamante.id);
            if (!existeDiamante) this.diamantes.push(diamante);
        }
        amimacao('diamanteImgScore', `Parabéns você ganhou: "${diamante.nome}"`);
    }

    async atualizaSaldo() {
        try {
            const { data } = await api.get('Aluno/me/saldo');

            if (data?.premioAulas.length > 0) this.diamantes = data?.premioAulas;

            if (data?.sacoMoedas.length > 0) this.moedas = data?.sacoMoedas;
            console.log('saldo sendo atualizado=', data);
        } catch (error) {
            console.error(error);
        }
    }
}

export default new ScorePlayer();
