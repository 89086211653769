import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Button from '@mui/material/Button';
import { useEffect, useMemo, useState } from 'react';
import Lottie from 'react-lottie';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

import threeDotLoading from '../../assets/lotties/3-dots-loading.json';
import ToastGame from '../../components/toastGame/ToastGame';
import api from '../../service/api';

import CardJornadaResumo from '../../components/cardJornadaResumo/CardJornadaResumo';
import JornadaResume from '../../components/jornadaResume/JornadaResume';
import valorParaString from '../../utils/valorParaString';
import {
    CardInfosAutorJornda,
    CardInfosJornada,
    Container,
    ContainerBody,
    ContainerBodyPrimeiro,
    ContainerInfosInit,
    ContainerRightBody,
    Cover,
} from './CursoStyles';

export default function Curso() {
    const [searchParams, setSearchParams] = useSearchParams();
    let { cupom, lead } = Object.fromEntries([...searchParams]);

    const params = useParams();
    const alias = params.alias;
    const leadAliasParam = params.leadAlias;

    const navigate = useNavigate();
    const [cupomInfo, setCupomInfo] = useState();
    const [dadosCurso, setDadosCurso] = useState();
    const [jornadaSelecionadaPopUp, setJornadaSelecionadaPopUp] = useState(null);

    const autor = useMemo(() => (dadosCurso?.autor ? dadosCurso.autor : null), [dadosCurso]);
    const jornadas = useMemo(() => (dadosCurso?.jornadas ? dadosCurso.jornadas : null), [dadosCurso]);

    const parametrosNavegate = useMemo(() => ({
        replace: false,
        state: dadosCurso,
    }));

    useEffect(() => {
        if (params.alias) {
            carregaDadosCurso(params.alias);
            carregaCumpomInfo();
        } else {
            navigate('/', { replace: true });
            toast.warning('Jornada não encontrada.');
        }
    }, [params]);

    const valorCalculado = useMemo(() => {
        if (!dadosCurso) return 0;
        if (!cupomInfo) {
            return dadosCurso.valor;
        } else {
            return dadosCurso.valor - (dadosCurso.valor * parseInt(cupomInfo.percentual)) / 100;
        }
    }, [cupomInfo, dadosCurso]);

    const valorParcelado = valorCalculado ? valorCalculado / 12 : 0;

    async function carregaCumpomInfo() {
        try {
            const paramsQuery = Object.fromEntries([...searchParams]);
            const { cupom } = paramsQuery;
            if (!cupom) return;
            const { data } = await api.get(`/stone/GetCupom?nomecupom=${cupom}`);
            console.log(data);
            setCupomInfo(data);
        } catch (error) {
            console.error(error);
            toast.error(<ToastGame text={'Erro'} title={'Erro ao carregar info do cupom.'} />);
        }
    }

    async function carregaDadosCurso() {
        try {
            const paramsQuery = Object.fromEntries([...searchParams]);
            const { data: responseDadosCurso } = await api.post(`curso/alias/${alias}`);
            setDadosCurso(responseDadosCurso);
        } catch (error) {
            toast.error('Erro ao carregar informações do curso.');
        }
    }

    function irComprar() {
        if (leadAliasParam) {
            navigate(`/pagamento/${alias}/${leadAliasParam}${cupom ? `?cupom=${cupom}` : ''}`, parametrosNavegate);
        } else {
            navigate(`/pagamento?alias=${alias}&lead=${lead}${cupom ? `&cupom=${cupom}` : ''}`, parametrosNavegate);
        }
    }

    if (!dadosCurso)
        return (
            <div>
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: threeDotLoading,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                        },
                    }}
                    style={{ width: '300px', height: '100px' }}
                />
                <h2>Carregando informações do curso...</h2>
            </div>
        );

    return (
        <Container>
            <Cover imgBackground={dadosCurso.urlImagem} />
            <ContainerInfosInit>
                <h1>{dadosCurso.nome}</h1>
            </ContainerInfosInit>
            <ContainerBody>
                <ContainerBodyPrimeiro>
                    <div style={{ flex: 1, paddingRight: '15px' }}>
                        <span
                            style={{
                                display: 'block',
                                textAlign: 'left',
                                whiteSpace: 'pre-line',
                                fontWeight: 600,
                                color: '#fefefe',
                            }}
                        >
                            {dadosCurso.descricao}
                        </span>
                    </div>
                    <ContainerRightBody>
                        <CardInfosJornada>
                            {dadosCurso.valor ? (
                                cupomInfo ? (
                                    <span style={{ textAlign: 'center' }}>
                                        De{' '}
                                        <span style={{ textDecoration: 'line-through' }}>
                                            {valorParaString(dadosCurso.valor)}
                                        </span>
                                        por {valorParaString(valorParcelado)} em até
                                        <span style={{ fontWeight: 600, color: '#22d12a' }}>
                                            12x de {valorParaString(valorParcelado)}
                                        </span>{' '}
                                        no cartão de crédito <span style={{ fontWeight: 600 }}>SEM JUROS</span>
                                    </span>
                                ) : (
                                    <span style={{ textAlign: 'center' }}>
                                        <span style={{ fontWeight: 600, color: '#22d12a' }}>
                                            12x de {valorParaString(valorParcelado)}
                                        </span>{' '}
                                        no cartão de crédito <span style={{ fontWeight: 600 }}>SEM JUROS</span>
                                    </span>
                                )
                            ) : (
                                <span style={{ textAlign: 'center', fontWeight: 600, color: '#22d12a' }}>Curos Gratuito</span>
                            )}
                            <Button variant="contained" sx={{ background: '#1a851f' }} color="success" onClick={irComprar}>
                                Assinar Curso
                            </Button>
                        </CardInfosJornada>

                        {autor && (
                            <CardInfosAutorJornda>
                                <div>
                                    <img src={autor.foto} />
                                    <div>
                                        <span>{autor.nome}</span>
                                        <Button startIcon={<AlternateEmailIcon />} href={`mailto:${autor?.email}`}>
                                            Enviar Email
                                        </Button>
                                    </div>
                                </div>
                                <span>{autor.descricaoAcademica}</span>
                            </CardInfosAutorJornda>
                        )}
                    </ContainerRightBody>
                </ContainerBodyPrimeiro>

                {jornadas && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            padding: '15px',
                            flex: 1,
                            gap: '20px',
                        }}
                    >
                        <h1>Jornadas do Curso</h1>
                        {jornadas.map((jornada, index) => (
                            <CardJornadaResumo
                                key={`CardJornada-${jornada.id}`}
                                jornada={jornada}
                                indexJornada={index}
                                setJornadaSelecionada={setJornadaSelecionadaPopUp}
                            />
                        ))}
                    </div>
                )}
                <Dialog
                    onClose={() => setJornadaSelecionadaPopUp(null)}
                    aria-labelledby="customized-dialog-title"
                    open={!!jornadaSelecionadaPopUp}
                >
                    <DialogTitle sx={{ m: 0, p: 2 }}>
                        {jornadaSelecionadaPopUp?.nome}
                        <IconButton
                            aria-label="close"
                            onClick={() => setJornadaSelecionadaPopUp(null)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <JornadaResume jornada={jornadaSelecionadaPopUp} />
                    </DialogContent>
                </Dialog>
            </ContainerBody>
        </Container>
    );
}
