import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-rows: auto;
    padding: 0;
    position: relative;
    /* background: url(${(props) => props.imgBackground}) bottom center no-repeat; */
    background: ${p=>p.theme.colors.background};

    > h2 {
        font-size: 52px;
        text-align: center;
        z-index: 2;
        margin-bottom: 20px;
        color:${p => p.theme.colors.text};
    }

    @media (max-width: 767px) {
        /* width: 100vw; */
        margin: 0;
        /* padding: 100px 50px; */
        display: flex;
        flex-direction: column;

        > h2 {
            font-size: 42px;
        }
    }
`;

export const SpanObservacao = styled.span`
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
    color:${p => p.theme.colors.text}
`;

export const ContainerProgramacao = styled.div`
    width: 80vw;
    display: grid;
    grid-template-rows: auto;
    margin: auto;
    padding: 0px 100px;
    @media (max-width: 800px) {
        width: 95vw;
        padding: 0px 0px;
        display: flex;
        flex-direction: column;
    }
`;
export const ContainerOpcoesDias = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
    align-items: flex-start;
    margin-bottom: 40px;
   
    @media (max-width: 800px) {
        flex-direction: column;
        align-items: center;
    }
`;
export const OpcaoDia = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.44);
    border: ${(props) => (props.selected ? '4px solid var(--secundaryColor);' : '4px solid #fff;')};
    padding: 10px 15px;
    cursor: pointer;
    transition-duration: 0.3s;
    position: relative;
    background:${p=>p.theme.colors.text};
    ::before {
        @media (max-width: 800px) {
            display: none;
        }
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid var(--secundaryColor);
        top: 71px;
        /* left: 22%; */
        visibility: ${(props) => (props.selected ? 'visible' : 'hidden')};
    }

    :hover {
        transform: scale(1.1);
    }

    > span:first-child {
        font-size: 22px;
        color: var(--secundaryColor);
        font-weight: 600;
    }

    > span:nth-child(2) {
        font-size: 18px;
        font-weight: 100;
    }
`;

export const ContainerAulas = styled.div`
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 4px 10px rgb(0 0 0 / 25%);
    display: flex;
    flex-direction: column;
    overflow:hidden;
    border-radius:8px;
    > div {
        background-color: #ffffffd4;
    }

    > div:nth-of-type(even) {
        background-color: #f1f1f1d4;
    }

    @media (max-width: 800px) {
        > div:nth-of-type(even) {
            background-color: #e8e8e8d4;
        }
    }
`;

export const Aula = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 50px;
    padding: 20px;

    ${(props) => (props.intervalo ? 'background-color: #ffe3e3eb!important; ' : '')}

    >div:nth-child(1) {
        font-family: Oswald, sans-serif;
        /* padding: 15px; */
        font-weight: 800;
        font-size: 30px;
        color: #575266;
    }

    > div:nth-child(2) {
        display: flex;
        align-items: center;

        > img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            overflow: hidden;
        }

        > span {
            padding-left: 40px;
            text-transform: uppercase;
            font-weight: 600;
            color: #34303e;
            width: 250px;
            overflow: hidden;
        }
    }

    > div:nth-child(3) {
        /* padding: 0px 15px; */

        > h3 {
            font-size: 25px;
            font-weight: 700;
            color: #34303e;
            margin-bottom: 10px;
            line-break: auto;
        }

        > span {
            font-family: serif;
            font-size: 18px;
            line-break: auto;
        }
    }

    @media (max-width: 800px) {
        flex-direction: column;

        > div:nth-child(2) {
            > span {
                padding-left: 10px;
                width: 130px;
                text-align: center;
            }
            /* > img {
                width: 1px;
                height: 1px;
            } */
        }

        > div:nth-child(3) {
            text-align: center;
            width: 100%;
            line-break: anywhere;
        }
    }
`;
