import { makeAutoObservable } from 'mobx';
import api from '../service/api';

class gameInitStore {
    constructor() {
        makeAutoObservable(this);
    }

    jornadaSelecionada = null;
    aluno = null;
    logout = null;
    jornadaCompleta = null;
    jornadasDoAluno = null;
    
    setAluno(data) {
        if (!data) return false;
        this.aluno = data;
        sessionStorage.setItem('@gammyx-aluno', JSON.stringify(data));
        if (data.urlAzureAvatar) sessionStorage.setItem('newSprit', data.urlAzureAvatar);
        return true;
    }

    setJornadaSelecionada(data) {
        if (!data) return false;
        this.jornadaSelecionada = data;
        return true;
    }
    setJornadaCompletaSelecionada(data) {
        if (!data) return false;
        this.jornadaCompleta = data;
        return true;
    }
    setJornadasAluno(data){
        if(!data)
            return false;
        this.jornadasDoAluno = data;
        return true;
    }

    setLogout(func) {
        this.logout = func;
    }

    removeAlunoAndLogout() {
        this.aluno = null;
        if (typeof this.logout === 'function') {
            this.logout();
        }
    }

    async salvaAvatar(arquivo) {
        try {
            const url = await api.post(`Aluno/saveAvatar`, arquivo);
            const aluno = await this.getAluno();
            return aluno;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    async getAluno() {
        try {
            const { data: aluno } = await api.get('Aluno/me');
            this.setAluno(aluno);
            return aluno;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    async getJornada(idJornada) {
        try {
            
            const responseRequestGetJornada = await api.get(`Jornada/jornadaid?jornadaParam=${idJornada}`);
            if(responseRequestGetJornada.isAxiosError){
                if(responseRequestGetJornada.response.status == 404 || responseRequestGetJornada.response.status == 400){
                   return responseRequestGetJornada.response.status;
                }
            }
            if(responseRequestGetJornada.data){
                this.setJornadaSelecionada(responseRequestGetJornada.data);
                return responseRequestGetJornada.data;
            }
            throw `Erro na chamada.`
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    async getJornadaCompleta(idJornada) {
        try {
            const { data: jornada } = await api.get(`Jornada/play/${idJornada}`);
            this.setJornadaCompletaSelecionada(jornada);
            return jornada;
        } catch (error) {
            console.error(error);
            return false;
        }
    }
}

export default new gameInitStore();
