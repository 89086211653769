import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Button from '@mui/material/Button';
import { useEffect, useMemo, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import logo from '../../assets/login/logo.png';

import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

import threeDotLoading from '../../assets/lotties/3-dots-loading.json';
import ToastGame from '../../components/toastGame/ToastGame';
import api from '../../service/api';

import { Box, TextField, Typography, useTheme } from '@mui/material';
import imageBandeiras from '../../assets/bandeiras.png';
import imageNotFound from '../../assets/image-not-found.jpg';
import JornadaResume from '../../components/jornadaResume/JornadaResume';
import Promocao from '../../components/promocao/Promocao';
import constantes from '../../utils/constantes';
import valorParaString from '../../utils/valorParaString';
import {
    CardInfosAutorJornda,
    CardInfosJornada,
    Container,
    ContainerBody,
    ContainerBodyPrimeiro,
    ContainerInfosInit,
    ContainerJornadasAulas,
    ContainerRightBody,
    Cover,
} from './landPageCursoStyles';
export default function LandPageCurso() {
    const [searchParams, setSearchParams] = useSearchParams();
    const refNameForm = useRef();
    const refForm = useRef();
    let { cupom, lead } = Object.fromEntries([...searchParams]);
    const theme = useTheme();
    const params = useParams();
    const alias = params.alias;
    const leadAliasParam = params.leadAlias;
    const [dadosForm, setDadosForm] = useState({ nome: '', email: '', celular: '' });
    const iframeBanner = useRef();
    const [iframeBannerPlayer, setIframeBannerPlayer] = useState(false);

    const navigate = useNavigate();
    const [cupomInfo, setCupomInfo] = useState();
    const [dadosCurso, setDadosCurso] = useState();
    const [jornadaSelecionadaPopUp, setJornadaSelecionadaPopUp] = useState(null);

    const autor = useMemo(() => (dadosCurso?.autor ? dadosCurso.autor : null), [dadosCurso]);
    const jornadas = useMemo(() => (dadosCurso?.jornadas ? dadosCurso.jornadas : null), [dadosCurso]);

    const parametrosNavegate = useMemo(() => ({
        replace: false,
        state: dadosCurso,
    }));

    useEffect(() => {
        const elementoAjudaBlip = document.getElementById('blip-chat-open-iframe');
        elementoAjudaBlip?.classList?.add('sobeAjudaBlip');

        return () => {
            const elementoAjudaBlip = document.getElementById('blip-chat-open-iframe');
            elementoAjudaBlip?.classList?.remove('sobeAjudaBlip');
        };
    }, []);

    useEffect(() => {
        if (params.alias) {
            carregaDadosCurso(params.alias);
            carregaCumpomInfo();
        } else {
            navigate('/', { replace: true });
            toast.warning('Jornada não encontrada.');
        }
    }, [params]);

    const valorCalculado = useMemo(() => {
        if (!dadosCurso) return 0;
        if (!cupomInfo) {
            return dadosCurso.valor;
        } else {
            return dadosCurso.valor - (dadosCurso.valor * parseInt(cupomInfo.percentual)) / 100;
        }
    }, [cupomInfo, dadosCurso]);

    const valorParcelado = valorCalculado ? valorCalculado / 12 : 0;

    async function carregaCumpomInfo() {
        try {
            const paramsQuery = Object.fromEntries([...searchParams]);
            const { cupom } = paramsQuery;
            if (!cupom) return;
            const { data } = await api.get(`/stone/GetCupom?nomecupom=${cupom}`);
            console.log(data);
            setCupomInfo(data);
        } catch (error) {
            console.error(error);
            toast.error(<ToastGame text={'Erro'} title={'Erro ao carregar info do cupom.'} />);
        }
    }

    async function carregaDadosCurso() {
        try {
            const paramsQuery = Object.fromEntries([...searchParams]);
            const { data: responseDadosCurso } = await api.post(`curso/alias/${alias}`);
            setDadosCurso(responseDadosCurso);
        } catch (error) {
            toast.error('Erro ao carregar informações do curso.');
        }
    }

    async function irComprar(nome, email, celular) {
        if (!nome || !email || !celular) {
            return toast.error(
                <ToastGame text={'Campos Obrigatórios'} title={'Todos os campos do formulário são obrigatórios!.'} />,
            );
        }

        try {
            await api.post(`/venda/salvaRdStation?nome=${nome}&email=${email}&celular=${celular}&aliasCurso=${alias}`);
        } catch (error) {
            console.log(error);
        } finally {
            if (leadAliasParam) {
                navigate(
                    `/pagamento/${alias}/${leadAliasParam}?nome=${nome}&celular=${celular}&email=${email}${
                        cupom ? `&cupom=${cupom}` : ''
                    }`,
                    parametrosNavegate,
                );
            } else {
                navigate(
                    `/pagamento?alias=${alias}&lead=${lead}${
                        cupom ? `&cupom=${cupom}` : ''
                    }&nome=${nome}&email=${email}&celular=${celular}`,
                    parametrosNavegate,
                );
            }
        }
    }

    const promocaoVilida = useMemo(() => {
        return dadosCurso?.promocao_dtIncio &&
            new Date() > new Date(dadosCurso.promocao_dtIncio) &&
            new Date() < new Date(dadosCurso.promocao_dtFim)
            ? true
            : false;
    }, [dadosCurso]);

    if (!dadosCurso)
        return (
            <div>
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: threeDotLoading,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                        },
                    }}
                    style={{ width: '300px', height: '100px' }}
                />
                <h2>Carregando informações do curso...</h2>
            </div>
        );

    return (
        <Container>
            <Box
                bgcolor={'#000'}
                display={'flex'}
                justifyContent={'center'}
                boxShadow={'0px 0px 34px 36px #000000'}
                paddingTop={'15px'}
                zIndex={'1'}
            >
                <img src={constantes.logo} onError={(e) => (e.target.src = logo)} height={'60px'} />
            </Box>
            {dadosCurso?.imgPromocional?.includes('youtube.com/embed') ? (
                <iframe
                    ref={iframeBanner}
                    id="iframe"
                    autoPlay
                    style={{ height: '70vh', width: '100%' }}
                    src={dadosCurso.imgPromocional}
                    title="YouTube video player"
                    frameBorder="0"
                    allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                ></iframe>
            ) : (
                <Cover
                    imgBackground={dadosCurso.imgPromocional || dadosCurso.urlImagem}
                    imagemVertical={dadosCurso.urlImagemVertical}
                />
            )}
            <ContainerInfosInit
                onClick={() => {
                    if (dadosCurso?.imgPromocional?.includes('youtube.com/embed')) {
                        iframeBannerPlayer
                            ? (iframeBanner.current.src = iframeBanner.current.src.replace('&autoplay=1', ''))
                            : (iframeBanner.current.src += '&autoplay=1');

                        setIframeBannerPlayer((old) => !old);
                    }
                }}
            >
                <h1>{dadosCurso.nome}</h1>
            </ContainerInfosInit>
            <ContainerBody>
                <ContainerBodyPrimeiro>
                    <div style={{ flex: 1, paddingRight: '15px' }}>
                        <span
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                textAlign: 'left',
                                whiteSpace: 'pre-line',
                                fontWeight: 600,
                                color: '#fefefe',
                            }}
                            dangerouslySetInnerHTML={{ __html: dadosCurso.descricao }}
                        ></span>
                    </div>
                    <ContainerRightBody>
                        <CardInfosJornadaComponent
                            refForm={refForm}
                            refNameForm={refNameForm}
                            setDadosForm={setDadosForm}
                            dadosCurso={dadosCurso}
                            dadosForm={dadosForm}
                            valorParcelado={valorParcelado}
                            valorParaString={valorParaString}
                            irComprar={irComprar}
                            cupomInfo={cupomInfo}
                            valorCalculado={valorCalculado}
                        />

                        {autor && (
                            <CardInfosAutorJornda>
                                <div>
                                    <img src={autor.foto} />
                                    <div>
                                        <span>{autor.nome}</span>
                                        <Button startIcon={<AlternateEmailIcon />} href={`mailto:${autor?.email}`}>
                                            Enviar Email
                                        </Button>
                                    </div>
                                </div>
                                <span dangerouslySetInnerHTML={{ __html: autor.descricaoAcademica }}></span>
                            </CardInfosAutorJornda>
                        )}
                    </ContainerRightBody>
                </ContainerBodyPrimeiro>

                {jornadas && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            padding: '15px',
                            flex: 1,
                            gap: '20px',
                        }}
                    >
                        {jornadas.length > 1 ? (
                            <ContainerJornadasAulas>
                                <h1>Jornadas do Curso</h1>
                                {jornadas.map((jornada, index) => (
                                    <>
                                        <Box
                                            display={'flex'}
                                            flexDirection={'column'}
                                            bgcolor={'#0c0c0c'}
                                            padding={'10px'}
                                            borderRadius={'6px'}
                                        >
                                            <Typography
                                                variant="h4"
                                                component="span"
                                                marginBottom={'15px'}
                                                color={theme.palette.primary.dark}
                                            >
                                                {jornada.nome}
                                            </Typography>
                                            <Box display={'flex'} flexDirection={'column'} padding={'0px 15px'}>
                                                <Typography variant="h6" component="span" marginBottom={'15px'}>
                                                    Descrição da jornada
                                                </Typography>
                                                <Typography
                                                    variant="subtitle1"
                                                    component="span"
                                                    marginBottom={'15px'}
                                                    color={theme.palette.text.secondary}
                                                >
                                                    {jornada.descricaoResumo}
                                                </Typography>
                                                <Typography variant="h6" component="span">
                                                    Aulas da Jornada
                                                </Typography>
                                                <ListaAulas aulas={jornada.aulas} />
                                            </Box>
                                        </Box>
                                        <Box
                                            display={'flex'}
                                            padding={'50px 0px'}
                                            justifyContent={'center'}
                                            alignItems={'center'}
                                        >
                                            <CardInfosJornadaComponent
                                                refForm={refForm}
                                                refNameForm={refNameForm}
                                                setDadosForm={setDadosForm}
                                                dadosCurso={dadosCurso}
                                                dadosForm={dadosForm}
                                                valorParcelado={valorParcelado}
                                                valorParaString={valorParaString}
                                                irComprar={irComprar}
                                                cupomInfo={cupomInfo}
                                                valorCalculado={valorCalculado}
                                                absolute={false}
                                            />
                                        </Box>
                                    </>
                                ))}
                            </ContainerJornadasAulas>
                        ) : (
                            <ContainerJornadasAulas>
                                <h1>Aulas</h1>
                                <ListaAulas aulas={jornadas[0].aulas} />
                            </ContainerJornadasAulas>
                        )}
                    </div>
                )}
                <Dialog
                    onClose={() => setJornadaSelecionadaPopUp(null)}
                    aria-labelledby="customized-dialog-title"
                    open={!!jornadaSelecionadaPopUp}
                >
                    <DialogTitle sx={{ m: 0, p: 2 }}>
                        {jornadaSelecionadaPopUp?.nome}
                        <IconButton
                            aria-label="close"
                            onClick={() => setJornadaSelecionadaPopUp(null)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <JornadaResume jornada={jornadaSelecionadaPopUp} />
                    </DialogContent>
                </Dialog>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        width: '100%',
                        padding: '12px 0px',
                        marginTop: '15px',
                        alignItems: 'center',
                        paddingBottom: '102px',
                    }}
                >
                    <div>
                        {/* <span >Power By: </span> */}
                        <a href="https://info.medx.med.br/" target="_blank">
                            <img style={{ height: '40px' }} src={constantes.logo} onError={(e) => (e.target.src = logo)} />
                        </a>
                    </div>
                    <span style={{ fontSize: '12px' }}>Curso online utilizando tecnologia Gammyx.</span>

                    <span style={{ fontSize: '12px' }}>MEDX Tecnologia S/A. ®️ {new Date().getFullYear()}</span>
                </div>
            </ContainerBody>
            {promocaoVilida && (
                <Promocao
                    dadosCurso={dadosCurso}
                    acaoBtn={() => {
                        refNameForm.current.focus();
                        refForm.current.scrollIntoView();
                        toast.info(<ToastGame text={'Preencha o formulario.'} title={''} />);
                    }}
                />
            )}
        </Container>
    );
}

function ListaAulas({ aulas }) {
    const theme = useTheme();
    console.log(aulas);
    return (
        <ul className="listaAulas" style={{ paddingLeft: 0, fontWeight: 600, maxWidth: '1200px', margin: 'auto' }}>
            {aulas
                .filter((aula) => aula.tipo === 1)
                .sort((a, b) => a.ordem - b.ordem)
                .map((aula, index) => (
                    <li key={`aulaListJornadaResume-${aula.id}`}>
                        <div className="tambVideo">
                            <img
                                src={aula?.urlTumb || imageNotFound}
                                onError={(e) => (e.target.src = imageNotFound)}
                                style={{ width: '100%', objectFit: 'cover' }}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <Typography variant="h6" component="span" color={theme.palette.primary.dark}>
                                {aula.titulo}
                            </Typography>
                            <Typography variant="subtitle2" color={theme.palette.text.secondary} component="span">
                                {aula.descricao}
                            </Typography>
                        </div>
                    </li>
                ))}
        </ul>
    );
}

function CardInfosJornadaComponent({
    refForm,
    refNameForm,
    setDadosForm,
    dadosCurso,
    valorParaString,
    valorParcelado,
    dadosForm,
    cupomInfo,
    irComprar,
    valorCalculado,
    absolute = true,
}) {
    return (
        <CardInfosJornada absolute={absolute}>
            <form ref={refForm}>
                <Typography variant="h6" textAlign={'center'} fontWeight={600}>
                    Comprar
                </Typography>
                <TextField
                    inputRef={refNameForm}
                    value={dadosForm.nome}
                    onChange={(e) => setDadosForm((old) => ({ ...old, nome: e.target.value }))}
                    size="small"
                    id="cadastroPrevio-nome"
                    label="Nome"
                    variant="outlined"
                    fullWidth
                />
                <TextField
                    value={dadosForm.email}
                    onChange={(e) => setDadosForm((old) => ({ ...old, email: e.target.value }))}
                    size="small"
                    id="cadastroPrevio-email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                />
                <TextField
                    value={dadosForm.celular}
                    onChange={(e) => setDadosForm((old) => ({ ...old, celular: e.target.value }))}
                    size="small"
                    id="cadastroPrevio-celular"
                    label="Celular"
                    variant="outlined"
                    fullWidth
                />
            </form>
            {dadosCurso.valor ? (
                cupomInfo ? (
                    <span style={{ textAlign: 'center' }}>
                        De{' '}
                        <span style={{ textDecoration: 'line-through', color: '#db4a4a' }}>
                            {valorParaString(dadosCurso.valor)}
                        </span>{' '}
                        {' por '}
                        <span style={{ fontWeight: 600, color: '#22d12a' }}>{valorParaString(valorCalculado)}</span> <br />
                        {' Em até '}
                        <span style={{ fontWeight: 600, color: '#22d12a' }}>12x de {valorParaString(valorParcelado)}</span> no
                        cartão de crédito <span style={{ fontWeight: 600 }}>SEM JUROS</span>
                    </span>
                ) : (
                    <span style={{ textAlign: 'center' }}>
                        <span style={{ fontWeight: 600, color: '#22d12a' }}>12x de {valorParaString(valorParcelado)}</span> no
                        cartão de crédito <span style={{ fontWeight: 600 }}>SEM JUROS</span>
                    </span>
                )
            ) : (
                <span style={{ textAlign: 'center', fontWeight: 600, color: '#22d12a' }}>Curos Gratuito</span>
            )}
            <Button
                variant="contained"
                sx={{ background: 'rgb(14 215 247)', fontWeight: 600 }}
                color="success"
                onClick={() => irComprar(dadosForm.nome, dadosForm.email, dadosForm.celular)}
            >
                {dadosCurso?.promocao_dtIncio &&
                new Date() > new Date(dadosCurso.promocao_dtIncio) &&
                new Date() < new Date(dadosCurso.promocao_dtFim)
                    ? 'Comprar Com Desconto'
                    : 'Comprar Curso'}
            </Button>
            <img style={{ width: '150px', margin: 'auto' }} src={imageBandeiras} />
        </CardInfosJornada>
    );
}
