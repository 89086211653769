import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import PopupMontaPersonagem from '../../../components/popUps/popupMontaPersonagem';
import gameInitStore from '../../../store/gameInitStore';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { Container, ContainerBody, DivSkeleton } from './stylesApresentacao';
import { Container as Container2 } from '../../../components/popUps/popUpStyles';
import { getToken } from '../../../service/auth';
import Compra from '../../../components/compra/compra';
import LogoEvento from '../../../assets/Logo-MEDX-EXPERIENCE.jpg';
import ImgClose from '../../../assets/close.png';
import SchedulePanel from '../../../components/programacao/SchedulePanel';
import { toast } from 'react-toastify';
import PopUpEcommerce from '../../../components/popUps/popUpEcommerce';
import ToastGame from '../../../components/toastGame/ToastGame';
import api from '../../../service/api';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Lottie from 'react-lottie';
import threeDotLoading from '../../../assets/lotties/3-dots-loading.json';
let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

const Apresentacao = observer(() => {
    const defaultOption = {
        loop: true,
        autoplay: true,
        animationData: threeDotLoading,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };
    // const jornadaStore = toJS(gameInitStore.jornadaSelecionada);
    const alunoStore = toJS(gameInitStore.aluno);
    const jornadasAlunoStore = toJS(gameInitStore.jornadasDoAluno);
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const [jornada, setJornada] = useState(null);
    const [listaJornadas, setListaJornadas] = useState([]);
    const [openMakeAvatar, setOpenMakeAvatar] = useState(false);
    const [token, setToken] = useState(null);
    const [jornadaLiberada, setJornadaLiberada] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);

    useEffect(() => {
        if (!params.idJornada) {
            alert('Id da jornda obrigatório');
        } else {
            const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
            if (pattern.test(params.idJornada)) {
                carregarJornada(params.idJornada);
                jornadaIsLiberada(params.idJornada);
                carregaAulasJornada(params.idJornada);
            } else navigate('/*', { replace: true });
        }
    }, [params]);

    useEffect(() => {
        pegaToken();
    }, []);

    function pegaToken() {
        const token = getToken();
        setToken(token);
        // console.log(`Token carregado: ${token}`)
    }
    function abreJogo() {
        try {
            // if (alunoStore.urlAzureAvatar) {
            //     navigate(`/app/gameui/${params.idJornada}`);
            // } else {
            //     setOpenMakeAvatar(true);
            // }
            navigate(`/app/gameui/${params.idJornada}`);
        } catch (error) {
            console.error('error ao abrir jornada.');
        }
    }
    function comprarCurso() {
        try {
            navigate(`/pagamento?alias=${jornada.alias}&lead=7c790ede-02c3-48f7-bcaf-8d135a471617`);
        } catch (error) {
            console.error(error);
            toast.error(<ToastGame title={'Erro ao redirecionar para a tela de compra.'} text={'Erro.'} />);
        }
    }
    async function jornadaIsLiberada() {
        try {
            const response = await gameInitStore.getJornadaCompleta(params.idJornada);
            console.log('aqqqqqq', response);

            if (response) {
                setListaJornadas([response.jornada]);
                setJornadaLiberada(true);
            }
        } catch (error) {
            console.error('Erro ao liberar jornada');
        }
    }
    async function carregarJornada(jorndaId) {
        const jornada = await gameInitStore.getJornada(jorndaId);
        if (jornada) {
            if (jornada == 404) {
                navigate('/*', { replace: true });
            }
            console.log('aqqqqqqiiiiiiiiiiiiiii', jornada);
            setJornada(jornada);
        } else {
            alert('Erro ao tentar carregar jornada');
        }
    }
    async function carregaAulasJornada(jornadaId) {
        try {
            const responseGetAulasJornada = await api.get(`/jornada/${jornadaId}/aulas`);
            console.log(responseGetAulasJornada);
        } catch (error) {
            console.error(error);
            toast.error(<ToastGame title={'Não foi possível carrega aulas da jornada'} text={'Erro'} />);
        }
    }

    return jornada && alunoStore ? (
        <Container>
            {!openMakeAvatar && (
                <ContainerBody className="ContainerBody">
                    <Compra
                        tituloJornada={jornada.nome}
                        acaoBotao={() => (jornadaLiberada ? abreJogo() : comprarCurso())}
                        descricaoPrincipal={jornada.descricao}
                        descricaoAdicional={`Data lançamento: ${new Date(jornada.lancamento).toLocaleString('pt-BR', options)}`}
                        descricaoBotao={jornadaLiberada ? 'Jogar' : 'Comprar'}
                        descricaoDiamante={'Descrição Diamante'}
                        descricaoMoeda={'Descrição Moeda'}
                        imgApresentacao={jornada.urlImagemApresentacao}
                        preco={jornada.preco}
                        aulas={jornada.aulas}
                        sticker={jornada.autor.foto}
                        jornadas={listaJornadas}
                    />
                </ContainerBody>
            )}
            {openMakeAvatar && <PopupMontaPersonagem closePopUp={() => navigate(`/app/gameui/${params.idJornada}`)} />}
        </Container>
    ) : (
        <Container>
            <div
                style={{
                    position: 'absolute',
                    background: '#0000006b',
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Lottie options={defaultOption} style={{ width: '300px', height: '300px' }} />
            </div>
        </Container>
    );
});
export default Apresentacao;
