import React from 'react';
import Lottie from 'react-lottie';
import threeDotLoading from '../../assets/lotties/3-dots-loading.json';

export default function LoadingLottie() {
    const defaultOption = {
        loop: true,
        autoplay: true,
        animationData: threeDotLoading,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };
    return (
        <div
            style={{
                position: 'absolute',
                background: '#0000008f',
                display: 'flex',
                width: '100%',
                height: '100%',
                left: '0',
                top: '0px',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 200,
            }}
        >
            <Lottie options={defaultOption} style={{ width: '300px', height: '300px' }} />
        </div>
    );
}
