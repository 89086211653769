import styled from 'styled-components';

export const Container = styled.div`
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: #0d0d0d;
    color: #fff;
    gap: 15px;
    overflow-y: auto;

    @media (max-width: 600px) {
        > div > nav > ol > li > a,
        > div > nav > ol > li > h4,
        > div > nav > ol > li > svg {
            font-size: 16px;
        }
    }
`;
