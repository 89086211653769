export default function valorParaString(valor) {
    try {
        return valor.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
        });
    } catch (error) {
        console.error(error);
        return 'R$ NaN';
    }
}
