
import axios from "axios";
import React, { useEffect, useState } from "react";
import api from "../../service/api";

import {
  Container,
  SpanObservacao,
  ContainerProgramacao,
  ContainerOpcoesDias,
  OpcaoDia,
  ContainerAulas,
  Aula,
} from "./SchedulePanelStyles";

// import backgroundIMG from "../../assets/backgrounds/schedule-bg-style.png";


function SchedulePanel() {
  const [evento, setEvento] = useState(null);
  const [dataSelecionada, setDataSelecionada] = useState(0);
  const [dias, setDias] = useState([]);
  const [token, setToken] = useState(null);

  async function GetDadosEventos(){
    try {
      let responsePalestrantes = await axios.get(`https://medx65-v65teste.azurewebsites.net/api/integration/getpalestrantes`, {
        headers: { Authorization: `Bearer ${token}` },
    });
      let response = await axios.get(`https://medx65-v65teste.azurewebsites.net/api/integration/GetEventoByAlias?AliasEvento=medxexperience2022`, {
         headers: { Authorization: `Bearer ${token}` }
    });
    console.log(response.data)
    setEvento({...response.data,palestrantes:responsePalestrantes.data})
    } catch (error) {
      console.error(error)
    }
  }

  async function GetToken(){
    try {
      const {data} = await api.get(`https://medx65-v65teste.azurewebsites.net/api/integration/GetAuthorizedToken?token=Dzg26xILiUGhbaBRa1tRww`)
      setToken(data);      
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(()=>{
    if(token)GetDadosEventos();
  },[token])

  useEffect(()=>{
    if(evento) setDias(getDatas(evento.Palestras));
  },[evento])

  useEffect(() => {
    GetToken();
  }, []);

  function getDatas(palestras) {
    let dias = {};

    palestras.forEach((palestra) => {
      dias[new Date(palestra.Horario).toLocaleDateString()] = "";
    });

    return Object.keys(dias);
  }

  function getPalestrante(idPalestrante) {
    let palestrante = evento.palestrantes.find(
      (p) => p.Id_Palestrante == idPalestrante
    );
    return palestrante;
  }

  function getInfoObjeto(objeto, campo) {
    if (!objeto && campo == "UrlFoto") return "";
    if (!objeto) return "";
    return objeto[campo];
  }

  function stringPalestrante(idPalestrante) {
    if (idPalestrante == 0) return "Intervalo";
    let palestrante = getPalestrante(idPalestrante);
    if (palestrante) return palestrante.Nome;
    return "";
  }

  return (
    evento? <Container imgBackground={''}>
    <h2>Programação</h2>
    <SpanObservacao>{evento.descricaoProgramacao}</SpanObservacao>
    <ContainerProgramacao>
      <ContainerOpcoesDias>
        {dias.map((data, index) => (
          <OpcaoDia
            key={data}
            selected={index == dataSelecionada}
            onClick={() => setDataSelecionada(index)}
          >
            <span>Dia {index + 1}</span>
            <span>{data}</span>
          </OpcaoDia>
        ))}
      </ContainerOpcoesDias>
      <ContainerAulas>
        {evento.Palestras.filter(
          (palestra) =>
            new Date(palestra.Horario).toLocaleDateString() ==
            dias[dataSelecionada]
        )
          .sort((a, b) => new Date(a.Horario) - new Date(b.Horario))
          .map((palestrax, index) => {
            return (
              <Aula key={index} intervalo={!palestrax.Id_Palestrante}>
                <div>
                  <span>
                    {palestrax.Horario != "0001-01-01T00:00:00"
                      ? new Date(palestrax.Horario)
                          .toLocaleTimeString()
                          .replace(":00", "")
                      : "Online"}
                  </span>
                </div>
                <div>
                  <span>{stringPalestrante(palestrax.Id_Palestrante)}</span>
                </div>
                <div>
                  <h3>{palestrax.Titulo}</h3>
                  <span>{palestrax.Descricao}</span>
                </div>
              </Aula>
            );
          })}
      </ContainerAulas>
    </ContainerProgramacao>
  </Container>
    :<div></div>
  ); 
}

export default SchedulePanel;
