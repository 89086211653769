import api from './api';

export const apiUrl = 'relatorio/adm/';

export const getVendasPorPeriodo = async ({ inicio, final, pagos }) => {
    const { data } = await api.get(encodeURI(`${apiUrl}getVendasPeriodo?inicio=${inicio}&final=${final}&somentePagos=${pagos}`));
    return data;
};

export const getNumerosComparativosMes = async () => {
    const { data } = await api.get(`${apiUrl}getNumerosComparativosMes`);
    return data;
};
