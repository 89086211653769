import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    color: ${(p) => p.theme.colors.text};
    background: ${(p) => p.theme.colors.background};
    flex-direction: column;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    ${(p) => (p.promo ? ' padding-bottom: 180px;' : '')}

    @media screen and (max-width: 900px) {
        padding: 12px;
    }

    .subTitle {
        font-size: 24px;
        border-top: 2px solid #252525;
        border-bottom: 2px solid #252525;
        padding: 20px 0px;
        margin: 20px 0px;
        margin-top: 30px;
        width: 100%;
    }
`;
export const FormEcommerce = styled.form`
    /* width: calc(100% - 360px); */
    width: 100%;
    @media screen and (max-width: 900px) {
        width: 100%;
    }
`;
export const ContainerResumoFatura = styled.div`
    color: ${(p) => p.theme.colors.text};
    /* h5 {
        font-size: 24px;
        margin: 12px 0;
        border-bottom: 1px solid #ffffff85;
        padding-bottom: 12px;
    } */
`;
export const DivConteudoResumoFatura = styled.div`
    display: flex;
    justify-content: space-between;
`;
export const DivInputInfo = styled.div`
    width: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    margin-top: 6px;

    @media screen and (max-width: 900px) {
        flex-direction: column;
        align-items: inherit;
    }

    > span {
        display: flex;
        flex: 1;
        width: fit-content;
        min-width: 150px;
        text-align: start;
        color: ${(p) => p.theme.colors.text};
    }

    > input {
        background: #d9d9d9;
        @media screen and (max-width: 900px) {
            width: initial;
        }
        :focus-visible {
            outline: ivory;
            box-shadow: 5px 5px 15px 5px #000000;
        }
    }

    select {
        background: #d9d9d9;
    }
`;

export const InfoDetalhesCurso = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;

    h1 {
        margin: 12px 0 0 0;
    }
    h3 {
        margin: 12px 0 2px 0;
        font-size: 24px;
        font-weight: bolder;
    }
    span {
        font-size: 16px;
    }
`;
export const ImgPagamento = styled.img`
    width: 50%;
    border-radius: 12px;
    max-width: 800px;
    align-self: center;
    @media screen and (max-width: 900px) {
        margin-bottom: 12px;
        height: auto;
    }
`;

export const ContainerFomrResume = styled.div`
    max-width: 800px;
    width: calc(100% - 50px);
    padding: 16px;
    padding-top: 0px;
    display: flex;
    flex: 1;
    align-items: flex-start;
    /* margin-top: 60px; */

    flex-direction: column;
    gap: 0px;

    /* flex-direction: row-reverse;  
    gap: 20px; */

    @media screen and (max-width: 900px) {
        flex-direction: column;
        gap: 0px;
    }
`;

export const DivHeaderCursoInfo = styled.div`
    display: flex;
    /* width: 330px; */
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 0 12px 0;

    flex-direction: column;

    @media screen and (max-width: 900px) {
        max-width: initial;
        width: 100%;
    }

    > div {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        /* background: #232222; */
        border-radius: 8px;
        /* box-shadow: rgb(0, 0, 0) 0px 0px 18px -4px; */
        position: relative;

        width: 100%;

        /* @media screen and (min-width: 900px) {
            position: fixed;
        } */

        @media screen and (max-width: 900px) {
            position: initial;
            width: initial;
        }
    }
`;
