import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { ThemeProvider as ThemeProviderMUI, createTheme } from '@mui/material/styles';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { ThemeProvider } from 'styled-components';
import { AuthProvider, useAuth } from './pages/auth/authProvider/AuthProvider';
import themeDark from './styles/themes/dark';

import ForgotPassword from './pages/auth/forgotPassword/ForgotPassword';
import Login from './pages/auth/login/Login';
import Configuracoes from './pages/configuracoes/Configuracoes';
import NotFound from './pages/erros/NotFound';
import Home from './pages/home/Home';
import Aprensentacao from './pages/jornada/apresentacao/Aprensentacao';
import Game from './pages/jornada/game/Game';
import GameUI from './pages/jornada/gameUi/GameUI';
import MinhaJornanda from './pages/jornada/minhaJornanda/MinhaJornada';
import LandPageCurso from './pages/landPageCurso/landPageCurso';
import Pagamento from './pages/pagamento/pagamento';
import Videos from './pages/videos/videos';

import axios from 'axios';
import ReactPixel from 'react-facebook-pixel';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import RoutersAdm from './Routers.adm';
import background from './assets/background_cadastro2.jpg';
import { DivAuthMenu } from './components/commonComponents/commonComponents';
import MenuComponent from './components/menu/menu';
import Curso from './pages/curso/Curso';
import Agradecimento from './pages/pagamento/agradecimento';
import constantes from './utils/constantes';

export const darkThemeMui = createTheme({
    palette: {
        mode: 'dark',
        // background: {
        //     deep: ['#1d1d1d', '#212121', '#242424', '#262626'],
        // },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                contained: {
                    textTransform: 'capitalize',
                },
            },
        },
       
    },
});
function RequireAuth({ children }: { children: JSX.Element }) {
    let auth = useAuth();
    let location = useLocation();

    useEffect(() => {
        const botaoAjuda = document.getElementById('md-app-widget');

        if (botaoAjuda) {
            console.log('Esconde ajuda');
            botaoAjuda.style.visibility = 'hidden';
        }

        return () => {
            const botaoAjuda = document.getElementById('md-app-widget');

            if (botaoAjuda) {
                console.log('Mostra ajuda');
                botaoAjuda.style.visibility = 'inherit';
            }
        };
    }, []);

    if (!auth.user) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }
    if (location.pathname.includes('game')) return children;
    else
        return (
            <DivAuthMenu>
                <MenuComponent />
                {children}
            </DivAuthMenu>
        );
}
// function RequireAdmAuth() {
//     let auth = useAdmAuth();
//     const location = useLocation();

//     useEffect(() => {
//         console.log('Oi routas');
//     }, []);

//     if (auth == null) {
//         return <Navigate to="/" state={{ from: location }} replace />;
//     } else
//         return (
//             <DivAdmAuthMenu>
//                 <AdmMenu />
//                 <ContainerPaginasAdm>
//                     <Outlet />
//                 </ContainerPaginasAdm>
//             </DivAdmAuthMenu>
//         );
// }

function GoogleAnalitics({ children }: { children: JSX.Element }) {
    const location = useLocation();
    const [googleAnalyticsAtivo, setGoogleAnalyticsAtivo] = useState(false);
    const [gTAGAtivo, setGTAGAtivo] = useState(false);
    // const [hotjarAtivo, setHotjarAtivo] = useState(false);
    const [facebookPixelAtivo, setFacebookPixelAtivo] = useState(false);

    async function initGa() {
        try {
            const { data: codigo } = await axios.get(`${constantes.APIUrl}Rastreio/GoogleAds`);
            if(!codigo || codigo == "Teste")
                return console.log("Codigo GoogleAds não cadastrado");
            ReactGA.initialize(codigo, { debug: false });
            setGoogleAnalyticsAtivo(true);
        } catch (error) {
            console.error('Erro ao tentar fazer inicialização no google analytics.');
            console.error(error);
        } finally {
        }
    }

    async function initGTAG() {
        try {
            const { data: codigo } = await axios.get(`${constantes.APIUrl}Rastreio/GoogleTAG`);
            if(!codigo || codigo == "Teste")
                return console.log("Codigo GoogleTAG não cadastrado");
            const tagManagerArgs = {
                gtmId: codigo
            }
             
            TagManager.initialize(tagManagerArgs)
            setGTAGAtivo(true);
        } catch (error) {
            console.error('Erro ao tentar fazer inicialização no GTAG.');
            console.error(error);
        } finally {
        }
    }
    async function initPixel() {
        try {
            const { data: codigo } = await axios.get(`${constantes.APIUrl}Rastreio/PixelId`);
            if(!codigo || codigo == "Teste")
                return console.log("Codigo PixelId não cadastrado");  
            ReactPixel.init(codigo);
            setFacebookPixelAtivo(true);
        } catch (error) {
            console.error('Erro ao tentar fazer inicialização no facebook pixel.');
            console.error(error);
        } finally {
        }
    }
    // async function initHotjar() {
    //     try {
    //         const { data: codigos } = await axios.get(`${constantes.APIUrl}Rastreio/Hotjar`);
    //         if(!codigos || codigos == "Teste")
    //             return console.log("Codigos Hotjar não cadastrados");  

    //         sessionStorage.setItem(constantes.storageHotjar, JSON.stringify(codigos));
    //         setHotjarAtivo(true);
    //     } catch (error) {
    //         console.error('Erro ao tentar fazer inicialização no Hotjar.');
    //         console.error(error);
    //     } finally {
    //     }
    // }
    useEffect(() => {
        initGa();
        initPixel();
        initGTAG();
        // initHotjar();
    }, []);

    useEffect(() => {
        if (googleAnalyticsAtivo) {
            console.log('Ativando Google analytics');
            ReactGA.pageview(location.pathname + location.search);
        }
    }, [location, googleAnalyticsAtivo]);

    useEffect(() => {
        if (gTAGAtivo) {
            console.log('Ativando G-TAG');
        }
    }, [location, gTAGAtivo]);

    useEffect(() => {
        if (facebookPixelAtivo) {
            console.log('Ativando Pixel analytics');
            ReactPixel.pageView();
            // ReactFbq.track({title:location.pathname + location.search})
        }
    }, [location, facebookPixelAtivo]);

    return children;
}

const MyRedirectAlunoRoot: React.FC = () => {
    useEffect(() => {
        setTimeout(() => {
            window.location.href = './aluno';
        }, 1);
    }, []);

    return <div></div>;
};

function Routers() {
    return (    
        <div className="App" style={{ background: `url(${background})` }}>
            <BrowserRouter basename="/">
                <Routes>
                    <Route index element={<MyRedirectAlunoRoot />} />
                </Routes>
            </BrowserRouter>
            <RoutersAdm />
            <BrowserRouter basename="/aluno">
            <ThemeProviderMUI theme={darkThemeMui}>
                <ThemeProvider theme={themeDark}>
                    <AuthProvider>
                        <GoogleAnalitics>
                            <Routes>
                                <Route index element={<Login perfil={'aluno'} />} />
                                <Route path="/begin_password_reset" element={<ForgotPassword />} />
                                <Route path="/curso/:alias" element={<LandPageCurso />} />
                                <Route path="/curso/:alias/:leadAlias" element={<LandPageCurso />} />
                                <Route path="/pagamento" element={<Pagamento />} />
                                <Route path="/pagamento/:curosAlias/:leadAlias" element={<Pagamento />} />
                                <Route path="/compraFinalizada" element={<Agradecimento />} />
                                <Route path="/app">
                                    <Route
                                        index
                                        element={
                                            <RequireAuth>
                                                <Home />
                                            </RequireAuth>
                                        }
                                    />
                                    <Route
                                        path="/app/apresentacao/:idJornada"
                                        element={
                                            <RequireAuth>
                                                <Aprensentacao />
                                            </RequireAuth>
                                        }
                                    />
                                    <Route
                                        path="/app/videos/:idJornada"
                                        element={
                                            <RequireAuth>
                                                <Videos />
                                            </RequireAuth>
                                        }
                                    />
                                    <Route
                                        path="/app/configuracoes"
                                        element={
                                            <RequireAuth>
                                                <Configuracoes />
                                            </RequireAuth>
                                        }
                                    />
                                    <Route
                                        path="/app/curso/:alias"
                                        element={
                                            <RequireAuth>
                                                <Curso />
                                            </RequireAuth>
                                        }
                                    />
                                    <Route
                                        path="/app/gameUI/:idJornada"
                                        element={
                                            <RequireAuth>
                                                <GameUI />
                                            </RequireAuth>
                                        }
                                    />
                                    <Route
                                        path="/app/game/:idJornada"
                                        element={
                                            <RequireAuth>
                                                <Game />
                                            </RequireAuth>
                                        }
                                    />
                                    <Route
                                        path="/app/minhajornada/:idJornada"
                                        element={
                                            <RequireAuth>
                                                <MinhaJornanda />
                                            </RequireAuth>
                                        }
                                    />
                                </Route>
                                <Route path="/404" element={<NotFound />} />
                                <Route path="/*" element={<Navigate to="/404" />} />
                            </Routes>
                        </GoogleAnalitics>
                    </AuthProvider>
                </ThemeProvider>
            </ThemeProviderMUI>
            </BrowserRouter>
        </div>   
    );
}

export default Routers;
