import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { ItemTabela, Tabela } from './cursoEdit.adm.styles';

function CursosJornadas() {
    const { curso, setCurso } = useOutletContext();
    const navegate = useNavigate();
    const theme = useTheme();
    return (
        <Box display={'flex'} flexDirection={'column'} padding={'10px'}>
            <Box display={'flex'} justifyContent={'space-between'} padding={'10px 15px'}>
                <Typography variant="h5" textAlign={'center'}>
                    Jornadas do Curso
                </Typography>
                <Button variant="contained" color="success" startIcon={<AddIcon />}>
                    Adicionar Jornada
                </Button>
            </Box>
            <Tabela>
                {curso.jornadas.map((jornada) => (
                    <ItemTabela
                        key={jornada.id}
                        style={{ backgroundColor: theme.palette.background.default }}
                        title="Abrir Curso"
                        onClick={() => navegate(`${jornada.id}/Elementos`)}
                    >
                        <img className="imagemTabelaJornada" src={jornada.urlImagemApresentacao} />

                        <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} flex={1}>
                            <Box>
                                <Typography color={theme.palette.primary.dark} variant="h6" textAlign={'start'}>
                                    {jornada.nome}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    className="textoDescricao"
                                    variant="body2"
                                    color={theme.palette.text.secondary}
                                    textAlign={'start'}
                                >
                                    {jornada.descricaoResumo || jornada.descricao}
                                </Typography>
                            </Box>
                        </Box>
                    </ItemTabela>
                ))}
            </Tabela>
        </Box>
    );
}

export default CursosJornadas;
