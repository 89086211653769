import React from 'react';
import { useParams } from 'react-router-dom';

import { Container } from './popUpStyles';
import imgClose from '../../assets/close.png';
import { toast } from 'react-toastify';
import ToastGame from '../toastGame/ToastGame';
import api from '../../service/api';

const PopUpEnviarPergunta = ({ closePopUp }) => {
    const params = useParams();

    async function postPergunta(texto, jornadaId) {
        const { data } = await api.post('duvida/enviaPergunta', { texto, jornadaId });
        return data;
    }

    const enviarPergunta = async (event) => {
        const pergunta = document.getElementById('perguntaDoAluno').value;
        if (!pergunta) {
            return toast.info(<ToastGame title={'O campo de pergunta é obrigatório.'} text={'Campo obrigatório esta vazio.'} />, {
                icon: false,
            });
        }
        try {
            await postPergunta(pergunta, params.idJornada);
            closePopUp();
        } catch (error) {
            toast.info(<ToastGame title={'Erro ao tentar enviar pergunta'} text={'Erro ao tentar enviar pergunta'} />, {
                icon: false,
            });
        }
    };

    return (
        <Container className="popup" id="PopUpEnviarPergunta" style={{ display: 'flex' }}>
            <div className="contentPopUp">
                <div className="TitlePopUp">
                    <span id="TitlePopUp">{`Enviar Duvida`}</span>
                </div>
                <div className="close" onClick={() => closePopUp()}>
                    <img src={imgClose} />
                </div>
                <div className="conteudoPopUp">
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center' }}>
                        <h2 style={{ color: '#fff' }}>Escreva abaixo sua duvida</h2>
                        <span className="text">
                            O professor ira responde-la assim que possivel, e voce recebera uma notificação no email.
                        </span>
                        <textarea
                            id="perguntaDoAluno"
                            placeholder="Escreva aqui sua duvida"
                            style={{
                                backgroundColor: '#181818',
                                border: '0px solid',
                                color: '#fff',
                                fontSize: '16px',
                                borderRadius: '4px',
                                width: '100%',
                                flex: 1,
                                resize: 'none',
                            }}
                        ></textarea>
                        <div style={{ marginTop: '5px', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <button
                                style={{
                                    padding: '10px',
                                    border: 'none',
                                    borderRadius: '8px',
                                    color: '#fff',
                                    background: '#1a8616',
                                    fontSize: '16px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => enviarPergunta()}
                            >
                                Enviar Duvida
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default PopUpEnviarPergunta;
