import React, { useState, useEffect, useRef, useCallback } from 'react';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { MdDoubleArrow, MdClose } from 'react-icons/md';
import {
    Container,
    Fechar,
    ProgressContainer,
    Progress,
    ContainerVideo,
    ContainerBody,
    ContainerPergunta,
    VerMais,
    ClosePergunta,
    ContainerPerguntaTexto,
} from './stylesIntagramStory';
import { useLongPress, LongPressDetectEvents } from 'use-long-press';
import ReactPlayer from 'react-player';

const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
};

function IntagramStory({ close, duvidas }) {
    const [duracao, setDuracao] = useState(20);
    const referenciaVideo = useRef();
    const [stories, setStories] = useState([]);
    const [storyAtivo, setStoryAtivo] = useState(0);
    const [isPoused, setIsPoused] = useState(false);
    const [vendoPergunta, setVendoPergunta] = useState(false);

    const passarProximoStory = useCallback(() => {
        if (!vendoPergunta) {
            setIsPoused(true);
            setStoryAtivo((old) => (old == stories.length - 1 ? 0 : old + 1));
        }
    }, [storyAtivo, vendoPergunta]);

    const voltaStory = useCallback(() => {
        if (!vendoPergunta) {
            setIsPoused(true);
            setStoryAtivo((old) => (old == 0 ? stories.length - 1 : old - 1));
        }
    }, [storyAtivo, vendoPergunta]);

    const clickRight = useLongPress(() => setIsPoused(true), {
        onStart: () => {},
        onFinish: () => setIsPoused(false),
        onCancel: () => passarProximoStory(),
        //onMove: () => console.log("Detected mouse or touch movement"),
        threshold: 300,
        captureEvent: true,
        cancelOnMovement: false,
        detect: LongPressDetectEvents.BOTH,
    });

    const clickLeft = useLongPress(() => setIsPoused(true), {
        onStart: () => {},
        onFinish: () => setIsPoused(false),
        onCancel: () => voltaStory(),
        //onMove: () => console.log("Detected mouse or touch movement"),
        threshold: 300,
        captureEvent: true,
        cancelOnMovement: false,
        detect: LongPressDetectEvents.BOTH,
    });

    useEffect(() => {
        setStories(duvidas);
    }, [duvidas]);

    function togglePergunta(abrir = true) {
        setIsPoused(abrir);
        setVendoPergunta(abrir);
    }

    return (
        <Container>
            <Fechar onClick={close}>
                <IoMdCloseCircleOutline fontSize={24} color={'#c7c7c7'} />
            </Fechar>
            {stories.length > 0 && (
                <>
                    <ProgressContainer className={isPoused ? 'pause' : ''}>
                        {stories.map((story, index, all) => (
                            <Progress
                                onClick={() => {
                                    console.log('clicouy');
                                    setStoryAtivo(index);
                                }}
                                // onAnimationEnd={passarStory}
                                key={story.urlVideoResposta}
                                style={{ animationDuration: `${duracao}s` }}
                                className={`${storyAtivo == index ? 'active' : ''} ${storyAtivo > index ? 'passed' : ''}`}
                            ></Progress>
                        ))}
                    </ProgressContainer>
                    <ContainerBody>
                        <ContainerVideo id="containerVideo">
                            <ReactPlayer
                                onStart={() => setIsPoused(false)}
                                onReady={() => setIsPoused(false)}
                                onDuration={(e) => setDuracao(e)}
                                onEnded={passarProximoStory}
                                ref={referenciaVideo}
                                url={stories[storyAtivo].urlVideoResposta}
                                progressInterval={3000}
                                loop={false}
                                playing={!isPoused}
                                width="100%"
                                height="100%"
                                controls={false}
                            />
                            <div className="goPrevious" {...clickLeft()}></div>
                            <div className="goNext" {...clickRight()}></div>
                        </ContainerVideo>
                        <VerMais onClick={togglePergunta}>
                            <MdDoubleArrow />
                            Ver Pergunta
                        </VerMais>
                        <ContainerPergunta className={vendoPergunta ? 'visivel' : ''}>
                            <ClosePergunta onClick={() => togglePergunta(false)}>
                                <MdClose />
                            </ClosePergunta>
                            <ContainerPerguntaTexto>
                                <span>{stories[storyAtivo].duvida}</span>
                            </ContainerPerguntaTexto>
                        </ContainerPergunta>
                    </ContainerBody>
                </>
            )}
        </Container>
    );
}

export default IntagramStory;
