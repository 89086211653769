import styled from 'styled-components';

export const Container = styled.div`
    background: ${(p) => p.theme.colors.background};
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    padding: 0px;
    overflow: hidden;
    justify-content: center;
    align-items: center;

    > div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 15px;
        flex: 1;
        width: 600px;
        max-width: 80vw;
        overflow: hidden;

        > h1 {
            margin: 0px;
            color: #fff;
        }

        input {
            margin-top: 10px;
            border-radius: 8px;
            padding: 10px;
            width: 100%;
        }

        sub {
            text-align: start;
            width: 100%;
            margin-top: 4px;
            font-weight: 600;
            color: #b7b7b7;
        }

        > button {
            margin-top: 20px;
        }
    }
`;
