import api from './api';

export const apiUrl = 'aluno/adm';

export const getAlunos = async () => {
    const { data } = await api.get(apiUrl.replace('/adm', ''));
    return data;
};

export const getAlunosByNomeOuEmail = async (texto) => {
    const { data } = await api.get(`${apiUrl.replace('/adm', '')}/busca?busca=${texto}`);
    return data;
};
