import axios from 'axios';
import md5 from 'md5';
import { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import logo from '../../../assets/login/logo.png';
import threeDotLoading from '../../../assets/lotties/3-dots-loading.json';
import { Button, Input } from '../../../components/commonComponents/commonComponents';
import { Container as ContainerPopup } from '../../../components/popUps/popUpStyles';
import ToastGame from '../../../components/toastGame/ToastGame';
import api from '../../../service/api';
import { setRefreshToken, setToken } from '../../../service/auth';
import gameInitStore from '../../../store/gameInitStore';
import constantes from '../../../utils/constantes';
import isMobile from '../../../utils/isMobile';
import { useAuth } from '../authProvider/AuthProvider';
import { Container, DivLogo, LoginContainer } from './stylesLogin';
const defaultOption = {
    loop: true,
    autoplay: true,
    animationData: threeDotLoading,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

function Login({ perfil }) {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const [recaptch, setRecaptch] = useState();
    const [mostraLoad, setMostraLoad] = useState(false);
    const [openAviso, setOpenAviso] = useState(false);
    const from = location.state?.from?.pathname || '/';
    const email = location?.state?.email || localStorage.getItem(constantes.storageEmail);

    const [user, setUser] = useState({
        Device: isMobile() ? 0 : 1,
        Password: '',
        Email: email || '',
    });

    const [stateProgress, setStateProgress] = useState({
        isAnimating: false,
        key: 0,
    });

    useEffect(() => {
        let searchFrom = location?.state?.from?.search;
        if (searchFrom) {
            const token = new URLSearchParams(searchFrom).get('token');
            if (token) loginRedirecionando(token);
        }
    }, []);

    async function login(event, derruba) {
        event.preventDefault();
        setMostraLoad(true);
        if (openAviso) setOpenAviso(false);
        try {
            toogleProgressBar();
            let { data } = await axios.post(`${constantes.APIUrl}login/${perfil}`, {
                ...user,
                ip: '1',
                derrubar: derruba,
            });
            setToken(data.token);
            setRefreshToken(data.refreshToken);
            const aluno = await gameInitStore.getAluno();
            if (!aluno) throw 'Erro ao tentar buscar dados do aluno';
            auth.signin(aluno, async () => {
                acessarSistema();
            });
        } catch (error) {
            if (error?.response?.data) {
                if (error?.response?.status === 401) {
                    toast.error(<ToastGame title={'Não autorizado!'} text={error.response.data} />, {
                        // autoClose: 100000,
                        icon: false,
                    });
                    setMostraLoad(false);
                    return;
                }
            }
            if (error?.response?.status === 418) {
                setMostraLoad(false);
                setOpenAviso(true);
                return;
            }
            let mensagemErro = error?.response?.data?.Message || error.message;
            if (mensagemErro === 'Erro, email não confirmado!') {
                toast.warning('Seu email ainda não foi verificado!');
                // setEmailNaoVerificado(true);
                setRecaptch(true);
            } else {
                toast.error(<ToastGame title={mensagemErro || 'Erro ao tentar logar!'} text={'Erro'} />, {
                    // autoClose: 100000,
                    icon: false,
                });
            }
            console.error(error, error.response);
        } finally {
            toogleProgressBar();
            setMostraLoad(false);
        }
    }

    async function loginRedirecionando(token) {
        try {
            setToken(token);
            const aluno = await gameInitStore.getAluno();
            if (!aluno) throw 'Erro ai tentar buscar dados do aluno';
            auth.signin(aluno, async () => {
                toogleProgressBar();
                acessarSistema();
            });
        } catch (error) {}
    }

    function acessarSistemaTrocandoSenha() {
        toast.warning('Sua senha precisa ser atualizada.');
    }

    function getIP() {
        return new Promise(async (resolve) => {
            try {
                let { data: ip } = await api.get('/api/ip/getip');
                resolve(ip);
            } catch (error) {
                console.log(error);
                resolve(false);
            }
        });
    }

    function toogleProgressBar() {
        setStateProgress((prevState) => ({
            isAnimating: !prevState.isAnimating,
            key: prevState.isAnimating ? prevState.key : prevState.key ^ 1,
        }));
    }

    function setEmail(email) {
        localStorage.setItem(constantes.storageEmail, email);
        setUser({ ...user, Email: email });
    }

    function acessarSistema() {
        let pagina = location?.state?.from;
        if (pagina) return navigate(pagina);
        navigate('/app', { replace: true });
    }

    return (
        <Container>
            {/* <Progress isAnimating={stateProgress.isAnimating} key={stateProgress.key} /> */}
            <DivLogo>
                <img src={constantes.logo} onError={(e) => (e.target.src = logo)} />
            </DivLogo>
            <LoginContainer>
                <form onSubmit={(event) => login(event, false)}>
                    <Input
                        type="email"
                        value={user.Email}
                        placeholder="Digite aqui seu email"
                        onChange={(e) => setEmail(e.target.value.trim())}
                        required
                    />

                    <Input
                        type="password"
                        placeholder="Digite aqui sua senha"
                        onChange={(e) => setUser({ ...user, Password: md5(e.target.value.trim()) })}
                        required
                    />

                    <Button backgroundColorProps="#53af32" hoverBackgroundColorProps="#37831c" style={{ width: '110%' }}>
                        Acessar
                    </Button>
                    <Link
                        to={{
                            pathname: '/begin_password_reset',
                            state: { email: user.Email },
                        }}
                    >
                        Esqueci minha senha
                    </Link>
                </form>
            </LoginContainer>
            {mostraLoad && (
                <div
                    style={{
                        position: 'absolute',
                        background: '#0000006b',
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Lottie options={defaultOption} style={{ width: '300px', height: '300px' }} />
                </div>
            )}
            {openAviso && (
                <ContainerPopup className="popup" id="popUpAvisoMultLogin" style={{ display: 'flex' }}>
                    <div className="contentPopUp" style={{ height: 'fit-content', width: '300px' }}>
                        <div className="TitlePopUp">
                            <span id="TitlePopUp">{`Usuário já logado.`}</span>
                        </div>
                        <div className="conteudoPopUp">
                            <p style={{ color: '#fff', fontSize: '18px' }}>Deseja desconectar o acesso para prosseguir ?</p>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                <Button
                                    onClick={() => setOpenAviso(false)}
                                    style={{ background: theme.colors.vermelho, fontSize: '16px' }}
                                >
                                    Não
                                </Button>
                                <Button
                                    onClick={(event) => login(event, true)}
                                    style={{ background: theme.colors.verde, fontSize: '16px' }}
                                >
                                    Sim
                                </Button>
                            </div>
                        </div>
                    </div>
                </ContainerPopup>
            )}
        </Container>
    );
}

export default Login;
