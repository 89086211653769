import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import IntagramStory from '../instagramStory/InstagramStory';
import api from '../../service/api';
import constantes from '../../utils/constantes';
import ToastGame from '../toastGame/ToastGame';
import { toast } from 'react-toastify';
import ReactPlayer from 'react-player';
const styleSeta = {
    cursor: 'pointer',
    fontSize: '30px',
    padding: '0px',
    background: '#fff',
    borderRadius: '50%',
    boxShadow: 'rgb(0 0 0 / 76%) 0px 0px 10px 1px',
    color: '#343434',
};

const PopUpPerguntaEnviada = ({ closePopUp, pauseAmbiente, objConfigJornada }) => {
    const params = useParams();
    const [duvidas, setDuvidas] = useState([]);
    const [popUpPerguntasEnviadas, setPopUpPerguntasEnviadas] = useState({ isOpen: true, indexPergunta: 0 });
    const [mostraEnviaPergunta, setMostraEnviaPergunta] = useState(false);

    async function getDuvidas(idJornada) {
        const { data: duvidas } = await api.get(`duvida/duvidasRespondidas?jornadaId=${idJornada}`);
        return duvidas;
    }

    useEffect(() => {
        async function init(params) {
            try {
                const duvidas = await getDuvidas(params.idJornada);
                setDuvidas(duvidas);
            } catch (error) {
                toast.info(
                    <ToastGame
                        title={'Erro ao baixar duvidas, entre em contato com suporte caso persista'}
                        text={'Ops, algo deu errado.'}
                        foto={`${constantes.urlStorage}${objConfigJornada.professor}`}
                    />,
                    {
                        // autoClose: 100000,
                        icon: false,
                    },
                );
                console.error(error);
            }
        }
        init(params);
    }, [params]);

    return <div>{<IntagramStory close={closePopUp} duvidas={duvidas}></IntagramStory>}</div>;
};

export default PopUpPerguntaEnviada;

const estiloBotaoStory = {
    textAlign: 'center',
    fontSize: 14,
    bottom: 20,
    position: 'relative',
    fontWeight: 600,
    color: '#1463a6',
    cursor: 'pointer',
    padding: '5px 10px',
    borderRadius: '10px',
    background: '#fff',
};
